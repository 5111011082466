export default {
    address: '0x71D1849548aA1e571fb523b057A7dcfD3D94fd1E',
    // address: '0x613618C96339dB1F777C99A2E4405b21a5744623',
    abi:
        [{
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
                "indexed": false,
                "internalType": "address",
                "name": "bidder",
                "type": "address"
            }, {"indexed": false, "internalType": "uint256", "name": "price", "type": "uint256"}],
            "name": "EnterBidForToken",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
                "indexed": false,
                "internalType": "address",
                "name": "offer",
                "type": "address"
            }, {"indexed": false, "internalType": "uint256", "name": "price", "type": "uint256"}],
            "name": "OfferTokenForSale",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            }, {"indexed": true, "internalType": "address", "name": "newOwner", "type": "address"}],
            "name": "OwnershipTransferred",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "address", "name": "receiver", "type": "address"}, {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }],
            "name": "PayableToReceiver",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "bool", "name": "isAccept", "type": "bool"}],
            "name": "SetIsAccept",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "bool", "name": "isBid", "type": "bool"}],
            "name": "SetIsBid",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "bool", "name": "isRoyalty", "type": "bool"}],
            "name": "SetIsRoyalty",
            "type": "event"
        }, {
            "anonymous": false,
            "inputs": [{
                "indexed": false,
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"indexed": false, "internalType": "address", "name": "from", "type": "address"}, {
                "indexed": false,
                "internalType": "address",
                "name": "to",
                "type": "address"
            }, {"indexed": false, "internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
                "indexed": false,
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }, {"indexed": false, "internalType": "bool", "name": "isBid", "type": "bool"}],
            "name": "TransactionToken",
            "type": "event"
        }, {
            "inputs": [{"internalType": "address", "name": "", "type": "address"}],
            "name": "NFTAddressIsAccept",
            "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{"internalType": "address", "name": "", "type": "address"}],
            "name": "NFTAddressIsBid",
            "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{"internalType": "address", "name": "", "type": "address"}],
            "name": "NFTAddressIsRoyalty",
            "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "minPrice",
                "type": "uint256"
            }], "name": "acceptBidForToken", "outputs": [], "stateMutability": "nonpayable", "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
            "name": "buyToken",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
            "name": "enterBidForToken",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
            "name": "getOfferBid",
            "outputs": [{
                "components": [{
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }, {"internalType": "address", "name": "owner", "type": "address"}, {
                    "internalType": "address",
                    "name": "seller",
                    "type": "address"
                }, {"internalType": "uint256", "name": "offerPrice", "type": "uint256"}, {
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                }, {"internalType": "uint256", "name": "bidPrice", "type": "uint256"}],
                "internalType": "struct PETGPTMarket.OfferBid",
                "name": "",
                "type": "tuple"
            }],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "start", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "end",
                "type": "uint256"
            }],
            "name": "getOfferBids",
            "outputs": [{
                "components": [{
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }, {"internalType": "address", "name": "owner", "type": "address"}, {
                    "internalType": "address",
                    "name": "seller",
                    "type": "address"
                }, {"internalType": "uint256", "name": "offerPrice", "type": "uint256"}, {
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                }, {"internalType": "uint256", "name": "bidPrice", "type": "uint256"}],
                "internalType": "struct PETGPTMarket.OfferBid[]",
                "name": "offerBids",
                "type": "tuple[]"
            }],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "start", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "end",
                "type": "uint256"
            }],
            "name": "getOwnerOf",
            "outputs": [{"internalType": "address[]", "name": "owners", "type": "address[]"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "address", "name": "user", "type": "address"}, {
                "internalType": "uint256",
                "name": "start",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "end", "type": "uint256"}],
            "name": "getUserBids",
            "outputs": [{
                "components": [{
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }, {"internalType": "address", "name": "owner", "type": "address"}, {
                    "internalType": "address",
                    "name": "seller",
                    "type": "address"
                }, {"internalType": "uint256", "name": "offerPrice", "type": "uint256"}, {
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                }, {"internalType": "uint256", "name": "bidPrice", "type": "uint256"}],
                "internalType": "struct PETGPTMarket.OfferBid[]",
                "name": "offerBids",
                "type": "tuple[]"
            }],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }], "name": "offerTokenForSale", "outputs": [], "stateMutability": "nonpayable", "type": "function"
        }, {
            "inputs": [],
            "name": "owner",
            "outputs": [{"internalType": "address", "name": "", "type": "address"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "bool", "name": "isAccept", "type": "bool"}],
            "name": "setIsAccept",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "bool", "name": "isBid", "type": "bool"}],
            "name": "setIsBid",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "bool", "name": "isRoyalty", "type": "bool"}],
            "name": "setIsRoyalty",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }, {
            "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }],
            "name": "tokenBids",
            "outputs": [{"internalType": "address", "name": "bidder", "type": "address"}, {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{
                "internalType": "address",
                "name": "petgptNFTAddress",
                "type": "address"
            }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
            "name": "tokenIsOffer",
            "outputs": [{"internalType": "bool", "name": "isOffer", "type": "bool"}],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }],
            "name": "tokenOfferedForSale",
            "outputs": [{"internalType": "address", "name": "seller", "type": "address"}, {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }],
            "stateMutability": "view",
            "type": "function"
        }, {
            "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }]
}