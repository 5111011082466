import store from './store'
import {message} from 'antd'
import languages from 'languages'
import {connect} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

export const
    requireDefault = (name, suffix = 'png') => require(`./assets/${name}.${suffix}`),
    square = size => ({width: size, height: size}),
    circle = size => ({...square(size), borderRadius: '50%', overflow: 'hidden'}),
    isNotNull = data => data != null,
    isNotNullOrEmpty = data => isNotNull(data) && data.length > 0,
    setPath = (name = '') => '/' + name,
    setPathSlice = (name = '') => name.slice(1),
    isIndexOf = (array = [], data) => array.indexOf(data) !== -1,
    checkData = ([content, ...ks], defaultData) => {
        const contentIsNotNull = isNotNull(content), [k, ...k1] = ks
        // 判断数据是否存在
        return (!contentIsNotNull || isIndexOf(ks) || isIndexOf(ks, null))
            ? defaultData
            : (contentIsNotNull
                // 判断k是否存在
                ? (isNotNull(k)
                    // 判断k是否是有元素的数组
                    ? (typeof k === 'object' && isNotNull(k[0])
                        // 遍历ks，返回含有指定value的数组
                        ? ks.map(([v, ...v1]) => checkData([content[v], ...v1], defaultData))
                        // 获取多维对象的值
                        : checkData([content[k], ...k1], defaultData))
                    : content)
                // 数据不存在返回默认值
                : defaultData);
    },
    checkData0 = (...keys) => checkData(keys, 0),
    checkDataBool = (...keys) => checkData(keys, false),
    checkDataString = (...keys) => checkData(keys, ''),
    checkDataArray = (...keys) => checkData(keys, []),
    checkDataObject = (...keys) => checkData(keys, {}),
    checkDataFunction = (...keys) => checkData(keys, () => {
    }),
    numberCheckData0 = (...keys) => Number(checkData0(...keys)),
    ternaryArray = (condition, array = []) => condition ? array : [],
    ternaryObject = (condition, object = []) => condition ? object : {},
    dispatch = (props, state = {}) => checkDataFunction(props, 'dispatch')({type: '', ...state}),
    getLanguage = (defaultData, ...keys) => {
        const getLanguage = (languageIndex, defaultData) => checkData([languages, languageIndex, ...keys], defaultData)
        return getLanguage(languageIndex(), getLanguage(0, defaultData))
    },
    languageIndex = () => store.getState().languageIndex,
    language = (...keys) => getLanguage('', ...keys),
    languageFunction = (...keys) => getLanguage(() => '', ...keys),
    connectClass = classData => connect(data => ({data}), dispatch => ({dispatch}))(classData),
    pathname = () => useLocation().pathname,
    path = () => pathname().slice(1),
    navigate = path => useNavigate()(`/${path}`),
    middleEllipsis = (str = '', num = 0) => {
        return checkDataString(str).length > num * 2
            ? `${str.slice(0, num)}...${str.slice(-num)}`
            : str
    },
    toast = m => message.useMessage()[0].info(m),
    copyText = (text = '', func) => {
        const input = document.createElement('input')
        input.value = text
        document.body.appendChild(input)
        input.select()
        document.execCommand('Copy')
        document.body.removeChild(input)
        isNotNull(func) ? func() : toast(language('copySucceed'))
    },
    isDev = process.env.NODE_ENV === 'development',
    timestampToDate=timestamp=>new Date(timestamp)
        .toLocaleString('cn', {hour12: false})