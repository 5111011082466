import React, {useEffect, useRef, useState} from 'react'
import store from "./store"
import {
    checkData,
    checkData0,
    checkDataArray,
    checkDataBool,
    checkDataFunction,
    checkDataObject,
    checkDataString,
    connectClass,
    isNotNull,
    isNotNullOrEmpty,
    requireDefault,
    ternaryArray,
    ternaryObject,
    timestampToDate,
} from 'functions'
import SuperGif from 'libgif'
import {Navigate, Route, Routes} from 'react-router-dom'
import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum'
import {useWeb3Modal, Web3Modal} from '@web3modal/react'
import {configureChains, createConfig, useAccount, useChainId, WagmiConfig} from 'wagmi'
import {arbitrum, arbitrumGoerli, mainnet} from 'wagmi/chains'
import {prepareWriteContract, readContract, signMessage, writeContract} from '@wagmi/core'
import {message} from 'antd'
import axios from 'axios'
import Web3 from "web3";
import {formatEther, parseEther} from "viem";
import privacy from "./privacy";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes,} from 'number-precision'
import {toBlob} from "html-to-image";
import JSZip from "jszip";
import {jsonRpcProvider} from "wagmi/providers/jsonRpc";
import {ParticleNetwork, WalletEntryPosition} from "@particle-network/auth";
import {ParticleProvider} from "@particle-network/provider";
import {ArbitrumOne} from "@particle-network/chains";
import {Buffer} from "buffer/";

let offerBidPage = 1
const sessionDev = sessionStorage.getItem('dev'),
    sessionMakeNFT = sessionStorage.getItem('makeNFT'),
    contractArbitrum = require('./contractArbitrum').default,
    contractArbitrumGoerli = require('./contractArbitrumGoerli').default,
    gameContractArbitrumGoerli = require('./gameContractArbitrumGoerli').default,
    gameContractArbitrum = require('./gameContractArbitrum').default,
    arbitrumRPC = 'https://arbitrum.petgpt.ai',
    // arbitrumRPC ='https://arb-mainnet.g.alchemy.com/v2/LmWB0Poa92tA-js8vE7unr-shVpetyL0',
    // arbitrumRPC = 'https://arbitrum-mainnet.infura.io/v3/4dc1418732704759aa363de02c17ca67',
    arbitrumGoerliRPC = 'https://arbitrum-goerli.infura.io/v3/6a7e6009ca2f4863b11e4d5b41598027',
    // arbitrumRPC = 'https://arb1.arbitrum.io/rpc',
    // arbitrumGoerliRPC = 'https://goerli-rollup.arbitrum.io/rpc',
    goerliRPC = 'https://goerli.infura.io/v3/6a7e6009ca2f4863b11e4d5b41598027',
    mainnetRPC = 'https://eth.petgpt.ai',
    scientistMetadataPath = 'scientistMetadata',
    scientistMetadataList = require.context('./assets/scientistMetadata/', false, /\.json$/).keys().map(v => v.slice(2)),
    {eth: arbitrumETH} = new Web3(arbitrumRPC),
    {eth: arbitrumGoerliETH} = new Web3(arbitrumGoerliRPC),
    scientistTraits = [
        'Fur',
        'Whiskers',
        'Mouth',
        'Goggles',
        'Outfit',
        'Tools',
    ],
    scientistAttributes = [
        ['Whiskers',
            [
                '3e',
                '3s',
                '3w',
                '3z',
            ]
        ],
        ['Tools',
            [
                'Golden Magnifying Glass',
                'Green Magnifying Glass',
                'Red Magnifying Glass',
                'Golden Spanner',
                'Green Spanner',
                'Red Spanner',
                'Cyan Syringe Gun',
                'Pink Syringe Gun',
                'Yellow Syringe Gun',
                'Caliper',
                'Golden Caliper',
                'Lightbulb',
                'Cyan Test Tube',
                'Pink Test Tube',
                'Yellow Test Tube',
            ]
        ],
        ['Outfit',
            [
                'Beige Craftsman',
                'Grey Craftsman',
                'Black Labcoat',
                'Green Labcoat',
                'Pink Labcoat',
                'White Labcoat',
                'Beige Professor',
                'Pink Professor',
                'White Professor',
                'Royal',
            ]
        ],
        ['Mouth',
            [
                'Neutral',
                'Smile',
                'Surprised',
            ]
        ],
        ['Goggles',
            [
                'Blue',
                'Cyan',
                'Green',
                'Black & Gold Hexagon',
                'Golden Gem Hexagon',
                'Pink',
                'Blue Sci-Fi',
                'Green Sci-Fi',
                'Pink Sci-Fi',
                'Blue Steampunk',
                'Cyan Steampunk',
                'Green Steampunk',
                'Pink Steampunk',
                'Yellow Steampunk',
                'Yellow',
            ]
        ],
        ['Fur',
            [
                'Black',
                'Tuxedo',
                'Grey',
                'Tortoiseshell',
                'Milktea',
                'Orange',
                'Point',
                'Silver',
                'Tiger',
                'Calico',
                'White',
            ]
        ],
    ],
    // todo 排序不可动
    backgroundAttribute = [
        'Background', [
            // todo 第一个必须是透明
            'Transparent',
            'Black',
            'Cyan',
            'Light Blue',
            'Light Green',
            'Pink',
            'Purple',
            'White',
            'Yellow',
        ]
    ],
    backgroundAttributeKey = backgroundAttribute[0],
    // todo 排序不可动
    annexAttributes = [
        ['Pack', 'Pack_front'],
        ['Clothes', 'Clothes_front',
            [
                'Space Odyssey',
                'Interstellar',
                'MEP',
            ]
        ]
    ],
    // todo 排序不可动
    requiredAttributes = [
        ['Fur',
            [
                'Black',
                'Tuxedo',
                'Grey',
                'Tortoiseshell',
                'Milktea',
                'Orange',
                'Point',
                'Silver',
                'Tiger',
                'Calico',
                'White',
            ]
        ],
        ['Goggles',
            [
                'Blue',
                'Cyan',
                'Green',
                'Black & Gold Hexagon',
                'Golden Gem Hexagon',
                'Pink',
                'Blue Sci-Fi',
                'Green Sci-Fi',
                'Pink Sci-Fi',
                'Blue Steampunk',
                'Cyan Steampunk',
                'Green Steampunk',
                'Pink Steampunk',
                'Yellow Steampunk',
                'Yellow',
            ]
        ],
        ['Mouth',
            [
                'Neutral',
                'Smile',
                'Surprised',
            ]
        ],
        ['Whiskers',
            [
                '3e',
                '3s',
                '3w',
                '3z',
            ]
        ],
        ['Clothes',
            [
                ...annexAttributes[1][2],
                'Art Generation',
                'Fitness',
                'GPT',
                'Mood Detector',
                'Music',
                'Search Engine',
            ]
        ]
    ],
    // todo 排序不可动
    newScientistAttributes = [
        ['Pack',
            [
                'Ernie',
                'Gemini',
                'LLaMA',
                'Openai',
            ]
        ],
        ...requiredAttributes
    ],
    openSea = 'https://opensea.io/collection/catscientist',
    {address: contractArbitrumAddress} = contractArbitrum,
    openSeaArbitrumTokenId = tokenId => windowOpen(`https://opensea.io/assets/arbitrum/${contractArbitrumAddress}/${tokenId}`),
    arbitrumIoUrl = address => `https://arbiscan.io/address/${address}`,
    arbiscanGoerliIoUrl = address => `https://goerli.arbiscan.io/address/${address}`,
    arbitrumEtherscan = arbitrumIoUrl(contractArbitrumAddress),
    openSeaArbitrumGoerli = 'https://testnets.opensea.io/collection/catscientist-5',
    {address: contractArbitrumGoerliAddress} = contractArbitrumGoerli,
    openSeaArbitrumTokenIdGoerli = tokenId => windowOpen(`https://testnets.opensea.io/assets/arbitrum-goerli/${contractArbitrumGoerliAddress}/${tokenId}`),
    etherscanArbitrumGoerli = arbiscanGoerliIoUrl(contractArbitrumGoerliAddress),
    {address: gameContractArbitrumGoerliAddress} = gameContractArbitrumGoerli,
    {address: gameContractArbitrumAddress} = gameContractArbitrum,
    marketContract721AArbitrumGoerli = require('./marketContract721AArbitrumGoerli').default,
    //确保与marketContract在同一链，chains要包含所用到的链
    defaultNFTContracts = [
        // {
        //     icon: 'genesisCatNFT',
        //     name: 'PETGPT Cat Scientist',
        //     nftIndex: 0,
        //     address: contractArbitrumAddress,
        //     abi: contractArbitrum.abi,
        //     chain: arbitrum,
        //     metadataPath: scientistMetadataPath,
        //     metadataList: scientistMetadataList,
        //     traits: scientistTraits,
        //     attributes: scientistAttributes,
        //     eth: arbitrumETH,
        //     marketContract: require('./marketContractArbitrum').default,
        //     openSea,
        //     openSeaTokenId: openSeaArbitrumTokenId,
        //     etherscan: arbitrumEtherscan,
        // },
        {
            icon: 'Blind box',
            name: 'PETGPT Show Field',
            nftIndex: 1,
            axiosOfferBids: true,
            address: gameContractArbitrumAddress,
            abi: gameContractArbitrum.abi,
            chain: arbitrum,
            traits: ['Collection', 'Type', 'Name'],
            attributes: [
                ['Collection',
                    [
                        'Dress Up',
                        'Furnishing',
                    ]
                ],
                ['Type',
                    [
                        'Blind box',
                        'Pants',
                        'Top',
                        'Collar',
                        'Top - Jacket',
                        'Head Accessories',
                        'Litter Basin',
                    ]
                ],
                ['Name',
                    [
                        'Black Jeans',
                        'Black Overalls',
                        'Blue Jeans',
                        'Blue JK Skirt',
                        'Blue Overalls',
                        'Green Jeans',
                        'Pink JK Skirt',
                        'Pink Tutu',
                        'White Tutu',
                        'Black Collar Shirt',
                        'Black Hoodie',
                        'Black Tank Top',
                        'Green Collar Shirt',
                        'Green Hoodie',
                        'Green Sweater',
                        'Pink Collar Shirt',
                        'Pink Hoodie',
                        'Red Sweater',
                        'White Collar Shirt',
                        'White Hoodie',
                        'White Tank Top',
                        'Black Bow Tie',
                        'Black Punk Collar',
                        'Black Tie',
                        'Green Bow Tie',
                        'Green Package',
                        'Green Tie',
                        'Pearls',
                        'Pink Punk Collar',
                        'Red Bow Tie',
                        'Red Package',
                        'Red Tie',
                        'White Bow Tie',
                        'Blackpink Cyberpunk',
                        'Neon Yellow Cyberpunk',
                        'Avocado',
                        'Black Bowler',
                        'Black Maid',
                        'Crown',
                        'Leaf',
                        'Pink Flower',
                        'Pink Maid',
                        'Red Flower',
                        'Straw Bowler',
                        'Toast',
                        'White Bowler',
                        'White Maid',
                        'Yellow Flower',
                    ]
                ],
            ],
            eth: arbitrumETH,
            marketContract: require('./marketContractArbitrumGame').default,
            openSea,
            openSeaTokenId: openSeaArbitrumTokenId,
            etherscan: arbitrumIoUrl(gameContractArbitrumAddress),
            isTokenOfOwner: gameContractArbitrum.isTokenOfOwner
        },
        ...ternaryArray(
            sessionDev,
            [
                {
                    icon: 'genesisCatNFT',
                    name: 'Cat Scientist AG',
                    nftIndex: 0,
                    is721A: marketContract721AArbitrumGoerli.is721A,
                    address: contractArbitrumGoerliAddress,
                    abi: contractArbitrumGoerli.abi,
                    chain: arbitrumGoerli,
                    metadataPath: scientistMetadataPath,
                    metadataList: scientistMetadataList,
                    traits: scientistTraits,
                    attributes: scientistAttributes,
                    eth: arbitrumGoerliETH,
                    marketContract: marketContract721AArbitrumGoerli,
                    openSea: openSeaArbitrumGoerli,
                    openSeaTokenId: openSeaArbitrumTokenIdGoerli,
                    etherscan: etherscanArbitrumGoerli,
                },
                {
                    icon: 'Blind box',
                    name: 'PETGPT Show Field AG',
                    nftIndex: 1,
                    axiosOfferBids: true,
                    address: gameContractArbitrumGoerliAddress,
                    abi: gameContractArbitrumGoerli.abi,
                    chain: arbitrumGoerli,
                    traits: ['Collection', 'Type', 'Name'],
                    attributes: [
                        ['Collection',
                            [
                                'Dress Up',
                                'Furnishing',
                            ]
                        ],
                        ['Type',
                            [
                                'Blind box',
                                'Pants',
                                'Top',
                                'Collar',
                                'Top - Jacket',
                                'Head Accessories',
                                'Litter Basin',
                            ]
                        ],
                        ['Name',
                            [
                                'Black Jeans',
                                'Black Overalls',
                                'Blue Jeans',
                                'Blue JK Skirt',
                                'Blue Overalls',
                                'Green Jeans',
                                'Pink JK Skirt',
                                'Pink Tutu',
                                'White Tutu',
                                'Black Collar Shirt',
                                'Black Hoodie',
                                'Black Tank Top',
                                'Green Collar Shirt',
                                'Green Hoodie',
                                'Green Sweater',
                                'Pink Collar Shirt',
                                'Pink Hoodie',
                                'Red Sweater',
                                'White Collar Shirt',
                                'White Hoodie',
                                'White Tank Top',
                                'Black Bow Tie',
                                'Black Punk Collar',
                                'Black Tie',
                                'Green Bow Tie',
                                'Green Package',
                                'Green Tie',
                                'Pearls',
                                'Pink Punk Collar',
                                'Red Bow Tie',
                                'Red Package',
                                'Red Tie',
                                'White Bow Tie',
                                'Blackpink Cyberpunk',
                                'Neon Yellow Cyberpunk',
                                'Avocado',
                                'Black Bowler',
                                'Black Maid',
                                'Crown',
                                'Leaf',
                                'Pink Flower',
                                'Pink Maid',
                                'Red Flower',
                                'Straw Bowler',
                                'Toast',
                                'White Bowler',
                                'White Maid',
                                'Yellow Flower',
                            ]
                        ],
                    ],
                    eth: arbitrumGoerliETH,
                    marketContract: require('./marketContractArbitrumGoerli').default,
                    openSea: openSeaArbitrumGoerli,
                    openSeaTokenId: openSeaArbitrumTokenIdGoerli,
                    etherscan: arbiscanGoerliIoUrl(gameContractArbitrumGoerliAddress),
                    isTokenOfOwner: gameContractArbitrumGoerli.isTokenOfOwner
                }
            ]
        ),
    ],
    isMarketplacePages = ['marketplace', 'myNFTs', 'myBids'].includes(window.location.pathname.slice(1).split('/')[0]),
    isGenesisPages = (() => {
        const locationPathname = window.location.pathname.slice(1).split('/')
        return ['genesis'].includes(locationPathname[0]) && !isNotNullOrEmpty(locationPathname[1])
    })(),
    chains = isGenesisPages
        ? [mainnet]
        : [
            ...new Set([
                arbitrum,
                ...ternaryArray(sessionDev, [arbitrumGoerli]),
                ...ternaryArray(
                    isMarketplacePages,
                    defaultNFTContracts.map(v => v.chain)
                )
            ])
        ],
    chainIds = chains.map(v => v.id),
    keyToLowerCase = obj => JSON.parse(JSON.stringify(obj).toLowerCase()),
    initData = {},
    projectId = 'a53af091b7583a110f5f12d01ea33065',
    wagmiConfig = createConfig({
        autoConnect: true,
        connectors: w3mConnectors({chains, projectId, version: 2}),
        publicClient: configureChains(chains, [w3mProvider({projectId})]).publicClient,
    }),
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [
    //         jsonRpcProvider({
    //             rpc: (chain) => ({
    //                 http: {
    //                     1: mainnetRPC,
    //                     5: goerliRPC,
    //                     42161: arbitrumRPC,
    //                     421613: arbitrumGoerliRPC,
    //                 }[chain.id],
    //             }),
    //         }),
    //     ]).publicClient,
    // }),
    ethereumClient = new EthereumClient(wagmiConfig, chains),
    replaceGenesis = () => windowReplace('/genesis'),
    replaceMarketplace = () => windowReplace('/marketplace'),
    height = '.6rem',
    heightPhone = '2rem',
    maxFullPage = 7,
    {location, open: windowOpen} = window,
    windowReplace = path => location.href = path,
    chainValues = (() => {
        const obj = {}
        chainIds.map((v, i) => obj[v] = (isGenesisPages
            ? [
                {
                    // todo 有的数据需要根据链网络补全
                    sbtContract: require('./sbtContractArbitrum').default,
                    openSea,
                    openSeaTokenId: openSeaArbitrumTokenId,
                    etherscan: arbitrumEtherscan,
                    scientistMetadataPath,
                    scientistMetadataList,
                    loginNetwork: 'arb_one',
                    api: 'https://api.petgpt.ai/v1/',
                    // todo 上线修改
                    // baseURI: 'https://s3.petgpt.ai/nft/metadata/',
                    // imageBaseUrl: 'https://s3.petgpt.ai/nft/images/',
                    baseURI: 'https://s3.petgpt.ai/genesis/metadata/',
                    imageBaseUrl: 'https://s3.petgpt.ai/genesis/images/',
                    contract: require('./newScientistContractMainnet').default,
                    allowList: keyToLowerCase(require('./newAllowListMainnet').default),
                    eth: (new Web3(mainnetRPC)).eth,
                }
            ]
            : [
                {
                    contract: contractArbitrum,
                    allowList: keyToLowerCase(require('./allowList').default),
                    sbtContract: require('./sbtContractArbitrum').default,
                    eth: arbitrumETH,
                    openSea,
                    openSeaTokenId: openSeaArbitrumTokenId,
                    etherscan: arbitrumEtherscan,
                    scientistMetadataPath,
                    scientistMetadataList,
                    loginNetwork: 'arb_one',
                    api: 'https://api.petgpt.ai/v1/',
                },
                {
                    contract: contractArbitrumGoerli,
                    allowList: keyToLowerCase(require('./allowListArbitrumGoerli').default),
                    sbtContract: require('./sbtContractArbitrumGoerli').default,
                    eth: arbitrumGoerliETH,
                    openSea: openSeaArbitrumGoerli,
                    openSeaTokenId: openSeaArbitrumTokenIdGoerli,
                    etherscan: etherscanArbitrumGoerli,
                    scientistMetadataPath,
                    scientistMetadataList,
                    loginNetwork: 'arb_goerli',
                    api: 'https://test-api.petgpt.ai/v1/',
                }
            ])[i])
        return obj
    })(),
    partners = [
        ['walletconnect'],
        ['hashExchange'],
        ['consensys'],
        ['particle'],
        ['privy'],
        ['arbitrumFoundation'],
        ['okxWallet'],
        ['gate'],
    ],
    leaveOut = str => str ? [str.slice(0, 2), str.slice(str.length - 4)].join('...') : '',
    arrayId = (num, start = 0) => {
        const arr = []
        for (let i = start; i < num + start; i++) {
            arr.push(i + 1)
        }
        return arr
    },
    dateNow = Date.now(),
    particle = new ParticleNetwork({
        projectId: "b55dbe0e-c29a-43bd-99e4-24aa368dc2e5",
        clientKey: "c6HouAThtav5l4kWmA7l0uL8Q5kmtSrh7leXGKbR",
        appId: "aa957e5e-2ead-4ec9-b818-d86ba120d0f9",
        chainName: ArbitrumOne.name, //optional: current chain name, default Ethereum.
        chainId: ArbitrumOne.id, //optional: current chain id, default 1.
        wallet: {   //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
            // displayWalletEntry: true,  //show wallet entry when connect particle.
            defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
            uiMode: "dark",  //optional: light or dark, if not set, the default is the same as web auth.
            supportChains: [{id: 42161, name: "ArbitrumOne"}], // optional: web wallet support chains.
            customStyle: {}, //optional: custom wallet style
        },
        securityAccount: { //optional: particle security account config
            //prompt set payment password. 0: None, 1: Once(default), 2: Always
            promptSettingWhenSign: 1,
            //prompt set master password. 0: None(default), 1: Once, 2: Always
            promptMasterPasswordSettingWhenLogin: 1
        },
    }),
    // particleProvider = new ParticleProvider(particle.auth),
    Profile = connectClass(props => {
            const pathname = location.pathname.slice(1),
                pNs = pathname.split('/'),
                pNs0 = pNs[0],
                tokenId = pNs[1],
                [messageApi, contextHolder] = message.useMessage(),
                toast = content => messageApi.open({content}),
                numberToFixed = num => {
                    const toFixedNum = 6, denominator = 10 ** toFixedNum
                    return Number(npDivide(Math.floor(npTimes(Number(isNaN(num) ? 0 : num), denominator)), denominator).toFixed(toFixedNum))
                },
                parseEtherWei = num => parseEther(numberToFixed(num).toString()),
                numberETH = (numWei = '0') => numberToFixed(formatEther(numWei)),
                {isPhoneClientWidth} = store.getState(), [data, stateData] = useState({...initData}),
                setData = (d, clean) => stateData(data => ({...(clean ? {} : data), ...d})),
                devCount = checkData0(data, 'devCount'),
                makeNFTCount = checkData0(data, 'makeNFTCount'),
                init = checkDataBool(data, 'init'),
                devCountAdd = () => setData({
                    devCount: devCount + 1
                }),
                makeNFTCountAdd = () => setData({
                    makeNFTCount: makeNFTCount + 1
                }),
                showList = checkDataBool(data, 'showList'), toggleShowList = () => setData({showList: !showList}),
                showBuy = checkDataBool(data, 'showBuy'), toggleShowBuy = () => setData({showBuy: !showBuy}),
                showGif = checkData([data, 'showGif'], true),
                isParticle = checkData([data, 'isParticle']),
                {
                    isOpen,
                    open = () => {
                    },
                    close = () => {
                    },
                } = checkDataObject(useWeb3Modal()),
                connectLoading = width => checkDataBool(data, 'showConnectLoading') &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${width}rem`}}/>
                    </div>,
                toggleShowLoading = showLoading => setData({showLoading}),
                showLoading = checkDataBool(data, 'showLoading'),
                loading = showLoading &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${isPhoneClientWidth ? 10 : 5}rem`}}/>
                    </div>,
                showIncubation = checkDataBool(data, 'showIncubation'),
                toggleShowIncubation = showIncubation => setData({showIncubation}),
                dataNumber = k => Number(checkData0(data, k)), count = Number(checkData([data, 'count'], 1)),
                setCount = c => setData({count: c > 0 ? c : 1}),
                tokenIdLink = checkDataString(data, 'tokenIdLink'),
                setTokenIdLink = tokenIdLink => setData({tokenIdLink}),
                chainId = ethereumClient.getNetwork().chain?.id,
                chainIdIsNotNull = isNotNull(chainId),
                fullPage = checkData([data, 'fullPage'], 0),
                scrolling = checkData0(data, 'scrolling'),
                listBalance = data.listBalance,
                balanceOf = data.balanceOf,
                soldSupply = dataNumber('soldSupply'),
                totalSupply = dataNumber('totalSupply'),
                address = ethereumClient.getAccount().address,
                addressLeaveOut = leaveOut(address),
                isInit = checkData([data, 'isInit'], true),
                isStart = dateNow >= 1690340400000,
                onSale = false,//isInit || (isStart && (soldSupply < totalSupply)),
                isMintSbt = true,
                isMintScientist = checkDataBool(data, 'isMintScientist'),
                toggleIsMintScientist = isMintScientist => setData({isMintScientist}),
                showDownloadApp = true,
                appDownload = [
                    ['appStore', () => windowOpen('https://apps.apple.com/app/id6447691273')],
                    ['googlePlay', () => windowOpen('https://play.google.com/store/apps/details?id=com.petgpt.wallet')],
                ],
                isGenesis = pNs0 === 'genesis',
                isMarketplace = pNs0 === 'marketplace',
                isMyBids = pNs0 === 'myBids',
                isMyNFTs = pNs0 === 'myNFTs',
                isHome = pNs0 === '',
                isHowToPlay = pNs0 === 'howToPlay',
                needSupply = false,// (showDownloadApp ? false : pNs0 === '') || (isGenesis && !isNotNullOrEmpty(tokenId)),
                wrongNetwork = !(isHome || isHowToPlay) && !chainIds.includes(Number(chainId)),
                chainValue = checkData([chainValues, wrongNetwork ? chainIds[0] : chainId], checkDataObject(chainValues, chainIds[0])),
                {eth} = chainValue,
                getPastEvents = (address, abi, eventName) => new (isMarketplacePages ? nftContractETH : eth).Contract(abi, address).getPastEvents(
                    eventName,
                    {
                        fromBlock: 0,
                        toBlock: 'latest'
                    }
                ),
                aL = checkData([chainValue.allowList, checkDataString(address).toLowerCase()], [1, []]),
                aL0 = aL[0],
                aL0Gt0 = aL0 > 0,
                aL0GtBalanceOf = aL0 > balanceOf,
                {address: contractAddress, abi: contractAbi} = chainValue.contract,
                {address: sbtContractAddress, abi: sbtContractAbi} = chainValue.sbtContract,
                {scientistMetadataList} = chainValue,
                {openSea, etherscan} = chainValue,
                windowOpenOpenSea = () => windowOpen(openSea),
                windowOpenEtherscan = () => windowOpen(etherscan),
                {openSeaTokenId} = chainValue,
                socialize = [
                    ['discord', 'https://discord.com/invite/petgptofficial'],
                    ['twitter', 'https://twitter.com/petgptofficial'],
                    ['medium', 'https://medium.com/@petgpt'],
                    ['youtube', 'https://www.youtube.com/@PETGPTAI'],
                ],
                contractCall = (funcName, ...params) => {
                    const func = checkData([new eth.Contract(contractAbi, contractAddress), 'methods', funcName])
                    return func ? func(...params).call() : new Promise(resolve => resolve())
                },
                canAccessWalletConnectUrl = (
                    func = () => {
                    },
                    falseFunc = () => {
                    }
                ) => {
                    func()
                    // const walletConnectUrl = 'https://walletconnect.com'
                    // axios(
                    //     {
                    //         timeout: 5000,
                    //         url: walletConnectUrl,
                    //     },
                    // ).then(() => func())
                    //     .catch(() => {
                    //         falseFunc()
                    //         toast(`Make sure your network can access to ${walletConnectUrl},Refresh and try again`)
                    //     })
                },
                openWeb3Modal = (...params) => {
                    if (chainIdIsNotNull) {
                        open(...params)
                    } else {
                        const toggleShowConnectLoading = sCL => setData({showConnectLoading: sCL})
                        toggleShowConnectLoading(true)
                        canAccessWalletConnectUrl(() => {
                            toggleShowConnectLoading()
                            open(...params)
                        }, () => toggleShowConnectLoading())
                    }
                },
                connect = isOpen ? close : openWeb3Modal,
                switchEthereumChain = (
                    func = () => {
                    },
                    falseFunc = () => {
                    },
                    needConnect,
                    chain = {}
                ) => {
                    const pleaseConnect = () => {
                        toast(`Please Connect ${chain.name || chains[0].name}`)
                        setTimeout(() => openWeb3Modal({route: chainIdIsNotNull ? 'SelectNetwork' : undefined}), 1000)
                    }, {id: chainId1} = chain, chainId1IsNotNull = isNotNull(chainId1)
                    if (chainIdIsNotNull) {
                        if (
                            (!chainId1IsNotNull || (chainId1IsNotNull && chainId === chainId1)) &&
                            !wrongNetwork
                        ) {
                            func()
                        } else {
                            falseFunc()
                            pleaseConnect()
                        }
                    } else {
                        falseFunc()
                        if (needConnect) {
                            pleaseConnect()
                        }
                    }
                },
                mint = () => {
                    const toggleShowBuyToast = text => {
                        toggleShowLoading()
                        toggleShowBuy()
                        text && toast(text)
                    }
                    aL0GtBalanceOf && switchEthereumChain(
                        () => {
                            toggleShowLoading(true)
                            canAccessWalletConnectUrl(
                                () => isNotNullOrEmpty(aL)
                                    ? prepareWriteContract({
                                        // abi: contractAbi,
                                        // args: [address],
                                        // address: contractAddress,
                                        // functionName: 'transferOwnership',
                                        // walletClient: {account: address},
                                        abi: contractAbi,
                                        args: [count, ...aL],
                                        address: contractAddress,
                                        functionName: 'mintAllowList',
                                        walletClient: {account: address},
                                    }).then(r => writeContract(r.request).then(r => toggleShowBuyToast(checkData([r, 'hash']) ? 'Successful' : 'Failed'))
                                        .catch(e => toggleShowBuyToast(e.message.split('\n')[0])))
                                        .catch(e => {
                                            const errorStr = [1, 2].map(v => e.message.split('\n')[v]).join('\n'),
                                                obj = {
                                                    'Mint time has not yet begun': 'Mint will start at July 26, 11:00 (UTC+8)',
                                                    ExceedsMaximumSupply: 'All NFTs have already been minted',
                                                    ExceedsAllowListQuota: 'Each wallet can only mint one NFT',
                                                    NotOnAllowList: 'You are not in WL list',
                                                }
                                            let eStr
                                            Object.keys(obj).map(v => {
                                                if (errorStr.includes(v)) eStr = obj[v]
                                            })
                                            toggleShowBuyToast(eStr || errorStr)
                                        })
                                    : toast('Sorry, you didn\'t make it...')
                            )
                        },
                        toggleShowBuyToast
                    )
                },
                mintSbt = () => switchEthereumChain(
                    () => {
                        toggleShowLoading(true)
                        const toggleShowLoadingToast = text => {
                            toggleShowLoading()
                            toast(text)
                        }
                        canAccessWalletConnectUrl(
                            () => readContractSBT('price').then(value => prepareWriteContract({
                                    value,
                                    args: [tokenId],
                                    abi: sbtContractAbi,
                                    functionName: 'mint',
                                    address: sbtContractAddress,
                                    walletClient: {account: address},
                                }).then(r => writeContract(r.request).then(r => {
                                    const hash = checkData([r, 'hash'])
                                    toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                                    hash && toggleShowIncubation(true)
                                })
                                    .catch(e => toggleShowLoadingToast(e.message.split('\n')[0])))
                                    .catch(e => toggleShowLoadingToast([1, 2].map(v => e.message.split('\n')[v]).join('\n')))
                            ).catch(() => toggleShowLoading()),
                            () => toggleShowLoading()
                        )
                    },
                    undefined,
                    true
                ),
                toastAL0Gt0 = func => switchEthereumChain(() => {
                    if (address) {
                        if (aL0Gt0) {
                            func ? func() : toast('Congrats, you are in the Meowlist!')
                        } else {
                            toast('Sorry, you didn\'t make it...')
                        }
                    }
                }, undefined, true),
                mintShowBuy = () => isStart
                    ? (onSale && switchEthereumChain(() => toastAL0Gt0(toggleShowBuy), undefined, true))
                    : toastAL0Gt0(),
                gif = (() => {
                    const {userAgent} = navigator,
                        divStyle = {
                            zIndex: 9999,
                            width: '100vw',
                            height: '100vh'
                        },
                        divClass = 'backgroundActiveColor flexCenter positionFixed',
                        gifGif = requireDefault('gif', 'gif'),
                        proportion = isPhoneClientWidth ? .8 : .4,
                        gifEnd = () => {
                            switchEthereumChain()
                            setData({showGif: false})
                        }
                    return userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1
                        ? (showGif && <div style={divStyle} className={divClass}>
                            <img alt=""
                                 src={gifGif}
                                 style={{width: `${npTimes(proportion, 100)}%`}}
                                 onLoad={() => setTimeout(gifEnd, 2000)}
                            />
                        </div>)
                        : <div style={{
                            ...divStyle,
                            display: showGif ? 'flex' : 'none'
                        }} className={divClass}>
                            <img alt=""
                                 src={gifGif}
                                 style={{display: checkDataBool(data, 'loadGif') ? 'block' : 'none'}}
                                 onLoad={v => new SuperGif({
                                     gif: v.target,
                                     loop_delay: 0,
                                     loop_mode: false,
                                     draw_while_loading: false,
                                     on_end: gifEnd
                                 }).load(() => {
                                     const canvas = document.querySelector('canvas')
                                     canvas.style.transform = `scale(${npTimes(npDivide(window.innerWidth, canvas.width), proportion)})`
                                     setData({loadGif: true})
                                 })}
                            />
                        </div>
                })(),
                buyDialog = showBuy && (
                    isPhoneClientWidth
                        ? <div className={'positionFixed flexColumnCenter'}
                               style={{
                                   top: 0,
                                   bottom: 0,
                                   left: 0,
                                   right: 0,
                                   zIndex: 1000,
                                   background: 'rgba(0,0,0,.56)',
                               }}>
                            <div className={'flexColumnCenter positionRelative'}>
                                <img onClick={toggleShowBuy} className={'positionAbsolute padding cursorPointer'}
                                     src={requireDefault('close')}
                                     style={{top: 0, right: 0, width: '1.5rem'}} alt=""/>
                                <img style={{
                                    background: '#9048FF',
                                    borderTopRightRadius: '1rem',
                                    borderTopLeftRadius: '1rem',
                                    marginBottom: '-7rem',
                                    width: '10rem'
                                }} className={'zIndex1'} src={requireDefault('mint', 'gif')} alt=""/>
                                <div className={'childrenSpacingTop backgroundWhite'}
                                     style={{width: '27rem', borderRadius: '1rem', overflow: 'hidden'}}
                                >
                                    <div className={'backgroundActiveColor'} style={{height: '7rem'}}/>
                                    <div className={'childrenSpacingTopBigPhone flexColumnCenter'}
                                         style={{padding: '2rem 5.3rem'}}
                                    >
                                        <div
                                            className={'width100 flexCenter justifyContentSpaceBetween childrenSpacingLeftBig'}>
                                            {['Minted/Total', [balanceOf, aL0].join('/')].map(v => <div
                                                className={'fontWeightBolder'}>{v}</div>)}
                                        </div>
                                        {/*{isNotNull(listBalance) && count > listBalance &&*/}
                                        {/*    <div className={'colorGray smallFontSizePhone width100'}>Max {listBalance} NFT*/}
                                        {/*        per wallet</div>}*/}
                                        {/*<div*/}
                                        {/*    className={'flexCenter justifyContentSpaceBetween width100 childrenFlexGrow1 childrenSpacingLeftBigPhone'}*/}
                                        {/*    style={{height: '2.5rem'}}>*/}
                                        {/*    <div*/}
                                        {/*        className={'borderRadius999 height100 flex alignItemsCenter boxSizingBorderBox'}*/}
                                        {/*        style={{background: '#ECECEC', padding: '.3rem'}}>*/}
                                        {/*        <img onClick={() => setCount(count - 1)}*/}
                                        {/*             src={requireDefault('subtract')} alt=""*/}
                                        {/*             style={{width: '1.5rem'}}/>*/}
                                        {/*        <div className={'flexGrow1 flexCenter'}>*/}
                                        {/*            <input value={count} type="text"*/}
                                        {/*                   onChange={e => setCount(e.target.value.replace(/\D/, ''))}*/}
                                        {/*                   className={'textAlignCenter width100 saf'}/>*/}
                                        {/*        </div>*/}
                                        {/*        <img onClick={() => setCount(count + 1)} src={requireDefault('add')}*/}
                                        {/*             alt=""*/}
                                        {/*             style={{width: '1.5rem'}}/>*/}
                                        {/*    </div>*/}
                                        {/*    {isNotNull(listBalance) &&*/}
                                        {/*        <div onClick={() => setCount(listBalance)}*/}
                                        {/*             className={'borderRadius999 height100 boxSizingBorderBox flexCenter cursorPointer fontWeightBolder backgroundActiveColor'}*/}
                                        {/*             style={{*/}
                                        {/*                 fontSize: '1rem',*/}
                                        {/*                 border: 'solid .2rem black'*/}
                                        {/*             }}*/}
                                        {/*        >*/}
                                        {/*            MINT MAX*/}
                                        {/*        </div>}*/}
                                        {/*</div>*/}
                                        <div
                                            onClick={mint}
                                            style={{padding: '.6rem 0', fontSize: '1rem'}}
                                            className={`width100 ${aL0GtBalanceOf ? 'backgroundBlack' : 'backgroundGrey'} activeColor borderRadius999 flexCenter  cursorPointer`}>
                                            MINT NOW
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className={'positionFixed flexColumnCenter'}
                               style={{
                                   top: 0,
                                   bottom: 0,
                                   left: 0,
                                   right: 0,
                                   zIndex: 1000,
                                   background: 'rgba(0,0,0,.56)',
                               }}>
                            <div className={'flexColumnCenter positionRelative'}>
                                <img onClick={toggleShowBuy}
                                     className={'positionAbsolute padding cursorPointer'}
                                     src={requireDefault('close')}
                                     style={{top: '.7rem', right: 0, width: '.5rem'}} alt=""/>
                                <img style={{
                                    borderTopRightRadius: '.5rem',
                                    borderTopLeftRadius: '.5rem',
                                    marginBottom: '-2.5rem',
                                    width: '5rem'
                                }} className={'zIndex1'} src={requireDefault('mint', 'gif')}/>
                                <div className={'childrenSpacingTop backgroundWhite'}
                                     style={{width: '9rem', borderRadius: '.5rem', overflow: 'hidden'}}
                                >
                                    <div className={'backgroundActiveColor'} style={{height: '2.5rem'}}/>
                                    <div className={'childrenSpacingTopBig flexColumnCenter'}
                                         style={{padding: '.5rem 1.97rem'}}
                                    >
                                        <div
                                            className={'width100 flexCenter justifyContentSpaceBetween childrenSpacingLeftBig'}>
                                            {['Minted/Total', [balanceOf, aL0].join('/')].map(v =>
                                                <div
                                                    className={'fontWeightBolder'}>{v}</div>)}
                                        </div>
                                        {/*{isNotNull(listBalance) && count > listBalance &&*/}
                                        {/*    <div className={'colorGray width100'}>Max {listBalance} NFT per wallet</div>}*/}
                                        {/*<div*/}
                                        {/*    className={'flexCenter justifyContentSpaceBetween width100 childrenFlexGrow1 childrenSpacingLeftBig'}*/}
                                        {/*    style={{height: '.8rem'}}>*/}
                                        {/*    <div*/}
                                        {/*        className={'borderRadius999 height100 flex alignItemsCenter boxSizingBorderBox'}*/}
                                        {/*        style={{background: '#ECECEC', padding: '.1rem'}}>*/}
                                        {/*        <img onClick={() => setCount(count - 1)}*/}
                                        {/*             src={requireDefault('subtract')}*/}
                                        {/*             alt=""*/}
                                        {/*             style={{width: '.5rem'}}/>*/}
                                        {/*        <div className={'flexGrow1 flexCenter'}>*/}
                                        {/*            <input value={count} type="text"*/}
                                        {/*                   onChange={e => setCount(e.target.value.replace(/\D/, ''))}*/}
                                        {/*                   className={'textAlignCenter width100 saf'}/>*/}
                                        {/*        </div>*/}
                                        {/*        <img onClick={() => setCount(count + 1)}*/}
                                        {/*             src={requireDefault('add')}*/}
                                        {/*             alt=""*/}
                                        {/*             style={{width: '.5rem'}}/>*/}
                                        {/*    </div>*/}
                                        {/*    {isNotNull(listBalance) &&*/}
                                        {/*        <div onClick={() => setCount(listBalance)}*/}
                                        {/*             className={'borderRadius999 height100 flexCenter cursorPointer fontWeightBolder backgroundActiveColor'}*/}
                                        {/*             style={{*/}
                                        {/*                 border: 'solid .06rem black'*/}
                                        {/*             }}*/}
                                        {/*        >*/}
                                        {/*            MINT MAX*/}
                                        {/*        </div>}*/}
                                        {/*</div>*/}
                                        <div
                                            onClick={mint}
                                            style={{padding: '.2rem 0'}}
                                            className={`width100 ${aL0GtBalanceOf ? 'backgroundBlack' : 'backgroundGrey'} activeColor borderRadius999 flexCenter  cursorPointer`}>
                                            MINT NOW
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                ),
                pageTopFootPhone = (content, showEnd = true) => <div>
                    <div className={'positionFixed zIndex999'} style={{left: 0, right: 0}}>
                        <div
                            className={'width100 boxSizingBorderBox flex justifyContentSpaceBetween alignItemsCenter paddingLeftRightBigPhone'}
                            style={{background: 'rgba(0, 0, 0, 0.5)', height: '3rem'}}>
                            <img onClick={replaceHome} src={requireDefault('logo')}
                                 style={{height: '1.5rem'}} alt=""/>
                            <div className={'flex childrenSpacingLeftPhone alignItemsCenter'}>
                                <div
                                    className={'colorWhite positionRelative flexColumnCenter paddingLeftRight cursorPointer'}>
                                </div>
                                {address
                                    ? (wrongNetwork
                                        ? <div onClick={connect}
                                               className={'borderRadius999'}
                                               style={{
                                                   border: '.08rem solid #E832CC',
                                                   padding: '.5rem',
                                                   color: '#E832CC',
                                                   fontSize: '.5rem'
                                               }}>
                                            Wrong network
                                        </div>
                                        : <div onClick={connect}
                                               className={'borderRadius999 activeColor smallerFontSizePhone'}
                                               style={{border: '.08rem solid #BAFF00', padding: '.5rem'}}>
                                            {addressLeaveOut}
                                        </div>)
                                    : <img onClick={connect}
                                           src={requireDefault('wallet')}
                                           style={{height: heightPhone}}
                                           alt=""/>}
                                <div className={'flexColumn justifyContentFlexEnd'}>
                                    <img onClick={toggleShowList} src={requireDefault(showList ? 'list2' : 'list')}
                                         style={{height: heightPhone}}
                                         alt=""/>
                                </div>
                            </div>
                        </div>
                        {
                            showList && <div onClick={toggleShowList}
                                             style={{height: '100vh', background: 'rgba(0, 0, 0, 0.5)'}}
                            >
                                <div className={'backgroundWhite overflowYScroll'}
                                     style={{
                                         maxHeight: '85vh',
                                         borderBottomRightRadius: '1rem',
                                         borderBottomLeftRadius: '1rem'
                                     }}
                                >
                                    {
                                        [
                                            ['howToPlay1', () => windowReplace('/howToPlay'), 'howToPlay'],
                                            ['whitepaper1', () => windowOpen('https://petgpt.gitbook.io/petgpt-whitepaper/')],
                                            ['genesis1', replaceGenesis, 'genesis'],
                                            ['support1', () => window.location.href = 'mailto:support@petgpt.ai'],
                                            ['marketplace1', replaceMarketplace, 'marketplace']
                                        ].map(v => <div
                                            className={`flexCenter ${v[2] === pNs0 ? 'backgroundActiveColor' : ''}`}
                                            style={{padding: '2rem 1rem'}}
                                            onClick={() => {
                                                v[1]()
                                                toggleShowList()
                                            }}
                                        >
                                            <img src={requireDefault(v[0])} style={{height: '1.2rem'}} alt=""/>
                                        </div>)
                                    }
                                    {address && <div style={{padding: '1rem 1rem 0 1rem'}}
                                                     className={'flexCenter justifyContentSpaceBetween'}>
                                        <div className={'flexCenter childrenSpacingLeftBig'}>
                                            <img src={requireDefault('wallet')} alt="" style={{width: '3rem'}}/>
                                            <div>{addressLeaveOut}</div>
                                        </div>
                                    </div>}
                                    <div className={'flexCenter childrenSpacingLeftBigPhone paddingTopBottomBigPhone'}>
                                        {socialize.map(v => <img onClick={() => v[1] && windowOpen(v[1])}
                                                                 src={requireDefault(v[0])}
                                                                 style={{height: heightPhone}}
                                                                 alt=""/>)}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {content}
                    {showEnd && <div onClick={devCountAdd}
                                     className={'paddingPhone backgroundBlack textAlignCenter'}
                                     style={{fontSize: '.5rem', color: 'rgba(255,255,255,.5)'}}
                    >
                        COPYRIGHT 2023 PETGPT LAB LTD. ALL RIGHTS RESERVED | POWERED BY PETGPT LAB LTD.
                    </div>}
                    {connectLoading(10)}
                </div>,
                supportEmail = () => window.location.href = 'mailto:support@petgpt.ai',
                pageTop = [
                    <div
                        className={'ttf positionFixed flex justifyContentSpaceBetween alignItemsCenter pagePaddingLeftRight zIndex999'}
                        style={{left: 0, right: 0, height: '1.5rem', background: 'rgba(0,0,0,.5)'}}>
                        {(() => {
                            return [
                                <img onClick={replaceHome} src={requireDefault('logo')}
                                     style={{height}} alt="" className={'cursorPointer'}/>,
                                <div className={'flex childrenSpacingLeftBigger alignItemsCenter'}>
                                    {[
                                        ['howToPlay', () => windowReplace('/howToPlay')],
                                        ['whitepaper', () => windowOpen('https://petgpt.gitbook.io/petgpt-whitepaper/')],
                                        ['genesis', replaceGenesis],
                                        ['marketplace', replaceMarketplace],
                                    ].map(v =>
                                        <img
                                            src={requireDefault(`${v[0]}${v[0] === pNs0 ? 'Active' : ''}`)}
                                            style={{height: '.3rem'}} alt=""
                                            className={'cursorPointer'}
                                            onClick={v[1]}
                                        />)}
                                </div>,
                                <div className={'flex childrenSpacingLeftBig alignItemsCenter'}>
                                    {
                                        socialize.map(v => <img onClick={() => v[1] && windowOpen(v[1])}
                                                                src={requireDefault(v[0])}
                                                                style={{height}}
                                                                alt="" className={'cursorPointer'}/>)
                                    }
                                    <img src={requireDefault('line')} style={{height}} alt=""
                                         className={'cursorPointer'}/>
                                    {
                                        address
                                            ? <div className={'positionRelative cursorPointer'}
                                                   onClick={connect}
                                            >
                                                {
                                                    wrongNetwork
                                                        ? <div className={'borderRadius999'}
                                                               style={{
                                                                   color: '#E832CC',
                                                                   padding: '.15rem .45rem',
                                                                   border: '.03rem solid #E832CC',
                                                               }}>
                                                            Wrong network
                                                        </div>
                                                        : <div className={'borderRadius999 activeColor'}
                                                               style={{
                                                                   border: '.03rem solid #BAFF00',
                                                                   padding: '.15rem .45rem'
                                                               }}>
                                                            {addressLeaveOut}
                                                        </div>
                                                }
                                            </div>
                                            : <img onClick={connect}
                                                   src={requireDefault('wallet')}
                                                   style={{height}}
                                                   alt="" className={'cursorPointer'}/>
                                    }
                                </div>
                            ]
                        })()}
                    </div>,
                    connectLoading(5)
                ],
                pageFoot = <div style={{height: '1rem', color: 'rgba(255,255,255,.5)', padding: '0 2rem'}}
                                className={'width100 backgroundBlack flexCenter justifyContentSpaceBetween boxSizingBorderBox'}
                >
                    <div onClick={devCountAdd}>
                        COPYRIGHT 2023 PETGPT LAB LTD. ALL RIGHTS RESERVED | POWERED BY PETGPT LAB LTD.
                    </div>
                    <img onClick={supportEmail} className={'cursorPointer'} src={requireDefault('support')} alt=""
                         style={{height: '30%'}}/>
                </div>,
                paddingBorder = {
                    paddingBottom: '.3rem',
                    borderBottom: '.05rem solid #D8D8D8'
                },
                tokenIdJson = tokenId => `${tokenId}.json`,
                scientistMetadataJson = tokenId => require(`./assets/${chainValue.scientistMetadataPath}/${tokenIdJson(tokenId)}`),
                scientistMetadataObj = t => {
                    t = t || tokenId
                    return scientistMetadataList.includes(tokenIdJson(t)) ? scientistMetadataJson(t) : {}
                },
                scientistMetadata = scientistMetadataObj(),
                {image: scientistMetadataImage, name: scientistMetadataName} = scientistMetadata,
                activity = checkDataArray(data, 'activity'),
                nftContracts = checkData([data, 'nftContracts'], defaultNFTContracts),
                nftContractIndex = checkData(
                    [data, 'nftContractIndex'],
                    (() => {
                        const sessionNFTContractIndex = sessionStorage.getItem('nftContractIndex')
                        return isNotNull(sessionNFTContractIndex) ? Number(sessionNFTContractIndex) : 0
                    })()
                ),
                setNFTContractIndex = i => {
                    setData({nftContractIndex: i})
                    sessionStorage.setItem('nftContractIndex', i)
                },
                nftContract = checkData([nftContracts, nftContractIndex], checkDataObject(nftContracts, 0)),
                {
                    name: nftContractName,
                    eth: nftContractETH,
                    address: nftContractAddress,
                    abi: nftContractAbi,
                    metadataList: nftContractMetadataList,
                    is721A,
                    axiosOfferBids
                } = nftContract,
                {address: marketContractAddress, abi: marketContractAbi} = nftContract.marketContract,
                offerBids = checkDataArray(data, 'offerBids'),
                metaJsons = checkDataObject(data, 'metaJsons'),
                disableCaching = url => `${url}?t=${new Date().getTime()}`,
                getMetaJsonList = (tokens = [], baseURI) => {
                    const isNftContractMetadataList = !isGenesis && nftContractMetadataList, metaJsonList = {};
                    return isNftContractMetadataList
                        ? (new Promise(resolve => resolve()))
                        : Promise.all(tokens.map(
                            async v => {
                                const tokenId = typeof v === 'object' ? v.tokenId : v
                                metaJsonList[Number(tokenId)] = await (baseURI
                                    ? (new Promise(resolve => resolve(`${baseURI}${tokenId}`)))
                                    : ((isGenesis && tokenId) ? rC : rCNFTContract)('tokenURI', tokenId)).then(url =>
                                    axios({url: disableCaching(url)}).then(r => checkDataObject(r, 'data'))
                                ).catch(() => ({}))
                            }
                        )).then(() => !isNftContractMetadataList && setData({metaJsons: {...metaJsons, ...metaJsonList}}))
                },
                metadataJson = tokenId => {
                    const {metadataPath} = nftContract;
                    return (!isGenesis && metadataPath) ? require(`./assets/${metadataPath}/${tokenIdJson(tokenId)}`) : {image: requireDefault('mint', 'gif'), ...checkDataObject(metaJsons, Number(tokenId))}
                },
                metadataObj = t => {
                    t = t || tokenId
                    return (!isGenesis && nftContractMetadataList) ? (nftContractMetadataList.includes(tokenIdJson(t)) ? metadataJson(t) : {}) : metadataJson(t)
                },
                metadata = metadataObj(),
                {image: metadataImage, name: metadataName} = metadata,
                transactionRecords = (arr = [], func = () => {
                }) => {
                    const iL = []
                    return Promise.all(arr.map(async v =>
                        tokenId === Number(checkData([v, 'returnValues', isGenesis ? 'scientistId' : 'tokenId'])).toString() &&
                        iL.push(
                            [
                                ...func(v),
                                npTimes(Number((await (isMarketplacePages ? nftContractETH : eth).getBlock(v.blockHash)).timestamp), 1000)
                            ]
                        ))).then(() => iL.sort((a, b) => b[b.length - 1] - a[a.length - 1])
                        .map(v => {
                            const vLength1 = v.length - 1
                            return [...v.slice(0, vLength1), timestampToDate(v[vLength1])]
                        }))
                },
                token = () => localStorage.getItem('token'),
                requestApi = (url, data, method, headers = {}) => {
                    const t = token()
                    return axios(
                        {
                            method,
                            url: `${chainValue.api}${url}`,
                            [method ? 'data' : 'params']: data,
                            headers: {
                                ...headers,
                                ...ternaryObject(t, {'Authorization': `Bearer ${t}`})
                            },
                        },
                    ).then(r => {
                        const d = checkDataObject(r, 'data'),
                            isSuccess = d.code === '1'
                        if (!isSuccess) {
                            toast(d.msg)
                            toggleShowLoading()
                        }
                        return isSuccess ? checkDataObject(d, 'data') : null
                    }).catch(e => {
                        toggleShowLoading()
                        toast('Unknown error')
                        console.error(`------------------------------\n`, e, `------------------------------\n`)
                    })
                },
                loginThenFunc = (func = () => {
                }, errorFunc = () => {
                }) => token()
                    ? func()
                    : new Promise(resolve => requestApi('sys/code', {address}).then(r => {
                            setTimeout(() => {
                                const code = checkData([r, 'code'])
                                signMessage({message: code}).then(sign =>
                                    requestApi(
                                        'sys/login',
                                        {
                                            code,
                                            sign,
                                            address,
                                            loginType: 'WEB',
                                            network: chainValue.loginNetwork,
                                        },
                                        'post'
                                    ).then(r => {
                                            const token = checkData([r, 'token'])
                                            if (token) {
                                                localStorage.setItem('token', token)
                                                resolve(func())
                                            }
                                        }
                                    )
                                ).catch(e => {
                                    errorFunc()
                                    toast(checkDataString(checkDataString(e, 'message').split('\n'), 0))
                                })
                            }, 1000)
                        })
                    ),
                showAttributes = checkDataBool(data, 'showAttributes'),
                attributesContentFunc = (sA = scientistAttributes) => {
                    const attributeDisplay = checkDataArray(data, 'attributeDisplay')
                    return isPhoneClientWidth
                        ? <div>
                            {
                                [
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div className={'saf'}>{isGenesis ? 'Genesis Cat' : ''}</div>
                                        <div className={'flexCenter childrenSpacingLeftPhone'}>
                                            {
                                                [
                                                    ['list1', () => setData({showAttributes: !showAttributes})],
                                                ].map(v => <img src={requireDefault(v[0])} alt=""
                                                                onClick={v[1]}
                                                                style={{width: '1.5rem'}}/>)
                                            }
                                        </div>
                                    </div>,
                                    <div
                                        style={ternaryObject(!showAttributes, {borderColor: '#D8D8D8'})}
                                        className={'paddingBottom borderBottomSmallGrayLinePhone positionRelative'}
                                    >
                                        {
                                            (() => {
                                                const
                                                    attributeDisplay = checkDataArray(data, 'attributeDisplay')
                                                return showAttributes &&
                                                    <div style={{padding: '1rem 3rem 3rem 3rem'}}
                                                         className={'width100 boxSizingBorderBox positionAbsolute backgroundWhite'}>
                                                        {
                                                            sA.map((v, i) =>
                                                                <div
                                                                    className={'childrenSpacingTopPhone'}
                                                                    style={{
                                                                        padding: '1rem 1rem 1rem 0',
                                                                        borderBottom: '.1rem solid #D8D8D8'
                                                                    }}
                                                                >
                                                                    {
                                                                        (() => {
                                                                            const aDi = attributeDisplay[i]
                                                                            return [
                                                                                <div
                                                                                    onClick={() => setData((() => {
                                                                                        const aD = attributeDisplay
                                                                                        aD[i] = !aDi
                                                                                        return {attributeDisplay: aD}
                                                                                    })())}
                                                                                    className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                                                    <div
                                                                                        className={'flexCenter childrenSpacingLeftPhone'}>
                                                                                        <img
                                                                                            style={{width: '2rem'}}
                                                                                            src={requireDefault(v[0])}
                                                                                            alt=""/>
                                                                                        <div>{v[0]}</div>
                                                                                    </div>
                                                                                    <img
                                                                                        style={{width: '1rem'}}
                                                                                        src={requireDefault('more')}
                                                                                        alt=""/>
                                                                                </div>,
                                                                                aDi && <div
                                                                                    className={'childrenSpacingTopPhone'}>
                                                                                    {v[1].map(v =>
                                                                                        <div>{v}</div>)}
                                                                                </div>
                                                                            ]
                                                                        })()
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                            })()
                                        }
                                    </div>
                                ]
                            }
                        </div>
                        : <div style={{width: '7rem'}}>
                            {<div className={'saf biggerFontSize'} style={paddingBorder}>
                                {isGenesis ? 'Genesis Cat' : ''}
                            </div>}
                            <div style={{paddingTop: '1rem'}}>
                                {
                                    (isGenesis ? sA : nftContract.attributes).map((v, i) =>
                                        <div className={'childrenSpacingTopBig'}
                                             style={{
                                                 ...paddingBorder,
                                                 paddingRight: '1rem',
                                                 paddingTop: '.3rem'
                                             }}
                                        >
                                            {
                                                (() => {
                                                    const aDi = attributeDisplay[i]
                                                    return [
                                                        <div
                                                            onClick={() => setData((() => {
                                                                const aD = attributeDisplay
                                                                aD[i] = !aDi
                                                                return {attributeDisplay: aD}
                                                            })())}
                                                            className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                            <div
                                                                className={'flexCenter childrenSpacingLeftBig'}>
                                                                <img
                                                                    style={{width: '.6rem'}}
                                                                    src={requireDefault(v[0])}
                                                                    alt=""/>
                                                                <div
                                                                    className={'bigFontSize'}>{v[0]}</div>
                                                            </div>
                                                            <img
                                                                style={{width: '.3rem'}}
                                                                src={requireDefault('more')}
                                                                alt=""/>
                                                        </div>,
                                                        aDi &&
                                                        <div
                                                            className={'childrenSpacingTopBig'}>
                                                            {v[1].map(v =>
                                                                <div>{v}</div>)}
                                                        </div>
                                                    ]
                                                })()
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                },
                attributesContent = attributesContentFunc(),
                newAttributesContent = attributesContentFunc(newScientistAttributes),
                seller = checkDataString(data, 'seller'),
                owner = checkData([data, 'owner']),
                addressEQAddress = (a = owner, a1 = address) => checkDataString(a).toString().toLowerCase() === checkDataString(a1).toLowerCase(),
                floorPrice = checkData0(data, 'floorPrice'),
                newSalePrice = checkDataString(data, 'newSalePrice'),
                setNewSalePrice = (nSP = 0) => setData({
                    newSalePrice: nSP.toString().replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                        .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                        .replace(/^(\-)*(\d+)\.(\d{6}).*$/, '$1$2.$3')
                }),
                {chain: nftContractChain} = nftContract,
                toggleShowMarketplaceDialog = sMPD =>
                    setData({
                        showMarketplaceDialog: sMPD,
                        ...ternaryObject(!sMPD, {
                            isBuy: false,
                            isBid: false,
                            confirmSell: false,
                            newSalePrice: ''
                        })
                    }),
                wCMarketSuccess = checkData([data, 'wCMarketSuccess']),
                toggleWCMarketSuccess = (wCMS = false) => setData({wCMarketSuccess: wCMS}),
                wCMarket = (needApprovedForAll, value, functionName, ...args) => switchEthereumChain(
                    () => {
                        toggleWCMarketSuccess()
                        toggleShowLoading(true)
                        const toggleShowLoadingToast = (text, tSMD = true, toggleSL = true) => {
                            toast(text)
                            toggleSL && toggleShowLoading()
                            tSMD && toggleShowMarketplaceDialog()
                        }
                        canAccessWalletConnectUrl(
                            () => (
                                needApprovedForAll
                                    ? rCNFTContract('isApprovedForAll', address, marketContractAddress)
                                    : new Promise(resolve => resolve(false))
                            ).then(isApprovedForAll => {
                                    setData({isApprovedForAll})
                                    if (!needApprovedForAll || isApprovedForAll) {
                                        prepareWriteContract({
                                            functionName,
                                            abi: marketContractAbi,
                                            address: marketContractAddress,
                                            walletClient: {account: address},
                                            args: [nftContractAddress, ...args],
                                            ...ternaryObject(value > 0, {value}),
                                        }).then(r => writeContract(r.request).then(r => {
                                            const hash = checkData([r, 'hash'])
                                            hash && toggleWCMarketSuccess(true)
                                            toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                                            toggleWCMarketSuccess()
                                        })
                                            .catch(e => toggleShowLoadingToast(e.message.split('\n')[0], false)))
                                            .catch(e => toggleShowLoadingToast([1, 2].map(v => e.message.split('\n')[v]).join('\n'), false))
                                    } else {
                                        prepareWriteContract({
                                            abi: nftContractAbi,
                                            address: nftContractAddress,
                                            functionName: 'setApprovalForAll',
                                            walletClient: {account: address},
                                            args: [marketContractAddress, true],
                                        }).then(r => writeContract(r.request).then(r => {
                                            const hash = checkData([r, 'hash'])
                                            hash && setTimeout(() => {
                                                toggleShowLoadingToast(hash ? 'Successful' : 'Failed', false, false)
                                                wCMarket(needApprovedForAll, value, functionName, ...args)
                                            }, 1000)
                                        })
                                            .catch(e => toggleShowLoadingToast(e.message.split('\n')[0], false)))
                                            .catch(e => toggleShowLoadingToast([1, 2].map(v => e.message.split('\n')[v]).join('\n'), false))
                                    }
                                }
                            ).catch(() => toggleShowLoading()),
                            () => toggleShowLoading()
                        )
                    },
                    () => toggleShowMarketplaceDialog(),
                    true,
                    nftContractChain
                ),
                tokenDetail = (() => {
                    const ownerLeaveOut = leaveOut(owner),
                        oEA = addressEQAddress(),
                        incomeList = checkDataArray(data, 'incomeList'),
                        traits =
                            (() => {
                                const obj = {}
                                checkDataArray(metadata, 'attributes').map(v => {
                                    const {trait_type} = v
                                    if (trait_type !== 'Background') obj[trait_type] = v.value
                                })
                                return (isGenesis ? scientistTraits : nftContract.traits).map(v => [v, obj[v] || Object.values(obj)[0]])
                            })(),
                        [
                            salePrice,
                            bidder,
                            bidPrice,
                            isRoyalty,
                        ] = checkData([
                            data,
                            ['salePrice'],
                            ['bidder'],
                            ['bidPrice'],
                            ['isRoyalty'],
                        ], 0),
                        salePriceGT0 = salePrice > 0,
                        bidPriceGT0 = bidPrice > 0,
                        serviceFee = 0,
                        royalty = isRoyalty ? checkData0(nftContract, 'royalty') : 0,
                        confirmSell = oEA && checkDataBool(data, 'confirmSell'),
                        isApprovedForAll = checkDataBool(data, 'isApprovedForAll'),
                        isBuy = !oEA && salePriceGT0 && checkDataBool(data, 'isBuy'),
                        isBid = checkDataBool(data, 'isBid'),
                        NFTAddressIsBid = checkDataBool(data, 'NFTAddressIsBid'),
                        bidText = [...ternaryArray(oEA, ['ACCEPT']), 'BID'].join(' '),
                        toggleIsBuyIsBid = isBuy => setData({
                            isBuy,
                            isBid: !isBuy
                        }),
                        isAcceptBid = oEA && isBid && bidPriceGT0,
                        minaHandelText = oEA ? (isAcceptBid ? bidText : 'SELL') : (isBuy ? 'BUY' : bidText),
                        // totalPrice = numberToFixed(
                        //     (isAcceptBid ? bidPrice : newSalePrice) *
                        //     ((isBid && !isAcceptBid)
                        //         ? 1
                        //         : (1 - (serviceFee + royalty) / 100))
                        // ),
                        totalPrice = numberToFixed(
                            npTimes(
                                (isAcceptBid ? bidPrice : newSalePrice),
                                (oEA
                                    ? npMinus(
                                        1,
                                        npDivide(npPlus(serviceFee, royalty), 100)
                                    )
                                    : 1)
                            )
                        ),
                        handel = remove => {
                            const nSP = parseEtherWei(newSalePrice), bP = parseEtherWei(bidPrice),
                                checkNewSalePrice = (func = () => {
                                }) => (!remove && (!isNotNullOrEmpty(newSalePrice) || Number(newSalePrice) <= 0)) ? toast('Price cannot be empty') : func()
                            if (oEA) {
                                const setDataConfirmSell = () => setData({confirmSell: !confirmSell})
                                if (isAcceptBid) {
                                    setDataConfirmSell()
                                    wCMarket(true, 0, 'acceptBidForToken', tokenId, bP)
                                } else {
                                    if (nSP <= 0 || bP <= 0 || nSP > bP) {
                                        checkNewSalePrice(() => {
                                            setDataConfirmSell()
                                            wCMarket(true, 0, 'offerTokenForSale', tokenId, remove ? 0 : nSP)
                                        })
                                    } else {
                                        toast('Same or higher bid already available, can choose to accept')
                                    }
                                }
                            } else {
                                if (isBuy) {
                                    addressEQAddress(bidder, address) && bidPriceGT0
                                        ? toast('Please remove bid before you buy')
                                        : wCMarket(false, parseEtherWei(salePrice), 'buyToken', tokenId)
                                } else {
                                    checkNewSalePrice(() => wCMarket(false, remove ? 0 : nSP, 'enterBidForToken', tokenId))
                                }
                            }
                        },
                        marketplaceDialog = checkDataBool(data, 'showMarketplaceDialog') && (
                            isPhoneClientWidth
                                ? <div className={'positionFixed flexColumnCenter'}
                                       style={{
                                           top: 0,
                                           left: 0,
                                           right: 0,
                                           bottom: 0,
                                           zIndex: 1000,
                                           background: 'rgba(0,0,0,.56)',
                                       }}>
                                    <div
                                        className={'backgroundWhite borderRadiusPhone paddingBigPhone childrenSpacingTopBigPhone overflowYScroll boxSizingBorderBox'}
                                        style={{width: '27rem', maxHeight: '97vh'}}
                                    >
                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                            <div
                                                className={'bigFontSizePhone saf'}>{
                                                oEA
                                                    ? (isAcceptBid ? bidText : [...ternaryArray(salePriceGT0, ['EDIT']), 'LISTING'].join(' '))
                                                    : (isBuy ? 'Make offer' : minaHandelText)
                                            }</div>
                                            <img onClick={() => toggleShowMarketplaceDialog()}
                                                 src={requireDefault('closeBlack')}
                                                 alt="" style={{width: '1.5rem'}} className={'cursorPointer'}/>
                                        </div>
                                        <div className={'flex childrenSpacingLeftBigPhone'}>
                                            <img src={metadataImage} className={'borderRadiusPhone'} alt=""
                                                 style={{width: '7rem', height: '7rem'}}/>
                                            <div
                                                className={'flexColumn justifyContentCenter childrenSpacingTopBigPhone flexGrow1'}
                                            >
                                                <div>{metadataName}</div>
                                                <div
                                                    className={'colorGray textOverflowEllipsis smallFontSizePhone'}>{nftContractName}</div>
                                                {(!oEA || isAcceptBid) &&
                                                    <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                        <div className={'colorGray smallFontSizePhone'}>Floor Price</div>
                                                        <img src={requireDefault('ETH')} alt="" style={{width: '1rem'}}/>
                                                        <div className={'smallFontSizePhone'}>{floorPrice}</div>
                                                    </div>}
                                            </div>
                                        </div>
                                        {
                                            (salePriceGT0 || confirmSell) &&
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div>Listing Price</div>
                                                <div className={'flexCenter childrenSpacingLeft'}>
                                                    <img src={requireDefault('ETH')} alt="" style={{width: '1rem'}}/>
                                                    <div>{confirmSell ? numberToFixed(newSalePrice) : salePrice} ETH
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isAcceptBid &&
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div>Bid Price</div>
                                                <div className={'flexCenter childrenSpacingLeft'}>
                                                    <img src={requireDefault('ETH')} alt="" style={{width: '1rem'}}/>
                                                    <div>{bidPrice} ETH
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            confirmSell
                                                ? [
                                                    <div
                                                        className={'paddingBigPhone backGroundBlueGray childrenSpacingTopPhone borderRadiusPhone'}>
                                                        {
                                                            [
                                                                [isApprovedForAll ? 'confirmSell' : 'confirmSell1', 'Approve Collection', isApprovedForAll ? 'colorGray' : ''],
                                                                [isApprovedForAll ? 'confirmSell2' : 'confirmSell2Grey', isBid ? 'Accept Bid' : 'Complete Listing', isApprovedForAll ? '' : 'colorGray'],
                                                            ].map(v => <div
                                                                className={`flex alignItemsCenter childrenSpacingLeftPhone ${v[2]}`}>
                                                                <img src={requireDefault(v[0])} alt="" style={{width: '1.5rem'}}/>
                                                                <div>{v[1]}</div>
                                                            </div>)
                                                        }
                                                    </div>
                                                ]
                                                : [
                                                    ...ternaryArray(
                                                        !isAcceptBid,
                                                        [
                                                            <div className={'childrenSpacingTopBigPhone'}>
                                                                {!isBuy && <div>Price</div>}
                                                                {
                                                                    oEA &&
                                                                    <div style={{borderColor: '#D8D8D8'}}
                                                                         onClick={() => setNewSalePrice(floorPrice)}
                                                                         className={'borderSmallGrayLine borderRadius999 flexCenter childrenSpacingLeftBigPhone paddingBigPhone cursorPointer'}
                                                                    >
                                                                        <div style={{color: '#E832CC'}}>
                                                                            Floor Price
                                                                        </div>
                                                                        <div>{floorPrice} ETH</div>
                                                                    </div>
                                                                }
                                                                {(oEA || isBid || !isBuy) && <div
                                                                    className={'flex alignItemsCenter borderRadius999 backGroundBlueGray paddingBigPhone childrenSpacingLeftBigger'}>
                                                                    <div className={'flexCenter childrenSpacingLeftPhone'}>
                                                                        <img src={requireDefault('ETH')} alt=""
                                                                             style={{width: '1rem'}}/>
                                                                        <div>ETH</div>
                                                                    </div>
                                                                    <div style={{
                                                                        width: '.2rem',
                                                                        height: '1.2rem',
                                                                        background: '#979797'
                                                                    }}/>
                                                                    <input type="text" className={'flexGrow1'} value={newSalePrice}
                                                                           onChange={e => setNewSalePrice(e.target.value)}/>
                                                                </div>}
                                                            </div>
                                                        ]
                                                    ),
                                                    <div className={'childrenSpacingTopPhone'}>
                                                        <div className={'childrenSpacingTopPhone colorGray'}>
                                                            {
                                                                oEA && [
                                                                    ...ternaryArray(!isAcceptBid, [['Price', `${newSalePrice} ETH`]]),
                                                                    ['Service Fee', `${serviceFee}%`],
                                                                    ['Royalty', `${royalty}%`],
                                                                ].map((v, i) => <div
                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div>{v[0]}</div>
                                                                    <div
                                                                        className={'flexCenter childrenSpacingLeftPhone flexGrow1 justifyContentFlexEnd'}>
                                                                        {!i &&
                                                                            <img src={requireDefault('ETH')} alt=""
                                                                                 style={{width: '1rem'}}/>}
                                                                        <div className={'textOverflowEllipsis'}>{v[1]}</div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                        {!isBuy && <div className={'flexCenter justifyContentSpaceBetween'}>
                                                            <div>Total {oEA ? 'Revenue' : ''}</div>
                                                            <div className={'flexCenter childrenSpacingLeftPhone'}>
                                                                <img src={requireDefault('ETH')} alt=""
                                                                     style={{width: '1rem'}}/>
                                                                <div>{totalPrice} ETH</div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                ]
                                        }
                                        {!confirmSell &&
                                            <div className={'flexCenter childrenSpacingLeftBigPhone childrenFlexGrow1'}>
                                                {
                                                    [
                                                        [false, minaHandelText, () => handel()],
                                                        ...ternaryArray(
                                                            !isAcceptBid && (oEA && salePriceGT0) || (!oEA && isBid && addressEQAddress(bidder) && bidPriceGT0),
                                                            [[true, `REMOVE ${minaHandelText}`, () => handel(true)]]
                                                        ),
                                                    ].map(v => <div onClick={checkDataFunction(v, 2)}
                                                                    className={`borderRadius999 flexCenter 
                                             ${v[0] ? 'paddingPhone borderBlackLine' : 'paddingBigPhone backgroundBlack activeColor'} cursorPointer`}>
                                                        {v[1]}
                                                    </div>)
                                                }
                                            </div>}
                                    </div>
                                </div>
                                : <div className={'positionFixed flexColumnCenter'}
                                       style={{
                                           top: 0,
                                           left: 0,
                                           right: 0,
                                           bottom: 0,
                                           zIndex: 1000,
                                           background: 'rgba(0,0,0,.56)',
                                       }}>
                                    <div className={'backgroundWhite paddingBigger childrenSpacingTopBigger'}
                                         style={{borderRadius: '.4rem', width: '9rem'}}
                                    >
                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                            <div
                                                className={'biggerFontSize saf'}>{
                                                oEA
                                                    ? (isAcceptBid ? bidText : [...ternaryArray(salePriceGT0, ['EDIT']), 'LISTING'].join(' '))
                                                    : (isBuy ? 'Make offer' : minaHandelText)
                                            }</div>
                                            <img onClick={() => toggleShowMarketplaceDialog()}
                                                 src={requireDefault('closeBlack')}
                                                 alt="" style={{width: '.6rem'}} className={'cursorPointer'}/>
                                        </div>
                                        <div className={'flex childrenSpacingLeftBig'}>
                                            <img src={metadataImage} className={'borderRadius'} alt=""
                                                 style={{width: '2rem'}}/>
                                            <div className={'flexColumn childrenSpacingTopBig flexGrow1'}
                                                 style={{padding: '.1rem 0'}}
                                            >
                                                <div className={'bigFontSize'}>{metadataName}</div>
                                                <div className={'colorGray textOverflowEllipsis'}>{nftContractName}</div>
                                                {(!oEA || isAcceptBid) &&
                                                    <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                        <div className={'colorGray'}>Floor Price</div>
                                                        <img src={requireDefault('ETH')} alt="" style={{width: '.3rem'}}/>
                                                        <div>{floorPrice}</div>
                                                    </div>}
                                            </div>
                                        </div>
                                        {
                                            (salePriceGT0 || confirmSell) &&
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div className={'bigFontSize'}>Listing Price</div>
                                                <div className={'flexCenter childrenSpacingLeft'}>
                                                    <img src={requireDefault('ETH')} alt="" style={{width: '.3rem'}}/>
                                                    <div
                                                        className={'bigFontSize'}>{confirmSell ? numberToFixed(newSalePrice) : salePrice} ETH
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isAcceptBid &&
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div className={'bigFontSize'}>Bid Price</div>
                                                <div className={'flexCenter childrenSpacingLeft'}>
                                                    <img src={requireDefault('ETH')} alt="" style={{width: '.3rem'}}/>
                                                    <div
                                                        className={'bigFontSize'}>{bidPrice} ETH
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            confirmSell
                                                ? [
                                                    <div
                                                        className={'paddingBig backGroundBlueGray childrenSpacingTop borderRadius'}>
                                                        {
                                                            [
                                                                [isApprovedForAll ? 'confirmSell' : 'confirmSell1', 'Approve Collection', isApprovedForAll ? 'colorGray' : ''],
                                                                [isApprovedForAll ? 'confirmSell2' : 'confirmSell2Grey', isBid ? 'Accept Bid' : 'Complete Listing', isApprovedForAll ? '' : 'colorGray'],
                                                            ].map(v => <div
                                                                className={`flex alignItemsCenter childrenSpacingLeft ${v[2]}`}>
                                                                <img src={requireDefault(v[0])} alt="" style={{width: '.5rem'}}/>
                                                                <div>{v[1]}</div>
                                                            </div>)
                                                        }
                                                    </div>
                                                ]
                                                : [
                                                    ...ternaryArray(
                                                        !isAcceptBid,
                                                        [
                                                            <div className={'childrenSpacingTopBig'}>
                                                                {!isBuy && <div className={'bigFontSize'}>Price</div>}
                                                                {
                                                                    oEA &&
                                                                    <div style={{borderColor: '#D8D8D8'}}
                                                                         onClick={() => setNewSalePrice(floorPrice)}
                                                                         className={'borderSmallGrayLine borderRadius999 flexCenter childrenSpacingLeftBig paddingBig cursorPointer'}
                                                                    >
                                                                        <div className={'bigFontSize'} style={{color: '#E832CC'}}>
                                                                            Floor Price
                                                                        </div>
                                                                        <div className={'bigFontSize'}>{floorPrice} ETH</div>
                                                                    </div>
                                                                }
                                                                {(oEA || isBid || !isBuy) && <div
                                                                    className={'flex alignItemsCenter borderRadius999 backGroundBlueGray paddingBig childrenSpacingLeftBigger'}>
                                                                    <div className={'flexCenter childrenSpacingLeft'}>
                                                                        <img src={requireDefault('ETH')} alt=""
                                                                             style={{width: '.3rem'}}/>
                                                                        <div className={'bigFontSize'}>ETH</div>
                                                                    </div>
                                                                    <div style={{
                                                                        width: '.05rem',
                                                                        height: '.4rem',
                                                                        background: '#979797'
                                                                    }}/>
                                                                    <input type="text" className={'flexGrow1'} value={newSalePrice}
                                                                           onChange={e => setNewSalePrice(e.target.value)}/>
                                                                </div>}
                                                            </div>
                                                        ]
                                                    ),
                                                    <div className={'childrenSpacingTop'}>
                                                        <div className={'childrenSpacingTop colorGray'}>
                                                            {
                                                                oEA && [
                                                                    ...ternaryArray(!isAcceptBid, [['Price', `${newSalePrice} ETH`]]),
                                                                    ['Service Fee', `${serviceFee}%`],
                                                                    ['Royalty', `${royalty}%`],
                                                                ].map((v, i) => <div
                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div>{v[0]}</div>
                                                                    <div
                                                                        className={'flexCenter childrenSpacingLeft flexGrow1 justifyContentFlexEnd'}>
                                                                        {!i &&
                                                                            <img src={requireDefault('ETH')} alt=""
                                                                                 style={{width: '.3rem'}}/>}
                                                                        <div className={'textOverflowEllipsis'}>{v[1]}</div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                        {!isBuy && <div className={'flexCenter justifyContentSpaceBetween'}>
                                                            <div className={'bigFontSize'}>Total {oEA ? 'Revenue' : ''}</div>
                                                            <div className={'flexCenter childrenSpacingLeft'}>
                                                                <img src={requireDefault('ETH')} alt=""
                                                                     style={{width: '.3rem'}}/>
                                                                <div className={'bigFontSize'}>{totalPrice} ETH</div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                ]
                                        }
                                        {!confirmSell &&
                                            <div className={'flexCenter childrenSpacingLeftBigger childrenFlexGrow1'}>
                                                {
                                                    [
                                                        [false, minaHandelText, () => handel()],
                                                        ...ternaryArray(
                                                            !isAcceptBid && (oEA && salePriceGT0) || (!oEA && isBid && addressEQAddress(bidder) && bidPriceGT0),
                                                            [[true, `REMOVE ${minaHandelText}`, () => handel(true)]]
                                                        ),
                                                    ].map(v => <div onClick={checkDataFunction(v, 2)}
                                                                    className={`borderRadius999 bigFontSize flexCenter 
                                             ${v[0] ? 'padding borderBlackLine' : 'paddingBig backgroundBlack activeColor'} cursorPointer`}>
                                                        {v[1]}
                                                    </div>)
                                                }
                                            </div>}
                                    </div>
                                </div>
                        ),
                        activitys = isGenesis
                            ? [
                                ['VALUE', 30],
                                ['HASH', 30],
                                ['TIME', 30],
                            ]
                            : [
                                ['ACTION', 22],
                                ['PRICE', 22],
                                ['FROM', 10],
                                ['TO', 10],
                                ['HASH', 10],
                                ['TIME', 22],
                            ],
                        mintSBTPrice = checkData0(data, 'mintSBTPrice')
                    return isPhoneClientWidth
                        ? <div className={'backgroundBlack'}>
                            <div className={'paddingTopLeftRightBigPhone childrenSpacingTopPhone'} style={{
                                paddingTop: '3.5rem', paddingBottom: '4rem',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 90%, rgba(186, 255, 0, .2) 100%)',
                            }}>
                                <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                    <div className={'colorWhite saf smallFontSizePhone cursorPointer'}
                                         onClick={isGenesis ? replaceGenesis : replaceMarketplace}>
                                        {isGenesis ? 'genesis' : 'marketplace'}
                                    </div>
                                    <div className={'colorWhite smallFontSizePhone'}>{'>'}</div>
                                    <div className={'activeColor saf smallFontSizePhone'}>
                                        {isGenesis ? 'MINT SBT' : metadataName}
                                    </div>
                                </div>
                                <div className={'childrenSpacingTopBigPhone'}>
                                    <div
                                        className={'saf biggerFontSize colorWhite flex alignItemsCenter childrenSpacingLeftPhone'}>
                                        <div className={'saf'} style={{fontSize: '2rem'}}>{metadataName}</div>
                                    </div>
                                    <div className={'childrenSpacingTopBigPhone'}>
                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                            <div
                                                className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                <img className={'borderRadius999'} style={{width: '2.5rem'}}
                                                     src={metadataImage} alt=""/>
                                                <div className={'flexColumn childrenSpacingTop'}>
                                                    {
                                                        [
                                                            'Owner',
                                                            ownerLeaveOut
                                                        ].map(v => <div
                                                            className={'colorWhite smallFontSizePhone'}>{v}</div>)
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                {
                                                    [
                                                        ['etherscan', isGenesis ? windowOpenEtherscan : () => windowOpen(nftContract.etherscan)],
                                                    ].map(v => <img src={requireDefault(v[0])}
                                                                    onClick={v[1]}
                                                                    className={'cursorPointer'} alt=""
                                                                    style={{width: '1.5rem'}}/>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'childrenSpacingTopBigPhone'}>
                                        <img src={metadataImage} alt=""
                                             className={'width100 borderRadiusPhone'}/>
                                        {
                                            isGenesis
                                                ? [
                                                    <div onClick={mintSbt}
                                                         style={{paddingLeft: '1rem', paddingRight: '1rem'}}
                                                         className={'backgroundActiveColor borderRadiusPhone paddingPhone bigFontSizePhone flexCenter justifyContentSpaceBetween cursorPointer'}
                                                    >
                                                        <div>Mint SBT</div>
                                                        <div>{mintSBTPrice} ETH</div>
                                                    </div>
                                                ]
                                                : [
                                                    ...oEA
                                                        ? [[false, 'SELL', salePrice]]
                                                        : ternaryArray(salePriceGT0, [[false, 'BUY', salePrice, () => toggleIsBuyIsBid(true)]]),
                                                    ...ternaryArray((NFTAddressIsBid && (!oEA || bidPriceGT0)) || (addressEQAddress(bidder) && bidPriceGT0),
                                                        [[true, [...ternaryArray(oEA, ['ACCEPT']), 'BID'].join(' '),
                                                            bidPrice, () => toggleIsBuyIsBid(false)]]),
                                                ].map(v => {
                                                    const price = v[2], priceGT0 = price > 0
                                                    return <div onClick={() => {
                                                        checkDataFunction(v, 3)()
                                                        toggleShowMarketplaceDialog(true)
                                                    }}
                                                                style={{paddingLeft: '1rem', paddingRight: '1rem'}}
                                                                className={`${v[0] ? 'borderActiveColorPhone activeColor' : 'backgroundActiveColor'} borderRadiusPhone paddingPhone 
                                                                boxSizingBorderBox bigFontSizePhone flexCenter ${priceGT0 ? 'justifyContentSpaceBetween' : ''} cursorPointer`}
                                                    >
                                                        <div>{v[1]}</div>
                                                        {priceGT0 && <div>{price} ETH</div>}
                                                    </div>
                                                })
                                        }
                                        <div className={'childrenSpacingTopBigPhone'}>
                                            {
                                                [
                                                    [
                                                        'TRAITS',
                                                        <div
                                                            className={'flexCenter justifyContentSpaceBetween flexWrap'}>
                                                            {
                                                                traits.map(v => <div
                                                                    className={'flexCenter justifyContentSpaceBetween marginTopPhone childrenSpacingLeftSmall'}
                                                                    style={{
                                                                        width: `${(
                                                                            () => {
                                                                                const {length} = traits
                                                                                return length === 1 ? 100 : 43
                                                                            }
                                                                        )()}%`,
                                                                        padding: '.7rem',
                                                                        borderRadius: '.3rem',
                                                                        border: 'solid .07rem rgba(255,255,255,.3)'
                                                                    }}
                                                                >
                                                                    <img src={requireDefault(v[0])} alt=""
                                                                         style={{width: '2rem'}}/>
                                                                    <div
                                                                        className={'colorWhite smallFontSizePhone textOverflowEllipsis'}>{v[1]}</div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    ],
                                                    [
                                                        isGenesis ? 'Incubation income' : 'ACTIVITY',
                                                        <div>
                                                            <div style={{marginTop: '.5rem'}}
                                                                 className={'flexCenter justifyContentSpaceBetween'}>
                                                                {
                                                                    activitys.map((v, i) => <div
                                                                        className={`${i ? 'textAlignRight' : ''} smallerFontSizePhone`}
                                                                        style={{
                                                                            color: 'rgba(255,255,255,.3)',
                                                                            width: `${v[1]}%`,
                                                                        }}
                                                                    >
                                                                        {v[0]}
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div style={{height: '20rem'}}
                                                                 className={'overflowYScroll scrollbarNone'}>
                                                                {
                                                                    (isGenesis ? incomeList : activity).map(v => <div
                                                                        style={{marginTop: '.55rem'}}
                                                                        className={'flexCenter justifyContentSpaceBetween paddingTop'}>
                                                                        {
                                                                            v.map((v1, i) => <div
                                                                                className={`${i ? 'justifyContentFlexEnd' : ''} colorWhite bigFontSize 
                                                                            flex alignItemsCenter childrenSpacingLeftSmallPhone`}
                                                                                style={{width: `${activitys[i][1]}%`}}
                                                                            >
                                                                                {!isGenesis && !i &&
                                                                                    <img src={requireDefault(v[0])} alt=""
                                                                                         style={{width: '.5rem'}}/>}
                                                                                <div style={{fontSize: '.5rem'}}
                                                                                     className={'textOverflowEllipsis'}>{v1}</div>
                                                                            </div>)
                                                                        }
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>,
                                                    ],
                                                ].map(v => <div
                                                        className={`paddingPhone ${v[2]}`}
                                                        style={{
                                                            border: 'solid .07rem rgba(255,255,255,.3)',
                                                            borderRadius: '.3rem',
                                                        }}
                                                    >
                                                        <div className={'colorWhite saf paddingBottomPhone'}
                                                             style={{borderBottom: 'solid .07rem rgba(255,255,255,.1)'}}
                                                        >{v[0]}
                                                        </div>
                                                        {v[1]}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {marketplaceDialog}
                            {
                                showIncubation &&
                                <div className={'positionFixed flexColumnCenter paddingBigPhone'}
                                     style={{
                                         top: 0,
                                         left: 0,
                                         right: 0,
                                         bottom: 0,
                                         zIndex: 1000,
                                         background: 'rgba(0,0,0,.56)',
                                     }}>
                                    <div className={'positionRelative width100'}>
                                        <div className={'overflowHidden'}
                                             style={{borderRadius: '1rem'}}
                                        >
                                            <div
                                                className={'paddingBigPhone backgroundActiveColor flexCenter justifyContentSpaceBetween positionRelative'}>
                                                <div className={'saf'} style={{fontSize: '3rem'}}>INCUBATION
                                                </div>
                                                <img src={requireDefault('INCUBATION')} alt=""
                                                     style={{width: '7.5rem'}}/>
                                                <img className={'positionAbsolute'}
                                                     src={requireDefault('Fill')}
                                                     alt=""
                                                     style={{top: '1rem', left: '16.5rem', width: '1.2rem'}}
                                                />
                                            </div>
                                            <div
                                                className={'childrenSpacingTopBigPhone backgroundWhite paddingBigPhone'}>
                                                <div>
                                                    {
                                                        [
                                                            `Scientist #${tokenId} successfully incubated the`,
                                                            'cat, please download the APP to view your pet and play'
                                                        ].map(v => <div
                                                            style={{fontSize: '.9rem'}}>{v}</div>)
                                                    }
                                                </div>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    {
                                                        appDownload.map(v => <img src={requireDefault(v[0])}
                                                                                  className={'cursorPointer'}
                                                                                  onClick={v[1]} alt=""
                                                                                  style={{width: '11.7rem'}}/>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <img onClick={() => toggleShowIncubation()}
                                             className={'positionAbsolute cursorPointer'}
                                             src={requireDefault('close')} alt=""
                                             style={{width: '2rem', top: '-3rem', right: 0,}}/>
                                    </div>
                                </div>
                            }
                            {loading}
                        </div>
                        : <div className={'backgroundBlack'}>
                            <div className={'pagePaddingLeftRight boxSizingBorderBox flexCenter'}
                                 style={{
                                     'background': 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 50%, rgba(186, 255, 0, .2) 100%)',
                                     paddingTop: '1.5rem', paddingBottom: '1rem',
                                     minHeight: '100vh'
                                 }}>
                                <div className={'width100'}>
                                    <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                        <div className={'colorWhite saf cursorPointer'}
                                             onClick={isGenesis ? replaceGenesis : replaceMarketplace}>
                                            {isGenesis ? 'genesis' : 'marketplace'}
                                        </div>
                                        <div className={'colorWhite'}>{'>'}</div>
                                        <div className={'activeColor saf'}>
                                            {isGenesis ? 'MINT SBT' : metadataName}
                                        </div>
                                    </div>
                                    <div className={'childrenSpacingTopBig'}
                                         style={{marginTop: '.5rem'}}>
                                        <div
                                            className={'saf biggerFontSize colorWhite flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div className={'saf'}
                                                 style={{fontSize: '.8rem'}}>{metadataName}</div>
                                        </div>
                                        <div style={{width: '8rem'}}
                                             className={'childrenSpacingTopBig'}>
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                    <img className={'borderRadius999'}
                                                         style={{width: '1rem'}}
                                                         src={metadataImage} alt=""/>
                                                    <div className={'flexColumn childrenSpacingTop'}>
                                                        {
                                                            [
                                                                'Owner',
                                                                ownerLeaveOut
                                                            ].map(v => <div
                                                                className={'colorWhite'}>{v}</div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                    {
                                                        [
                                                            ['etherscan', isGenesis ? windowOpenEtherscan : () => windowOpen(nftContract.etherscan)],
                                                        ].map(v => <img src={requireDefault(v[0])}
                                                                        onClick={v[1]}
                                                                        className={'cursorPointer'}
                                                                        alt=""
                                                                        style={{width: '.7rem'}}/>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex childrenSpacingLeftBig'}>
                                            <div style={{width: '8rem'}} className={'childrenSpacingTopBig'}>
                                                <img src={metadataImage} alt=""
                                                     className={'width100 borderRadius'}/>
                                                {
                                                    isGenesis
                                                        ? [
                                                            <div onClick={mintSbt}
                                                                 style={{paddingLeft: '.3rem', paddingRight: '.3rem'}}
                                                                 className={'backgroundActiveColor borderRadius padding bigFontSize flexCenter justifyContentSpaceBetween cursorPointer'}
                                                            >
                                                                <div>Mint SBT</div>
                                                                <div>{mintSBTPrice} ETH</div>
                                                            </div>
                                                        ]
                                                        : [
                                                            ...oEA
                                                                ? [[false, 'SELL', salePrice]]
                                                                : ternaryArray(salePriceGT0, [[false, 'BUY', salePrice, () => toggleIsBuyIsBid(true)]]),
                                                            ...ternaryArray((NFTAddressIsBid && (!oEA || bidPriceGT0)) || (addressEQAddress(bidder) && bidPriceGT0),
                                                                [[true, [...ternaryArray(oEA, ['ACCEPT']), 'BID'].join(' '),
                                                                    bidPrice, () => toggleIsBuyIsBid(false)]]),
                                                        ].map(v => {
                                                            const price = v[2], priceGT0 = price > 0
                                                            return <div onClick={() => {
                                                                checkDataFunction(v, 3)()
                                                                toggleShowMarketplaceDialog(true)
                                                            }}
                                                                        style={{paddingLeft: '.3rem', paddingRight: '.3rem'}}
                                                                        className={`${v[0] ? 'borderActiveColor activeColor' : 'backgroundActiveColor'} borderRadius padding 
                                                                boxSizingBorderBox bigFontSize flexCenter ${priceGT0 ? 'justifyContentSpaceBetween' : ''} cursorPointer`}
                                                            >
                                                                <div>{v[1]}</div>
                                                                {priceGT0 && <div>{price} ETH</div>}
                                                            </div>
                                                        })
                                                }
                                            </div>
                                            <div
                                                className={'flexGrow1 childrenSpacingTopBig flexColumn'}>
                                                {
                                                    [
                                                        [
                                                            'TRAITS',
                                                            <div
                                                                className={'flexCenter justifyContentSpaceBetween flexWrap'}>
                                                                {
                                                                    traits.map(v => <div
                                                                        className={'flexCenter justifyContentSpaceBetween borderRadius marginTop childrenSpacingLeftSmall'}
                                                                        style={{
                                                                            width: `${(
                                                                                () => {
                                                                                    const {length} = traits
                                                                                    return length === 1 ? 100 : (length === 2 ? 42.5 : 26)
                                                                                }
                                                                            )()}%`,
                                                                            padding: '.2rem .5rem',
                                                                            border: 'solid .03rem rgba(255,255,255,.3)'
                                                                        }}
                                                                    >
                                                                        <img src={requireDefault(v[0])}
                                                                             alt=""
                                                                             style={{width: '.5rem'}}/>
                                                                        <div
                                                                            className={'colorWhite bigFontSize textOverflowEllipsis'}>{v[1]}</div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        ],
                                                        [
                                                            isGenesis ? 'Incubation income' : 'ACTIVITY',
                                                            <div
                                                                className={'flexGrow1Column flexColumn'}>
                                                                <div style={{marginTop: '.2rem'}}
                                                                     className={'flexCenter justifyContentSpaceBetween'}>
                                                                    {
                                                                        activitys.map((v, i) => <div
                                                                            className={`${i ? 'textAlignRight' : ''}`}
                                                                            style={{
                                                                                color: 'rgba(255,255,255,.3)',
                                                                                width: `${v[1]}%`
                                                                            }}
                                                                        >
                                                                            {v[0]}
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                <div
                                                                    className={'flexGrow1Column overflowYScroll'}>
                                                                    {
                                                                        (isGenesis ? incomeList : activity).map(v => <div
                                                                            style={{marginTop: '.05rem'}}
                                                                            className={'flexCenter justifyContentSpaceBetween paddingTop width100'}>
                                                                            {
                                                                                v.map((v1, i) => <div
                                                                                    className={`flex ${i ? 'justifyContentFlexEnd' : ''} colorWhite bigFontSize childrenSpacingLeftSmall`}
                                                                                    style={{width: `${activitys[i][1]}%`}}
                                                                                >
                                                                                    {!isGenesis && !i &&
                                                                                        <img src={requireDefault(v[0])}
                                                                                             alt=""
                                                                                             style={{width: '.3rem'}}/>}
                                                                                    <div
                                                                                        className={'textOverflowEllipsis'}>{v1}</div>
                                                                                </div>)
                                                                            }
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </div>,
                                                            'flexGrow1Column'
                                                        ],
                                                    ].map(v => <div
                                                            className={`borderRadius paddingBig flexColumn ${v[2]}`}
                                                            style={{border: 'solid .03rem rgba(255,255,255,.3)'}}
                                                        >
                                                            <div
                                                                className={'colorWhite saf paddingBottomBig'}
                                                                style={{borderBottom: 'solid .03rem rgba(255,255,255,.3)'}}
                                                            >{v[0]}
                                                            </div>
                                                            {v[1]}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {marketplaceDialog}
                            {
                                showIncubation && <div className={'positionFixed flexColumnCenter'}
                                                       style={{
                                                           top: 0,
                                                           left: 0,
                                                           right: 0,
                                                           bottom: 0,
                                                           zIndex: 1000,
                                                           background: 'rgba(0,0,0,.56)',
                                                       }}>
                                    <div className={'positionRelative'}>
                                        <div className={'overflowHidden'}
                                             style={{borderRadius: '.5rem'}}
                                        >
                                            <div style={{width: '8rem'}}
                                                 className={'paddingBigger backgroundActiveColor flexCenter justifyContentSpaceBetween positionRelative'}>
                                                <div className={'saf'}
                                                     style={{fontSize: '.8rem'}}>INCUBATION
                                                </div>
                                                <img src={requireDefault('INCUBATION')} alt=""
                                                     style={{width: '2rem'}}/>
                                                <img className={'positionAbsolute'}
                                                     src={requireDefault('Fill')}
                                                     alt=""
                                                     style={{top: '.5rem', left: '5.2rem', width: '.5rem'}}
                                                />
                                            </div>
                                            <div
                                                className={'childrenSpacingTopBigger backgroundWhite paddingBigger'}>
                                                <div>
                                                    {
                                                        [
                                                            `Scientist #${tokenId} successfully incubated the`,
                                                            'cat, please download the APP to view your pet and play'
                                                        ].map(v => <div>{v}</div>)
                                                    }
                                                </div>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    {
                                                        appDownload.map(v => <img src={requireDefault(v[0])}
                                                                                  className={'cursorPointer'}
                                                                                  onClick={v[1]} alt=""
                                                                                  style={{width: '3.5rem'}}/>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <img onClick={() => toggleShowIncubation()}
                                             className={'positionAbsolute cursorPointer'}
                                             src={requireDefault('close')} alt=""
                                             style={{width: '.5rem', top: '-.7rem', right: 0,}}/>
                                    </div>
                                </div>
                            }
                            {loading}
                        </div>
                })(),
                totalVolume = checkData0(data, 'totalVolume'),
                owners = checkData0(data, 'owners'),
                uniqueOwners = checkData0(data, 'uniqueOwners'),
                offerBidsSort = (offerBids = []) => {
                    let arr = []
                    return Promise.all(offerBids.map(
                        v => {
                            if (!is721A || (is721A && (isMyBids || Number(v.owner) !== 0)))
                                arr = [...arr, {
                                    ...v,
                                    offerPrice: ((axiosOfferBids && !isMyBids) || addressEQAddress(v.owner, v.seller)) ? v.offerPrice : 0n
                                }]
                        },
                    )).then(
                        () => arr.sort(
                            (a, b) => {
                                const compare = (key) => {
                                    const price = p => (p === 0 ? Infinity : p)
                                    return price(Number(a[key])) - price(Number(b[key]))
                                }, compareOfferPrice = compare('offerPrice'), compareBidPrice = compare('bidPrice')
                                return isNaN(compareOfferPrice)
                                    ? (isNaN(compareBidPrice) ? compare('tokenId') : compareBidPrice)
                                    : compareOfferPrice
                            }
                        )
                    )
                },
                marketInfoApi = 'market/info',
                marketInfoTotal = checkData([data, 'marketInfoTotal']),
                getMarketInfo = (loadMore, size = 20, reload, current = 1) => loginThenFunc(
                    () => requestApi(marketInfoApi,
                        {
                            size,
                            current,
                            ...ternaryObject(isMyNFTs, {address})
                        }
                    ).then(r => {
                            if (!isNotNull(marketInfoTotal)) {
                                setData({marketInfoTotal: r.total})
                            }
                            const offerBid = []
                            return Promise.all(checkDataArray(r, 'records').map(v =>
                                offerBid.push({
                                    ...v,
                                    tokenId: v.id,
                                })
                            )).then(() => (loadMore
                                    ? getMetaJsonList(offerBid).then(() => setData({
                                        offerBids: reload ? offerBid : [...offerBids, ...offerBid],
                                        ...ternaryObject(axiosOfferBids, {showLoading: false})
                                    }))
                                    : new Promise(resolve => resolve())
                            ).then(() => offerBid))
                        }
                    ),
                    toggleShowLoading
                ),
                getMarketInfoAxiosOfferBidsMyNFTs = (current = 1, offerBids = [], metaJsons = {}) => loginThenFunc(
                    () => {
                        const size = 100
                        return requestApi(
                            marketInfoApi,
                            {
                                size,
                                current,
                                ...ternaryObject(isMyNFTs, {address})
                            }
                        ).then(r => {
                                const offerBid = [], records = checkDataArray(r, 'records')
                                Promise.all(records.map(v =>
                                    offerBid.push({
                                        ...v,
                                        tokenId: v.id,
                                    })
                                )).then(() => ((tokens = [], metaJsons = {}) => {
                                    const metaJsonList = {};
                                    return Promise.all(tokens.map(
                                        async v => {
                                            const {tokenId} = v
                                            metaJsonList[Number(tokenId)] = await rCNFTContract('tokenURI', tokenId).then(url =>
                                                axios({url: disableCaching(url)}).then(r => checkDataObject(r, 'data'))
                                            ).catch(() => ({}))
                                        }
                                    )).then(() => ({...metaJsons, ...metaJsonList}))
                                })(offerBid, metaJsons).then(metaJsons => {
                                    offerBids = [...offerBids, ...offerBid]
                                    if (records.length < size) {
                                        setData({metaJsons, offerBids})
                                        toggleShowLoading()
                                    } else {
                                        setTimeout(() => getMarketInfoAxiosOfferBidsMyNFTs(current + 1, offerBids, metaJsons), 1000)
                                    }
                                }))
                            }
                        )
                    },
                    toggleShowLoading
                ),
                marketplaceNFTs = isPhonePage => {
                    const marketplaceNFTSpace = isPhoneClientWidth ? .5 : .2, offerBidsScroll = React.createRef(),
                        offerBidsScrollFunc = () => {
                            const node = offerBidsScroll.current
                            if (
                                offerBids.length < checkData0(marketInfoTotal) &&
                                isMarketplace && !tokenId && axiosOfferBids && (node.scrollHeight - node.scrollTop - 1 <= node.clientHeight) &&
                                !showLoading
                            ) {
                                toggleShowLoading(true)
                                getMarketInfo(true, undefined, undefined, ++offerBidPage)
                            }
                        }
                    return isPhoneClientWidth
                        ? <div style={{
                            paddingTop: `${marketplaceNFTSpace}rem`,
                            marginLeft: `${-marketplaceNFTSpace}rem`,
                            alignContent: 'flex-start'
                        }}
                               className={`flex flexWrap  ${isPhonePage ? '' : 'height100 overflowYScroll'}`}
                               ref={offerBidsScroll}
                               onScroll={offerBidsScrollFunc}
                        >
                            {
                                offerBids.map(v => {
                                        const {tokenId} = v, metadata = metadataObj(tokenId)
                                        return <div
                                            onClick={() => windowReplace(`marketplace/${tokenId}`)}
                                            style={{
                                                width: '9rem',
                                                height: '14rem',
                                                marginLeft: `${marketplaceNFTSpace}rem`,
                                                marginBottom: `${marketplaceNFTSpace}rem`
                                            }}
                                            className={'borderRadiusPhone overflowHidden boxShadow cursorPointer'}>
                                            <img className={'width100'} src={metadata.image}/>
                                            <div
                                                className={'backgroundWhite paddingSmallPhone childrenSpacingTopBigger'}>
                                                <div className={'smallerFontSizePhone'}>{metadata.name}</div>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}
                                                    style={{height: '.4rem'}}
                                                >
                                                    {(() => {
                                                        const offerPrice = numberETH(v.offerPrice)
                                                        return offerPrice > 0 && [
                                                            <img src={requireDefault('ETH')} alt=""
                                                                 style={{width: '1rem'}}/>,
                                                            <div className={'saf smallFontSizePhone lineHeight1'}>
                                                                {offerPrice}
                                                            </div>
                                                        ]
                                                    })()}
                                                </div>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                    {(() => {
                                                        const bidPrice = numberETH(v.bidPrice)
                                                        return bidPrice > 0 && [
                                                            <img src={requireDefault('ETH')} alt=""
                                                                 style={{width: '.7rem'}}/>,
                                                            <div className={'saf smallerFontSizePhone colorGray lineHeight1'}>
                                                                {bidPrice}
                                                            </div>
                                                        ]
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                )
                            }
                        </div>
                        : <div style={{
                            paddingTop: `${marketplaceNFTSpace}rem`,
                            marginLeft: `${-marketplaceNFTSpace}rem`, ...ternaryObject(!isPhonePage, {height: '15rem'}),
                            alignContent: 'flex-start'
                        }}
                               className={`flex flexWrap ${isPhonePage ? '' : 'overflowYScroll'}`}
                               ref={offerBidsScroll}
                               onScroll={offerBidsScrollFunc}
                        >
                            {
                                offerBids.map(v => {
                                        const {tokenId} = v, metadata = metadataObj(tokenId)
                                        return <div
                                            onClick={() => windowReplace(`marketplace/${tokenId}`)}
                                            style={{
                                                width: '4rem',
                                                height: '6rem',
                                                marginLeft: `${marketplaceNFTSpace}rem`,
                                                marginBottom: `${marketplaceNFTSpace}rem`
                                            }}
                                            className={'borderRadius boxShadow cursorPointer'}>
                                            <img className={'width100'}
                                                 src={metadata.image}
                                            />
                                            <div
                                                className={'backgroundWhite padding childrenSpacingTopSmall'}>
                                                <div>{metadata.name}</div>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}
                                                    style={{height: '.4rem'}}
                                                >
                                                    {(() => {
                                                        const offerPrice = numberETH(v.offerPrice)
                                                        return offerPrice > 0 && [
                                                            <img src={requireDefault('ETH')} alt=""
                                                                 style={{width: '.3rem'}}/>,
                                                            <div className={'saf bigFontSize lineHeight1'}>
                                                                {offerPrice}
                                                            </div>
                                                        ]
                                                    })()}
                                                </div>
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                    {(() => {
                                                        const bidPrice = numberETH(v.bidPrice)
                                                        return bidPrice > 0 && [
                                                            <img src={requireDefault('ETH')} alt=""
                                                                 style={{width: '.2rem'}}/>,
                                                            <div className={'saf colorGray lineHeight1'}>
                                                                {bidPrice}
                                                            </div>
                                                        ]
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                )
                            }
                        </div>
                },
                myNFTsPage = (isNFTs, title) => {
                    const offerBidsHaveData = offerBids.length > 0
                    return <div>
                        {
                            isPhoneClientWidth
                                ? pageTopFootPhone(
                                    <div className={'childrenSpacingTopPhone flexColumn boxSizingBorderBox'}
                                         style={{padding: '4rem 1rem 1rem 1rem', minHeight: '100vh'}}>
                                        <div className={'paddingBigger'}
                                             style={{
                                                 width: '10rem',
                                                 background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)'
                                             }}
                                        >
                                            <div className={'saf lineHeight1'} style={{fontSize: '.8rem'}}>
                                                {title}
                                            </div>
                                        </div>
                                        {
                                            offerBidsHaveData
                                                ? marketplaceNFTs(true)
                                                : <div
                                                    className={'paddingBiggerPhone childrenSpacingTopBigPhone flexColumnCenter flexGrow1Column'}>
                                                    <img src={requireDefault('noDataYet')} alt="" style={{width: '5rem'}}/>
                                                    <div>No data yet</div>
                                                </div>
                                        }
                                    </div>
                                )
                                : <div>
                                    {pageTop}
                                    <div className={'childrenSpacingTopBigger boxSizingBorderBox flexColumn'}
                                         style={{padding: '2rem 2rem .5rem 2rem', minHeight: '100vh'}}>
                                        <div className={'paddingBigger'}
                                             style={{
                                                 width: '10rem',
                                                 background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)'
                                             }}
                                        >
                                            <div className={'saf lineHeight1'} style={{fontSize: '.8rem'}}>
                                                {title}
                                            </div>
                                        </div>
                                        {
                                            offerBidsHaveData
                                                ? marketplaceNFTs(true)
                                                : <div
                                                    className={'paddingBigger childrenSpacingTopBig flexColumnCenter flexGrow1Column'}>
                                                    <img src={requireDefault('noDataYet')} alt="" style={{width: '2rem'}}/>
                                                    <div>No data yet</div>
                                                </div>
                                        }
                                    </div>
                                    {pageFoot}
                                </div>
                        }
                        {loading}
                    </div>
                },
                howToPlayContent = [
                    [
                        'Mood Recovery & Consumption',
                        [
                            ['Flower', 'Daily check-in can reward Crystal Energy Flower, Continuous check-in for 7 days can give u chance to draw a lottery to get random crystal energy flowers, Key or Energy Stone'],
                            ['Battery', 'Every 20 Crystal nucleus can be synthesised for a level 1 battery. Battery I can restoring 2 mood per time, and you can use up to 2 per day'],
                            ['Adventure', 'Phantom (treasure hunt): Inside the treasure hunt task, you can Consume 1 mood and gives you a chance to get a key to open a Mystery box or get an Energy Stone (restore 5 mood at once, and you can use up to 1 per day)'],
                            ['Crystal', 'Battery I,  II & Energy Stone: can use up to 2, 1, 1 times per day respectively'],
                            ['Chat', 'Each conversation with cat require you to consume at least 1 mood (1 mood can allow you to chat with cat 2 times)'],
                        ]
                    ],
                    [
                        'Poop',
                        [
                            ['Cat Feet', 'Each consumption of 1 mood allows you to get 2 cat poops'],
                            ['Synthesizer', 'Every 980 cat poops can be exchanged for 1 Mystery box'],
                            ['Key', 'Use a key to open different rarity of Mystery box and get different rarity of cat items (get your key🔑on phantom)'],
                            ['Blind Box1', 'Each Key can open one Mystery Box'],
                            ['Prop', 'Mystery boxes are divided into Furnishing & Dress up Mystery box. Furnitures can decorate the cat room, and dress up the cat can give u more XP.'],
                        ]
                    ],
                ],
                newScientistListTotal = checkData([data, 'newScientistListTotal']),
                scientistListSize = (() => {
                    const size = 20
                    return newScientistListTotal > size ? size : newScientistListTotal
                })(),
                defaultScientistList = arrayId(scientistListSize),
                scientistList = checkData(
                    [data, 'scientistList'],
                    defaultScientistList
                ),
                isWeChat = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1,
                paths = {
                    '/': (() => {
                        const showVideo = checkDataBool(data, 'showVideo'),
                            showVideoLoading = checkData([data, 'showVideoLoading'], true),
                            toggleShowVideoLoading = showVideoLoading => setData({
                                showVideoLoading
                            }),
                            toggleShowVideo = showVideo => {
                                setData({showVideo})
                                toggleShowVideoLoading(!!showVideo)
                            }
                        return <div>
                            {
                                isPhoneClientWidth
                                    ? <div>
                                        {gif}
                                        {!showGif && pageTopFootPhone(
                                            <div>
                                                {
                                                    showDownloadApp
                                                        ? <div
                                                            className={'backgroundBlack childrenSpacingTopBigPhone flexCenter positionRelative'}
                                                            style={{minHeight: '100vh'}}>
                                                            <div className={'positionRelative'} style={{paddingTop: '8rem'}}>
                                                                <div className={'flexCenter'}>
                                                                    <img style={{width: '90%', transform: 'translateY(-4rem)'}}
                                                                         src={requireDefault('wavePoint')} alt=""/>
                                                                </div>
                                                                <div
                                                                    className={'positionAbsolute flexColumnCenter justifyContentFlexEnd'}
                                                                    style={{
                                                                        top: 0,
                                                                        bottom: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        padding: '0 2rem 2rem 2rem '
                                                                    }}>
                                                                    <div className={'flexCenter'}>
                                                                        <img src={requireDefault('cat')}
                                                                             style={{width: '12rem'}}
                                                                             alt=""/>
                                                                    </div>
                                                                    <div
                                                                        style={{padding: '2rem'}}
                                                                        className={'positionRelative width100 flexColumnCenter overflowVisible ' +
                                                                            'childrenSpacingTopBigPhone borderRadius linearGreen boxSizingBorderBox'}
                                                                    >
                                                                        <div className={'saf'} style={{
                                                                            fontSize: '2rem'
                                                                        }}>download now
                                                                        </div>
                                                                        {
                                                                            appDownload.map(v => <img src={requireDefault(v[0])}
                                                                                                      onClick={v[1]} alt=""
                                                                                                      style={{width: '50%'}}/>)
                                                                        }
                                                                        <img src={requireDefault('hangingRope')}
                                                                             className={'positionAbsolute'}
                                                                             style={{
                                                                                 height: '1.5rem',
                                                                                 top: '-1.5rem',
                                                                                 right: '-1.5rem'
                                                                             }}
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img className={'positionAbsolute cursorPointer'}
                                                                 onClick={() => toggleShowVideo(true)}
                                                                 src={requireDefault('image48')}
                                                                 alt=""
                                                                 style={{width: '7rem', right: '1rem', bottom: '6rem'}}/>
                                                        </div>
                                                        : <div style={{minHeight: '100vh'}}
                                                               className={'backgroundBlack paddingLeftRightBigPhone flexCenter'}>
                                                            <div className={'childrenSpacingTopBigPhone'}>
                                                                <img src={requireDefault('title')} alt=""
                                                                     style={{width: '90%', marginTop: '6rem'}}/>
                                                                <img src={requireDefault('arrowhead')} alt=""
                                                                     style={{width: '25%'}}/>
                                                                <div className={'positionRelative'}>
                                                                    <img style={{width: '100%'}} src={requireDefault('wavePoint')}
                                                                         alt=""/>
                                                                    <div className={'positionAbsolute flexColumnCenter'}
                                                                         style={{top: 0, bottom: 0, left: 0, right: 0}}>
                                                                        <div className={'flexCenter'}>
                                                                            <img src={requireDefault('genesisCat')}
                                                                                 style={{width: '13rem'}}
                                                                                 alt=""/>
                                                                        </div>
                                                                        <div
                                                                            className={'flex justifyContentSpaceBetween childrenSpacingLeftPhone alignItemsCenter borderRadius backgroundActiveColor'}
                                                                            style={{padding: '1rem', width: '80%'}}
                                                                        >
                                                                            {
                                                                                [
                                                                                    [isStart ? (onSale ? 'ON SALE' : 'SOLD OUT') : 'Mint Date: July 26, 11:00 (UTC+8)', 'GET YOUR GENESIS NFT'],
                                                                                    ...ternaryArray(
                                                                                        isStart,
                                                                                        [[[600, 600].join('/'), ['Minted', 'Total'].join('/')]]
                                                                                    )
                                                                                ].map((v) => <div>
                                                                                    {
                                                                                        v.map((v, i) => <div
                                                                                            className={i === 0 ? 'fontWeightBolder' : ''}
                                                                                            style={{fontSize: i === 0 ? '.8rem' : '.6rem'}}
                                                                                        >{v}</div>)
                                                                                    }
                                                                                </div>)
                                                                            }
                                                                            {/*<pre*/}
                                                                            {/*    onClick={mintShowBuy}*/}
                                                                            {/*    style={{fontSize: '.5rem', padding: '.5rem'}}*/}
                                                                            {/*    className={`textAlignCenter borderRadius ${isStart ? (onSale ? 'backgroundBlack' : 'backgroundGrey') : (address ? 'backgroundBlack' : 'backgroundGrey')} activeColor cursorPointer`}*/}
                                                                            {/*>{isStart ? 'MINT' : 'Check WL'}</pre>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                <div className={'paddingBigPhone'}>
                                                    <div className={'flex justifyContentFlexEnd'}>
                                                        <img src={requireDefault('page')} style={{width: '12rem'}} alt=""/>
                                                    </div>
                                                    <div className={'positionRelative zIndex1'}>
                                                        <div className={'positionAbsolute backgroundActiveColor zIndex-1'}
                                                             style={{top: 0, left: 0, width: '10.5rem', height: '1.7rem'}}
                                                        />
                                                        <pre style={{fontSize: '2rem'}} className={'fontWeightBolder saf'}>{`PETGPT.AI
Play to Train`}</pre>
                                                    </div>
                                                    <div>
                                                        {
                                                            [
                                                                [
                                                                    ['openAI', `Based on OpenAI`],
                                                                    ['blockchain', `Blockchain to 
Ensure Ownership`],
                                                                ],
                                                                [
                                                                    ['metaverse', `True Metaverse`],
                                                                    ['game', `Transparent  
Incentive Mechanism`],
                                                                    ['social', `Enhanced Social 
Gameplay`],
                                                                ]
                                                            ].map(v => <div style={{paddingTop: '4rem'}}
                                                                            className={'flex justifyContentCenter childrenSpacingLeftBigPhone'}
                                                                >
                                                                    {
                                                                        v.map(v => <div className={'positionRelative '}
                                                                                        style={{width: '8rem', height: '5rem'}}>
                                                                                <div className={'width100 positionAbsolute zIndex-1'}
                                                                                     style={{
                                                                                         bottom: '-1rem',
                                                                                         height: '4.5rem',
                                                                                         background: '#F6F7FF'
                                                                                     }}/>
                                                                                <div
                                                                                    className={'flexColumnCenter childrenSpacingTopPhone'}>
                                                                                    <img style={{width: '3rem'}}
                                                                                         src={requireDefault(v[0])}
                                                                                         alt=""/>
                                                                                    <pre style={{fontSize: '.65rem'}}
                                                                                         className={'textAlignCenter fontWeightBolder'}>{v[1]}</pre>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'positionRelative zIndex1 flex alignItemsCenter'}
                                                     style={{
                                                         height: '50rem',
                                                         marginTop: '5rem',
                                                         overflow: 'hidden',
                                                         background: 'linear-gradient(180deg, #BAFF00 0%, #FFFFFF 100%)',
                                                     }}>
                                                    <img className={'positionAbsolute zIndex-1'} style={{width: '3rem'}}
                                                         src={requireDefault('page2')}
                                                         alt=""/>
                                                    <div className={'flex childrenSpacingLeftPhone  overflowScroll'}
                                                         style={{paddingRight: '2rem'}}
                                                    >
                                                        {
                                                            [
                                                                ['lab', 'Genesis Cat NFT', 'Swipe to choose a genesis cat NFT to hatch cat SBT'],
                                                                ['pet', 'Cat SBT', 'Train & Play with your cat SBT to earn rewards'],
                                                                ['mintSBT', 'SOCIALIZE!', 'Invite your friends to get bonuses, and show off your pet to earn rewards!'],
                                                            ].map(v => <div style={{paddingLeft: '5rem'}}
                                                                            className={'flexColumnCenter'}>
                                                                <div className={'flexColumnCenter childrenSpacingTopPhone'}
                                                                     style={{height: '6rem'}}>
                                                                    <div style={{fontSize: '1.4rem'}}
                                                                         className={'fontWeightBolder saf textAlignCenter'}>{v[1]}</div>
                                                                    <div
                                                                        className={'textAlignCenter smallerFontSizePhone'}>{v[2]}</div>
                                                                </div>
                                                                <img src={requireDefault(v[0])} style={{width: '12.5rem'}}
                                                                     alt=""/>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'positionRelative'} style={{overflow: 'hidden'}}>
                                                    <div className={'paddingBiggerPhone'}>
                                                        <div className={'positionRelative'}
                                                             style={{marginTop: '3rem'}}>
                                                            <div className={'positionAbsolute backgroundActiveColor zIndex-1'}
                                                                 style={{
                                                                     top: '1.5rem',
                                                                     left: '8.5rem',
                                                                     width: '3.7rem',
                                                                     height: '1.8rem'
                                                                 }}
                                                            />
                                                            <div className={'fontWeightBolder saf'}
                                                                 style={{fontSize: '1.7rem'}}>
                                                                The First Web3 Product to Empower SBT
                                                            </div>
                                                        </div>
                                                        <div className={'flexColumnCenter'}>
                                                            {
                                                                [
                                                                    ['sbt', 'Using SBT prevents users from over-hyping the pets that players need to use the App, allowing the enrollment requirement to remain stable.'],
                                                                    ['peiban', 'The core function of pets is companionship. In real life, we consider our pets as lifelong companions and that we never abandon them. This principle holds true... in the virtual world as well.']
                                                                ].map(v => <div
                                                                    className={'flexColumnCenter childrenSpacingTopBigPhone'}
                                                                    style={{width: '17rem', marginTop: '3rem'}}>
                                                                    <img src={requireDefault(v[0])} style={{width: '15rem'}}
                                                                         alt=""/>
                                                                    <div
                                                                        className={'textAlignCenter fontWeightBolder'}
                                                                        style={{fontSize: '.5rem', lineHeight: '1rem'}}>
                                                                        {v[1]}
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={'paddingBiggerPhone backgroundBlack positionRelative zIndex1'}>
                                                        <div className={'paddingTopBiggerPhone childrenSpacingTopPhone'}>
                                                            <div className={'flex colorWhite childrenSpacingLeftPhone'}>
                                                                <div style={{fontSize: '2rem'}}
                                                                     className={'activeColor saf'}>OPENAI
                                                                </div>
                                                                <div style={{fontSize: '2rem'}}
                                                                     className={'colorWhite saf'}> Helps
                                                                    to
                                                                    Build
                                                                    a
                                                                </div>
                                                            </div>
                                                            <div className={'colorWhite saf'}
                                                                 style={{fontSize: '2rem'}}>Personalized
                                                                Pet
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'width100 flexColumn alignItemsFlexEnd childrenSpacingTopBigPhone'}
                                                            style={{margin: '5rem 0 16rem 0'}}
                                                        >
                                                            {
                                                                [
                                                                    ['picture', 'NFT Generation', 'Generate or edit your own NFT on our platform'],
                                                                    ['social', 'Chat', "Chat with your pet and collect data to train the digital pets' AI model"],
                                                                    ['yuyin', 'Customization', 'Customize game NPC/Story/Scenario with the help of OpenAI and PETGPT'],
                                                                ].map(v => <div
                                                                    className={'width100 borderRadius999 boxSizingBorderBox flex alignItemsCenter childrenSpacingLeftBigPhone'}
                                                                    style={{
                                                                        padding: '.7rem 1.7rem',
                                                                        border: '.03rem solid #BAFF00',
                                                                    }}>
                                                                    <img src={requireDefault(v[0])} alt=""
                                                                         style={{height: '3.5rem'}}/>
                                                                    <div className={'childrenSpacingTop flexGrow1 colorWhite'}>
                                                                        <div
                                                                            className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                            <div className={''}>{v[1]}</div>
                                                                        </div>
                                                                        <div className={'smallerFontSizePhone'}>{v[2]}</div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                        <div className={'positionAbsolute width100 zIndex-1'}
                                                             style={{top: '7rem', bottom: 0, left: '15rem', right: 0}}
                                                        >
                                                            <img src={requireDefault('halfWavePointPhone')} alt=""
                                                                 style={{width: '30rem'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{background: '#F6F7FF', paddingBottom: '7rem'}}>
                                                    <div className={'paddingBiggerPhone'}>
                                                        <div className={'paddingTopBiggerPhone fontWeightBolder saf'}
                                                             style={{fontSize: '2rem'}}
                                                        >
                                                            ROADMAP
                                                        </div>
                                                    </div>
                                                    <div className={'positionRelative'}>
                                                        <img src={requireDefault('roadmapPhone')} className={'width100'}
                                                             alt=""/>
                                                        {
                                                            [
                                                                ['2023.Q1', `Official website, Whitepaper

Genesis Cat NFT and Cat SBT creation

App version 1.0 is developed`, -14, 0, 5],
                                                                ['Phase.1', `Genesis Cat NFT Mint
Pet SBT Mint
App Launch&Integration with OpenAI`, -29, 0, 15],
                                                                ['Phase.2', ` Casual Game Launch
Pet Dress-Up System
Pet Fashion Show
Native Marketplace Launch on App
`, 8, 0, 19],
                                                                ['Phase.3', `3D Pet
Deployment on Other L2s
Special Skills for Genesis Cat
 (Inherited to Pet SBT)
`, 8, 0, 4],
                                                                ['Phase.4', `Launch Our Own Small Language 
Model
DIY Gameplay
Pet SBT lives on Metaverse
`, 35.5, 0, 8],
                                                            ].map(v => <div
                                                                className={'positionAbsolute flexColumnCenter childrenSpacingTop'}
                                                                style={{
                                                                    top: `${v[2]}rem`,
                                                                    bottom: `${v[3]}rem`,
                                                                    left: `${v[4]}rem`,
                                                                }}
                                                            >
                                                                <div className={'fontWeightBolder saf'}>
                                                                    {v[0]}
                                                                </div>
                                                                <pre className={'textAlignCenter fontWeightBolder'}
                                                                     style={{
                                                                         fontSize: '.5rem',
                                                                         lineHeight: '.9rem'
                                                                     }}>{v[1]}</pre>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'paddingBiggerPhone'}>
                                                    <div className={'paddingTopBiggerPhone fontWeightBolder saf'}
                                                         style={{fontSize: '2rem'}}
                                                    >
                                                        Investors & Partners
                                                    </div>
                                                    <div style={{paddingTop: '1.5rem'}}
                                                         className={'width100 flex justifyContentSpaceBetween flexWrap paddingBottomBigPhone'}>
                                                        {
                                                            partners.map(v => <img src={requireDefault(v[0])}
                                                                                   style={{
                                                                                       width: '11.5rem',
                                                                                       paddingBottom: '1.5rem'
                                                                                   }}
                                                                                   alt=""/>)
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={'paddingBiggerPhone linearGreen positionRelative zIndex1 overflowHidden'}>
                                                    <div className={'paddingTopBiggerPhone fontWeightBolder saf'}
                                                         style={{fontSize: '2rem'}}
                                                    >
                                                        FEATURED ON
                                                    </div>
                                                    <div style={{paddingTop: '1.5rem'}}
                                                         className={'width100 flex justifyContentSpaceBetween flexWrap paddingBottomBigPhone'}>
                                                        {
                                                            [
                                                                ['coinspeaker', 'https://www.coinspeaker.com/petgpt-launches-closed-beta-on-arbitrum-testnet/'],
                                                                ['yahoo', 'https://finance.yahoo.com/news/petgpt-launches-closed-beta-arbitrum-000000982.html'],
                                                                ['Bloomberg', 'https://www.bloomberg.com/press-releases/2023-06-16/petgpt-launches-closed-beta-on-arbitrum-testnet'],
                                                                ['AP', 'https://apnews.com/press-release/globe-newswire/cats-globe-newswire-a2ea2ebe3a1094ba5000e9ea69b80280'],
                                                            ].map(v => <img onClick={() => windowOpen(v[1])}
                                                                            src={requireDefault(v[0])}
                                                                            style={{width: '11.7rem', paddingBottom: '1rem'}}
                                                                            alt=""/>)
                                                        }
                                                    </div>
                                                    <div style={{left: 0, right: 0, top: '14rem'}}
                                                         className={'positionAbsolute flexCenter zIndex-1'}>
                                                        <img src={requireDefault('wavePoint3')}
                                                             alt=""
                                                             style={{width: '25rem'}}
                                                        />
                                                    </div>
                                                </div>
                                                {buyDialog}
                                            </div>
                                        )}
                                        {loading}
                                    </div>
                                    : <div>
                                        {gif}
                                        {!showGif &&
                                            <div>
                                                {pageTop}
                                                {
                                                    fullPage < maxFullPage &&
                                                    <div onClick={() => setData({fullPage: maxFullPage})}
                                                         className={'positionFixed flexCenter zIndex999 borderRadius999 cursorPointer'}
                                                         style={{
                                                             width: '1rem',
                                                             height: '1rem',
                                                             right: '.5rem',
                                                             bottom: '.5rem',
                                                             background: 'rgba(0,0,0,.5)'
                                                         }}
                                                    >
                                                        <img src={requireDefault('toBottom')} alt="" style={{width: '50%'}}/>
                                                    </div>
                                                }
                                                {(() => {
                                                    const fullPageScroll = isDown => {
                                                        if (!scrolling && (isDown ? fullPage < maxFullPage : fullPage > 0)) {
                                                            const setScrolling = (s = false) => setData({
                                                                scrolling: s
                                                            })
                                                            setScrolling(true)
                                                            setData({
                                                                fullPage: fullPage + (isDown ? 1 : -1)
                                                            })
                                                            setTimeout(() => setScrolling(), 1000)
                                                        }
                                                    }
                                                    return <div style={{height: '100vh', overflow: 'hidden'}}
                                                                onTouchStart={e => setData({startY: e.touches[0].clientY})}
                                                                onTouchMove={e => setData({endY: e.touches[0].clientY})}
                                                                onTouchEnd={() => fullPageScroll(checkData0(data, 'endY') < checkData0(data, 'startY'))}
                                                                onWheel={() => fullPageScroll(window.event.wheelDelta < 0)}
                                                    >
                                                        <div className={'height100'}
                                                             style={{
                                                                 transition: 'all 1000ms ease',
                                                                 transform: `translate3d(0,-${fullPage * 100}vh, 0)`,
                                                             }}>
                                                            {
                                                                showDownloadApp
                                                                    ? <div className={'sectionChild positionRelative'}
                                                                           style={{'background': 'black'}}>
                                                                        <div className={'flexCenter width100 height100'}>
                                                                            <div className={'positionRelative'}>
                                                                                <img style={{width: '17rem'}}
                                                                                     src={requireDefault('wavePoint')}
                                                                                     alt=""/>
                                                                                <div
                                                                                    className={'positionAbsolute flexColumn justifyContentCenter'}
                                                                                    style={{top: 0, bottom: 0, left: 0, right: 0}}>
                                                                                    <div className={'flexCenter'}>
                                                                                        <img src={requireDefault('cat')}
                                                                                             style={{width: '6rem'}}
                                                                                             alt=""/>
                                                                                    </div>
                                                                                    <div
                                                                                        className={'positionRelative flexColumnCenter borderRadius childrenSpacingTopBig overflowVisible'}
                                                                                        style={{
                                                                                            padding: '.7rem .6rem',
                                                                                            background: 'linear-gradient(180deg, #BAFF00 0%, #FFFFFF 100%)'
                                                                                        }}
                                                                                    >
                                                                                        <div className={'saf'}
                                                                                             style={{fontSize: '1rem'}}>
                                                                                            download now
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flexCenter childrenSpacingLeftBigger'}>
                                                                                            {
                                                                                                [
                                                                                                    ...appDownload,
                                                                                                    ['qrCode'],
                                                                                                ].map(v => {
                                                                                                        const [icon, func] = v,
                                                                                                            {showQrCode = false} = data,
                                                                                                            funcIsNull = !isNotNull(func),
                                                                                                            toggleShowQrCode = (s = false) => funcIsNull && setData({showQrCode: s})
                                                                                                        return <div
                                                                                                            className={'positionRelative'}>
                                                                                                            <img onClick={func}
                                                                                                                 style={{height: '1rem'}}
                                                                                                                 className={'cursorPointer'}
                                                                                                                 src={requireDefault(icon)}
                                                                                                                 alt=""
                                                                                                                 onMouseEnter={() => toggleShowQrCode(true)}
                                                                                                                 onMouseLeave={() => toggleShowQrCode()}
                                                                                                            />
                                                                                                            {funcIsNull && showQrCode &&
                                                                                                                <div
                                                                                                                    className={'positionAbsolute flexColumnCenter'}
                                                                                                                    style={{
                                                                                                                        left: 0,
                                                                                                                        right: 0
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div style={{
                                                                                                                        width: 0,
                                                                                                                        height: 0,
                                                                                                                        border: '.2rem solid transparent',
                                                                                                                        borderBottomColor: '#BAFF00',
                                                                                                                    }}/>
                                                                                                                    <div
                                                                                                                        onClick={() => resetApp()}
                                                                                                                        className={'borderRadius backgroundActiveColor flexCenter childrenSpacingLeft cursorPointer'}
                                                                                                                        style={{
                                                                                                                            width: '3rem',
                                                                                                                            height: '3rem'
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <img alt=""
                                                                                                                             style={{height: '90%'}}
                                                                                                                             src={requireDefault('petGptQrCode')}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <img src={requireDefault('hangingRope')}
                                                                                             className={'positionAbsolute'}
                                                                                             style={{
                                                                                                 height: '1rem',
                                                                                                 top: '-.5rem',
                                                                                                 right: '-1rem'
                                                                                             }}
                                                                                             alt=""/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <img className={'positionAbsolute cursorPointer'}
                                                                             onClick={() => toggleShowVideo(true)}
                                                                             src={requireDefault('image48')}
                                                                             alt=""
                                                                             style={{
                                                                                 width: '4rem', right: '.5rem', bottom: '2rem'
                                                                             }}/>
                                                                    </div>
                                                                    : <div className={'sectionChild'}
                                                                           style={{'background': 'black'}}>
                                                                        <div
                                                                            className={'flex justifyContentSpaceBetween alignItemsFlexEnd'}>
                                                                            <img src={requireDefault('title')}
                                                                                 style={{width: '12rem'}}
                                                                                 alt=""/>
                                                                            <div className={'positionRelative'}>
                                                                                <img style={{width: '12rem'}}
                                                                                     src={requireDefault('wavePoint')}
                                                                                     alt=""/>
                                                                                <div
                                                                                    className={'positionAbsolute flexColumn justifyContentFlexEnd'}
                                                                                    style={{
                                                                                        top: 0,
                                                                                        bottom: 0,
                                                                                        left: 0,
                                                                                        right: 0
                                                                                    }}>
                                                                                    <div className={'flexCenter'}>
                                                                                        <img src={requireDefault('genesisCat')}
                                                                                             style={{width: '9rem'}}
                                                                                             alt=""/>
                                                                                    </div>
                                                                                    <div
                                                                                        className={'flex justifyContentSpaceBetween alignItemsCenter borderRadius backgroundActiveColor'}
                                                                                        style={{padding: '.4rem .6rem'}}
                                                                                    >
                                                                                        {
                                                                                            [
                                                                                                [isStart ? (onSale ? 'ON SALE' : 'SOLD OUT') : 'Mint Date: July 26, 11:00 (UTC+8)', 'GET YOUR GENESIS NFT'],
                                                                                                ...ternaryArray(
                                                                                                    isStart,
                                                                                                    [[[600, 600].join('/'), ['Minted', 'Total'].join('/')]]
                                                                                                )
                                                                                            ].map((v) => <div>
                                                                                                {
                                                                                                    v.map((v, i) => <div
                                                                                                        className={i === 0 ? 'bigFontSize fontWeightBolder' : ''}>{v}</div>)
                                                                                                }
                                                                                            </div>)
                                                                                        }
                                                                                        {/*<pre*/}
                                                                                        {/*    onClick={mintShowBuy}*/}
                                                                                        {/*    className={`textAlignCenter borderRadius ${isStart ? (onSale ? 'backgroundBlack' : 'backgroundGrey') : (address ? 'backgroundBlack' : 'backgroundGrey')} paddingBig activeColor cursorPointer`}*/}
                                                                                        {/*>{isStart ? 'MINT' : 'Check WL'}</pre>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            }
                                                            <div className={'sectionChild'} style={{'background': 'white'}}>
                                                                <div className={'childrenSpacingTop width100'}>
                                                                    <div className={'positionRelative zIndex1'}>
                                                                        <div
                                                                            className={'positionAbsolute backgroundActiveColor zIndex-1'}
                                                                            style={{
                                                                                top: 0,
                                                                                left: 0,
                                                                                width: '4.8rem',
                                                                                height: '1.4rem'
                                                                            }}
                                                                        />
                                                                        <pre style={{fontSize: '1rem', lineHeight: '1.5rem'}}
                                                                             className={'fontWeightBolder saf'}>{`PETGPT.AI
Play to Train`}</pre>
                                                                    </div>
                                                                    <div className={'flex justifyContentSpaceBetween'}>
                                                                        <img src={requireDefault('page')}
                                                                             style={{width: '8rem', paddingTop: '1rem'}}
                                                                             alt=""/>
                                                                        <div>
                                                                            {
                                                                                [
                                                                                    [
                                                                                        null,
                                                                                        ['openAI', `Based on OpenAI
                                            `],
                                                                                        ['blockchain', `Blockchain to 
Ensure Ownership
`],
                                                                                    ],
                                                                                    [
                                                                                        ['metaverse', `True Metaverse
`],
                                                                                        ['game', `Transparent  
Incentive Mechanism`],
                                                                                        ['social', `Enhanced Social 
Gameplay`],
                                                                                    ]
                                                                                ].map((v, i) => <div
                                                                                        className={'flex justifyContentSpaceBetween'}
                                                                                        style={{
                                                                                            width: '15rem',
                                                                                            paddingTop: i > 0 ? '1.5rem' : '0'
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            v.map(v => <div
                                                                                                    className={'positionRelative zIndex1'}
                                                                                                    style={{width: '4.5rem'}}>
                                                                                                    {v && <div
                                                                                                        className={'width100 positionAbsolute zIndex-1'}
                                                                                                        style={{
                                                                                                            bottom: '-.3rem',
                                                                                                            height: '2.3rem',
                                                                                                            background: '#F6F7FF'
                                                                                                        }}/>}
                                                                                                    {v && <div
                                                                                                        className={'flexColumnCenter childrenSpacingTopBig'}
                                                                                                    >
                                                                                                        <img style={{width: '1.5rem'}}
                                                                                                             src={requireDefault(v[0])}
                                                                                                             alt=""/>
                                                                                                        <pre
                                                                                                            className={'textAlignCenter bigFontSize fontWeightBolder'}>{v[1]}</pre>
                                                                                                    </div>}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'sectionChild'}
                                                                 style={{background: 'linear-gradient(180deg, #BAFF00 0%, #FFFFFF 100%)'}}>
                                                                <div>
                                                                    <img className={'width100'} style={{marginBottom: '-.7rem'}}
                                                                         src={requireDefault('page2Text')}
                                                                         alt=""/>
                                                                    <div className={'flex justifyContentSpaceAround'}>
                                                                        {
                                                                            [
                                                                                ['lab', 'Genesis Cat NFT', 'Swipe to choose a genesis cat NFT to hatch cat SBT'],
                                                                                ['pet', 'CAT SBT', 'Train & Play with your cat SBT to earn rewards'],
                                                                                ['mintSBT', 'SOCIALIZE!', 'Invite your friends to get bonuses, and show off your pet to earn rewards!'],
                                                                            ].map(v => <div style={{width: '5.5rem'}}
                                                                                            className={'flexColumn alignItemsCenter childrenSpacingTopBig'}>
                                                                                <img src={requireDefault(v[0])}
                                                                                     style={{width: '70%'}}
                                                                                     alt=""/>
                                                                                <div style={{fontSize: '.6rem'}}
                                                                                     className={'saf'}>{v[1]}</div>
                                                                                <div className={'textAlignCenter'}
                                                                                     style={{lineHeight: '.4rem'}}>{v[2]}</div>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{height: '200%'}} className={'positionRelative'}>
                                                                <div className={'sectionChild'} style={{height: '50%'}}>
                                                                    <div className={'width100'}>
                                                                        <div className={'positionRelative'}>
                                                                            <div
                                                                                className={'positionAbsolute backgroundActiveColor zIndex-1'}
                                                                                style={{
                                                                                    top: '.8rem',
                                                                                    left: '14.7rem',
                                                                                    width: '2rem',
                                                                                    height: '1.2rem'
                                                                                }}
                                                                            />
                                                                            <pre
                                                                                className={'width100 textAlignCenter fontWeightBolder saf'}
                                                                                style={{fontSize: '1rem'}}>
                                                {`The First Web3 Product 
to Empower SBT`}
                                            </pre>
                                                                        </div>
                                                                        <div className={'flex justifyContentSpaceAround'}
                                                                             style={{alignItems: 'flex-start',}}>
                                                                            <div
                                                                                className={'flexColumnCenter childrenSpacingTopBigger'}
                                                                                style={{width: '9rem'}}>
                                                                                {/*peiban.png*/}
                                                                                {/*sbt.png*/}
                                                                                <img src={requireDefault('sbt')}
                                                                                     style={{width: '90%'}}
                                                                                     alt=""/>
                                                                                <div style={{lineHeight: '.5rem'}}
                                                                                     className={'textAlignCenter'}>Using SBT
                                                                                    prevents
                                                                                    users
                                                                                    from
                                                                                    over-hyping
                                                                                    the pets that players need to use the App,
                                                                                    allowing
                                                                                    the
                                                                                    enrollment
                                                                                    requirement to remain stable.
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    width: '.01rem',
                                                                                    height: '9rem',
                                                                                    background: '#DDDDDD'
                                                                                }}></div>
                                                                            <div
                                                                                className={'flexColumnCenter childrenSpacingTopBigger'}
                                                                                style={{width: '9rem'}}>
                                                                                <img src={requireDefault('peiban')}
                                                                                     style={{width: '90%'}}
                                                                                     alt=""/>
                                                                                <div style={{lineHeight: '.5rem'}}
                                                                                     className={'textAlignCenter'}>
                                                                                    The core function of pets is companionship.
                                                                                    In
                                                                                    real
                                                                                    life, we
                                                                                    consider our pets as lifelong companions and
                                                                                    that we
                                                                                    never
                                                                                    abandon them. This principle holds true...
                                                                                    in
                                                                                    the
                                                                                    virtual
                                                                                    world as well.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={'sectionChild backgroundBlack positionRelative zIndex1'}
                                                                    style={{height: '50%'}}>
                                                                    <div className={'width100 childrenSpacingTopBig'}>
                                                                        <div
                                                                            className={'width100 flex justifyContentSpaceBetween alignItemsCenter'}>
                                                                            <div className={'childrenSpacingTopBigger'}>
                                                                                <div
                                                                                    className={'flex colorWhite biggerFontSize childrenSpacingLeftBig'}>
                                                                                    <div style={{fontSize: '1rem'}}
                                                                                         className={'activeColor saf'}>OPENAI
                                                                                    </div>
                                                                                    <div style={{fontSize: '1rem'}}
                                                                                         className={'colorWhite saf'}> Helps to
                                                                                        Build a
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'colorWhite saf'}
                                                                                     style={{fontSize: '1rem'}}>Personalized
                                                                                    Pet
                                                                                </div>
                                                                            </div>
                                                                            <img src={requireDefault('openAI1')}
                                                                                 style={{height: '3rem'}}
                                                                                 alt=""/>
                                                                        </div>
                                                                        <div
                                                                            className={'width100 flexColumn alignItemsFlexEnd childrenSpacingTopBig'}>
                                                                            {
                                                                                [
                                                                                    ['picture', 'NFT Generation', 'Generate or edit your own NFT on our platform'],
                                                                                    ['social', 'Chat', "Chat with your pet and collect data to train the digital pets' AI model"],
                                                                                    ['yuyin', 'Customization', 'Customize game NPC/Story/Scenario with the help of OpenAI and PETGPT'],
                                                                                ].map((v, i) => <div
                                                                                    className={'borderRadius999 paddingBigger flex alignItemsCenter childrenSpacingLeftBig'}
                                                                                    style={{
                                                                                        border: '.03rem solid #BAFF00',
                                                                                        width: '11rem',
                                                                                        marginRight: i % 2 !== 0 ? '5rem' : '0'
                                                                                    }}>
                                                                                    <img src={requireDefault(v[0])} alt=""
                                                                                         style={{height: '1.5rem'}}/>
                                                                                    <div
                                                                                        className={'childrenSpacingTop flexGrow1 colorWhite'}>
                                                                                        <div
                                                                                            className={'flex alignItemsCenter childrenSpacingLeftBigger'}>
                                                                                            <div
                                                                                                className={'biggerFontSize'}>{v[1]}</div>
                                                                                        </div>
                                                                                        <div
                                                                                            className={'bigFontSize'}>{v[2]}</div>
                                                                                    </div>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div style={{top: '5rem', bottom: '0', left: '-7.4rem'}}
                                                                         className={'positionAbsolute flex alignItemsCenter zIndex-1'}>
                                                                        <img src={requireDefault('halfWavePoint')} alt=""
                                                                             style={{width: '20rem'}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'sectionChild'}
                                                                 style={{padding: 0, background: '#F6F7FF'}}>
                                                                <div className={'positionRelative'}>
                                                                    <img src={requireDefault('roadmap')} className={'width100'}
                                                                         alt=""/>
                                                                    <div className={'positionAbsolute'}
                                                                         style={{top: 0, bottom: 0, left: 0, right: 0}}
                                                                    >
                                                                        <div
                                                                            className={'textAlignRight pagePaddingLeftRight fontWeightBolder saf'}
                                                                            style={{fontSize: '1rem', marginTop: '2.5rem'}}>
                                                                            ROADMAP
                                                                        </div>
                                                                        {
                                                                            [
                                                                                ['2023.Q1', `Official website, Whitepaper

Genesis Cat NFT and Cat SBT creation

App version 1.0 is developed`, 8.5, 0, 2],
                                                                                ['Phase.1', `Genesis Cat NFT Mint

Pet SBT Mint

App Launch&Integration with OpenAI

`, -2.5, 0, 7.5],
                                                                                ['Phase.2', ` Casual Game Launch

Pet Dress-Up System

Pet Fashion Show

Native Marketplace Launch on App
`, 9.5, 0, 10],
                                                                                ['Phase.3', `3D Pet

Deployment on Other L2s

Special Skills for Genesis Cat
 (Inherited to Pet SBT)

`, -5.7, 0, 17.5, true],
                                                                                ['Phase.4', `Launch Our Own Small Language Model

DIY Gameplay

Pet SBT lives on Metaverse
`, 4, 0, 22],
                                                                            ].map(v => <div
                                                                                className={'positionAbsolute flexColumnCenter childrenSpacingTop'}
                                                                                style={{
                                                                                    top: `${v[2]}rem`,
                                                                                    bottom: `${v[3]}rem`,
                                                                                    left: `${v[4]}rem`,
                                                                                    flexDirection: v[5] ? 'column-reverse' : ''
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={'fontWeightBolder biggerFontSize saf'}>
                                                                                    {v[0]}
                                                                                </div>
                                                                                <pre className={'textAlignCenter'}
                                                                                     style={{lineHeight: '.5rem'}}>{v[1]}</pre>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'sectionChild flexColumn'}
                                                                 style={{padding: 0}}
                                                            >
                                                                <div
                                                                    className={'flexGrow1Column pagePaddingLeftRight flexColumn justifyContentCenter'}>
                                                                    <div className={'textAlignCenter saf'}
                                                                         style={{fontSize: '1rem'}}>
                                                                        Investors & Partners
                                                                    </div>
                                                                    <div style={{paddingTop: '1.5rem'}}
                                                                         className={'width100 flex flexWrap justifyContentSpaceBetween'}>
                                                                        {
                                                                            partners.map(v => <img
                                                                                src={requireDefault(v[0])}
                                                                                style={{
                                                                                    width: '5.8rem',
                                                                                    paddingBottom: '.7rem'
                                                                                }}
                                                                                alt=""/>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'sectionChild flexColumn linearGreen positionRelative zIndex1'}
                                                                style={{padding: 0}}
                                                            >
                                                                <div
                                                                    className={'flexGrow1Column pagePaddingLeftRight flexColumn justifyContentCenter'}>
                                                                    <div className={'saf'} style={{fontSize: '1rem'}}>
                                                                        FEATURED ON
                                                                    </div>
                                                                    <div style={{paddingTop: '1.5rem'}}
                                                                         className={'width100 flex flexWrap justifyContentSpaceBetween'}>
                                                                        {
                                                                            [
                                                                                ['coinspeaker', 'https://www.coinspeaker.com/petgpt-launches-closed-beta-on-arbitrum-testnet/'],
                                                                                ['yahoo', 'https://finance.yahoo.com/news/petgpt-launches-closed-beta-arbitrum-000000982.html'],
                                                                                ['Bloomberg', 'https://www.bloomberg.com/press-releases/2023-06-16/petgpt-launches-closed-beta-on-arbitrum-testnet'],
                                                                                ['AP', 'https://apnews.com/press-release/globe-newswire/cats-globe-newswire-a2ea2ebe3a1094ba5000e9ea69b80280'],
                                                                            ].map(v => <img onClick={() => windowOpen(v[1])}
                                                                                            className={'cursorPointer paddingBottomBig'}
                                                                                            src={requireDefault(v[0])}
                                                                                            style={{width: '6rem'}}
                                                                                            alt=""/>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <img src={requireDefault('wavePoint3')}
                                                                     className={'positionAbsolute zIndex-1'}
                                                                     alt=""
                                                                     style={{width: '15rem', right: '-3rem', bottom: '-9rem'}}
                                                                />
                                                                {pageFoot}
                                                            </div>
                                                        </div>
                                                    </div>
                                                })()}
                                                {buyDialog}
                                            </div>
                                        }
                                        {loading}
                                    </div>
                            }
                            {showVideo && <div className={'positionFixed zIndex999 flexCenter'} style={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0,0,0,.9)'
                            }}>
                                <div className={'childrenSpacingTop'}>
                                    <div className={'flex justifyContentFlexEnd'}>
                                        <img src={requireDefault('close')} className={'cursorPointer'}
                                             onClick={() => toggleShowVideo()} alt=""
                                             style={{width: isPhoneClientWidth ? '1.5rem' : '1rem'}}/>
                                    </div>
                                    <div className={'positionRelative flexCenter'}
                                         style={isPhoneClientWidth
                                             ? {width: '24rem', height: '13.2rem'}
                                             : {
                                                 width: '20rem',
                                                 height: '11rem'
                                             }}
                                    >
                                        <div style={{width: '97.7%', borderRadius: '.84rem', overflow: 'hidden'}}
                                             className={'height100'}>
                                            <video src={requireDefault('1086_raw', 'mp4')}
                                                   loop
                                                   autoPlay
                                                   playsInline
                                                   x5VideoPlayerType={'h5'}
                                                   className={'width100 height100'}
                                                   style={{x5VideoPlayerType: 'h5'}}
                                                   poster={requireDefault('videoPoster')}
                                                   onLoadedData={() => toggleShowVideoLoading(false)}
                                            />
                                        </div>
                                        <img src={requireDefault('VectorVideo')} alt=""
                                             className={'width100 positionAbsolute'} style={{top: 0}}/>
                                        {showVideoLoading &&
                                            <div className={'flexCenter positionAbsolute'}
                                                 style={{
                                                     top: 0,
                                                     bottom: 0,
                                                     left: 0,
                                                     right: 0
                                                 }}
                                            >
                                                <img className={'positionAbsolute'}
                                                     src={requireDefault('connectLoading', 'gif')}
                                                     alt=""
                                                     style={{width: `${isPhoneClientWidth ? 5 : 2}rem`}}
                                                />
                                            </div>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    })(),
                    howToPlay: isPhoneClientWidth
                        ? pageTopFootPhone(
                            <div>
                                <div className={'backgroundBlack overflowHidden positionRelative'}
                                     style={{padding: '5rem 1rem', height: '40rem'}}
                                >
                                    <div className={'height100 flexColumn justifyContentSpaceBetween positionRelative'}>
                                        <div
                                            className={'childrenSpacingTopPhone borderRadius overflowVisible'}>
                                            <img style={{width: '8rem'}}
                                                 src={requireDefault('genesisCatNFT')} alt=""/>
                                            <div className={'childrenSpacingTopPhone'}>
                                                <div className={'saf activeColor'} style={{fontSize: '1.3rem'}}>
                                                    Genesis Cat NFT
                                                </div>
                                                <pre className={'smallerFontSizePhone colorWhite'}
                                                     style={{lineHeight: 1.5}}>{`Genesis Cat, a.k.a. The Cat Scientist
It is an NFT asset for collectors 
that can be freely traded on the 
market. It allows users to hatch pet cats.`}</pre>
                                            </div>
                                        </div>
                                        <div style={{top: '13rem', right: 0}}
                                             className={'flexColumn alignItemsFlexEnd childrenSpacingTopPhone borderRadius overflowVisible positionAbsolute'}>
                                            <img style={{width: '8rem'}}
                                                 src={requireDefault('littleCat')} alt=""/>
                                            <div className={'childrenSpacingTopPhone'}>
                                                <div className={'saf activeColor textAlignRight'}
                                                     style={{fontSize: '1.3rem'}}>
                                                    CAT SBT
                                                </div>
                                                <pre className={'smallerFontSizePhone colorWhite textAlignRight'}
                                                     style={{lineHeight: 1.5}}>{`Pet cats are the SBT assets of players, 
generated from the Genesis Cats' hatching, 
inheriting their skin and eye color. They are 
a unique identity linked to OpenAI, and 
shall accompany players in their growth.`}</pre>
                                            </div>
                                        </div>
                                        <div
                                            className={'flex childrenSpacingLeftBigPhone borderRadius'}>
                                            <img style={{height: '6rem'}}
                                                 src={requireDefault('hi')} alt=""/>
                                            <div className={'childrenSpacingTopPhone'}>
                                                <div className={'saf activeColor'} style={{fontSize: '1.3rem'}}>Interaction
                                                    &
                                                    Chat
                                                </div>
                                                <pre className={'smallerFontSizePhone colorWhite'}
                                                     style={{lineHeight: 1.5}}>{`Cat interaction includes all 
personalized companion scenes such 
as chatting, dressing up, and 
showrooms.`}</pre>
                                            </div>
                                        </div>
                                        {
                                            [
                                                ['topArrow', 20, 5],
                                                ['bottomArrow', 5, 10],
                                            ].map(v => <img src={requireDefault(v[0])} alt="" className={'positionAbsolute'}
                                                            style={
                                                                {
                                                                    top: `${v[1]}rem`,
                                                                    left: `${v[2]}rem`,
                                                                    width: '10rem',
                                                                }
                                                            }
                                            />)
                                        }
                                    </div>
                                    <img src={requireDefault('wavePoint2')} className={'positionAbsolute'}
                                         style={{
                                             top: '3rem',
                                             right: '-8rem',
                                             width: '15rem',
                                         }}
                                         alt=""/>
                                    <img src={requireDefault('wavePoint1')} className={'positionAbsolute'}
                                         style={{
                                             left: 0,
                                             bottom: 0,
                                             width: '7rem',
                                         }}
                                         alt=""/>
                                </div>
                                <div style={{padding: '5rem 2rem'}}
                                     className={'childrenSpacingTopBigPhone positionRelative'}>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>Set up a wallet</div>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>to log in to the app
                                    </div>
                                    <div className={'flex childrenSpacingLeftPhone'}>
                                        <img src={requireDefault('setUpWalletPhone')} alt=""
                                             style={{width: '12rem'}}
                                        />
                                        <div className={'flexColumn paddingTopBiggerPhone'}>
                                            <div style={{marginTop: '1rem'}}>
                                                <img src={requireDefault('particleLogin')} alt=""
                                                     style={{width: '9rem'}}
                                                />
                                                <pre style={{fontSize: '.6rem'}}>{`You can choose to log in with a 
media account`}</pre>
                                            </div>
                                            <div style={{marginTop: '6rem'}}>
                                                <img src={requireDefault('MetaMask')} alt=""
                                                     style={{width: '2rem'}}
                                                />
                                                <pre style={{fontSize: '.6rem'}}>{`Or connect with mobile wallet`}</pre>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={requireDefault('howToPlayVertical')} alt=""
                                         className={'positionAbsolute'}
                                         style={{top: '6rem', right: '2rem', width: '2rem'}}
                                    />
                                </div>
                                <div style={{padding: '5rem 1rem'}}
                                     className={'childrenSpacingTopBigPhone positionRelative linearGreen'}>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>Select a scientist
                                    </div>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>to hatch a pet cat
                                    </div>
                                    <div className={'flex justifyContentSpaceBetween'}>
                                        <div>
                                        <pre style={{fontSize: '.6rem', marginTop: '2rem'}}>{`Enter the Token ID or 
invitation link to find 
the corresponding cat 
scientist accurately`}</pre>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}
                                                 style={{marginTop: '4rem'}}>
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('Fur')} alt="" style={{width: '1.5rem'}}/>
                                                    <pre style={{fontSize: '.6rem'}}>{`Click here to filter fur`}</pre>
                                                </div>
                                            </div>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}
                                                 style={{marginTop: '4rem'}}>
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('mintSBT1')} alt="" style={{width: '4rem'}}/>
                                                    <pre style={{fontSize: '.6rem'}}>{`Click MINT to let scientists 
create a unique cat for you`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flexColumn'}>
                                            <div style={{marginTop: '2rem'}}>
                                                <img src={requireDefault('scientistIcon')} alt="" style={{width: '3rem'}}/>
                                                <pre style={{fontSize: '.6rem'}}>{`Click here to view 
your own cat 
scientists`}</pre>
                                            </div>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}
                                                 style={{marginTop: '5rem'}}>
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('1_1')} alt="" style={{width: '1.5rem'}}/>
                                                    <pre style={{fontSize: '.6rem'}}>{`Click here to filter glasses`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={requireDefault('selectScientistPhone')} alt=""
                                         className={'positionAbsolute'}
                                         style={{left: '5rem', top: '13rem', width: '19rem'}}
                                    />
                                </div>
                                <div style={{padding: '5rem 1rem', background: '#F6F7FF'}}
                                     className={'childrenSpacingTopBigPhone positionRelative'}
                                >
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>Pet cat</div>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>interactive area</div>
                                    <div className={'flex justifyContentSpaceBetween'}>
                                        <div style={{height: '13rem', marginTop: '5rem'}}
                                             className={'flexColumn justifyContentSpaceBetween'}>
                                        <pre style={{fontSize: '.8rem'}}>{`Pet cat level and 
experience points`}</pre>
                                            <div>
                                                <img src={requireDefault('startTyping')} alt="" style={{width: '6rem'}}/>
                                                <pre style={{fontSize: '.8rem'}}>{`Players can chat with 
Pet Cat`}</pre>
                                            </div>
                                            <div>
                                                <img src={requireDefault('selected')} alt="" style={{width: '6rem'}}/>
                                                <pre style={{fontSize: '.8rem'}}>{`Choose a Pet Cat to 
interact with`}</pre>
                                            </div>
                                        </div>
                                        <div className={'childrenSpacingTopBiggerPhone'} style={{marginTop: '3rem'}}>
                                            <div>
                                                <img src={requireDefault('power')} alt="" style={{width: '4rem'}}/>
                                                <pre style={{fontSize: '.8rem'}}>{`Pet cat battery power`}</pre>
                                            </div>
                                            <div>
                                                <img src={requireDefault('switchButton')} alt="" style={{width: '2.2rem'}}/>
                                                <pre style={{fontSize: '.8rem'}}>{`Pet training and 
games portal`}</pre>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={requireDefault('petCatPhone')} alt=""
                                         className={'positionAbsolute'}
                                         style={{left: '6.7rem', top: '11rem', width: '14rem'}}
                                    />
                                </div>
                                <div className={'positionRelative overflowHidden'}>
                                    <div style={{padding: '5rem .7rem'}}
                                         className={'childrenSpacingTopBigPhone positionRelative linearGreen'}>
                                        <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>Pet training</div>
                                        <div className={'saf textAlignCenter'} style={{fontSize: '2rem'}}>and games</div>
                                        <div className={'flex justifyContentSpaceBetween'}>
                                            <div style={{marginTop: '-3rem'}}>
                                                <div>
                                                    <img src={requireDefault('fishPond')} alt="" style={{width: '2rem'}}/>
                                                    <pre style={{fontSize: '.8rem'}}>{`Here you can teleport 
to the fish pond for 
fishing`}</pre>
                                                </div>
                                                <div>
                                                    <img src={requireDefault('workshop')} alt="" style={{width: '3.5rem'}}/>
                                                    <pre style={{fontSize: '.8rem'}}>{`Yard workstation, 
Batteries can be 
synthesized and used`}</pre>
                                                </div>
                                                <div style={{marginTop: '1rem'}}>
                                                    <img src={requireDefault('0812')} alt="" style={{width: '2.5rem'}}/>
                                                    <pre style={{fontSize: '.8rem'}}>{`Pet cat crystal energy 
flower, recyclable`}</pre>
                                                </div>
                                                <div>
                                                <pre style={{fontSize: '.8rem', marginTop: '.5rem'}}>{`Friend interaction 
function entry `}</pre>
                                                </div>
                                            </div>
                                            <div className={'childrenSpacingTopPhone'} style={{marginTop: '-2rem'}}>
                                                <div>
                                                    <img src={requireDefault(' daka')} alt="" style={{width: '2.5rem'}}/>
                                                    <pre style={{fontSize: '.8rem'}}>{`Daily pet cuddle`}</pre>
                                                </div>
                                                <div>
                                                    <img src={requireDefault('market')} alt="" style={{width: '2.5rem'}}/>
                                                    <pre style={{fontSize: '.8rem'}}>{`Pet props trading 
market`}</pre>
                                                </div>
                                                <div>
                                                <pre style={{fontSize: '.8rem'}}>{`Pet room portal You 
can interact with 
your cat`}</pre>
                                                </div>
                                                <div style={{marginTop: '2.5rem'}}>
                                                <pre style={{fontSize: '.8rem'}}>{`Explore and discover
function entry`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                        <img src={requireDefault('theWayPhone')} alt=""
                                             className={'positionAbsolute'}
                                             style={{left: '6rem', top: '10rem', width: '15rem'}}
                                        />
                                    </div>
                                    <div style={{padding: '0 2rem 7rem 2rem'}} className={'childrenSpacingTopBiggerPhone'}>
                                        {
                                            howToPlayContent.map(v => <div className={'childrenSpacingTopBigPhone'}>
                                                    <div
                                                        className={'paddingLeftRightBigPhone flex alignItemsCenter childrenSpacingLeftPhone'}
                                                        style={{
                                                            height: '4.7rem',
                                                            background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)',
                                                        }}
                                                    >
                                                        <img src={requireDefault('wellNo')} alt="" style={{width: '1.2rem'}}/>
                                                        <div className={'saf lineHeight1'} style={{fontSize: '1.5rem'}}>{v[0]}
                                                        </div>
                                                    </div>
                                                    {
                                                        v[1].map(v =>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                <img src={requireDefault(v[0])} alt=""
                                                                     style={{width: '1.5rem'}}/>
                                                                <pre style={{fontSize: '.8rem'}}>{v[1]}</pre>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <img src={requireDefault('wavePoint2')} alt=""
                                         className={'positionAbsolute'}
                                         style={{top: '35rem', width: '15rem', right: '-10rem'}}
                                    />
                                    <img src={requireDefault('wavePoint3')} alt=""
                                         className={'positionAbsolute'} style={{left: 0, width: '27rem', bottom: '-18rem'}}
                                    />
                                </div>
                            </div>
                        )
                        : <div>
                            {pageTop}
                            <div className={'backgroundBlack flexCenter justifyContentSpaceBetween positionRelative'}
                                 style={{padding: '3rem', overflow: 'hidden'}}>
                                <div className={'childrenSpacingTopBigger'}>
                                    <div
                                        className={'flex alignItemsCenter childrenSpacingLeftBigger borderRadius overflowVisible'}>
                                        <img style={{width: '4rem', marginLeft: '-1rem'}}
                                             src={requireDefault('genesisCatNFT')} alt=""/>
                                        <div className={'childrenSpacingTopBig'}>
                                            <div className={'saf activeColor'} style={{fontSize: '.6rem'}}>Genesis Cat NFT
                                            </div>
                                            <pre className={'smallFontSize colorWhite'} style={{lineHeight: 1.5}}>{`Genesis Cat, a.k.a. The Cat Scientist
It is an NFT asset for collectors that can be freely 
traded on the market. It allows users to hatch pet cats.`}</pre>
                                        </div>
                                    </div>
                                    <div className={'borderRadius overflowVisible'}>
                                        <img style={{width: '2.4rem'}}
                                             src={requireDefault('hi')} alt=""/>
                                        <div className={'childrenSpacingTopBig'}>
                                            <div className={'saf activeColor'} style={{fontSize: '.6rem'}}>
                                                Interaction & Chat
                                            </div>
                                            <pre className={'smallFontSize colorWhite'} style={{lineHeight: 1.5}}>{`Cat interaction includes all personalized companion
scenes such as chatting, dressing up, and showrooms.`}</pre>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={'borderRadius overflowVisible flexColumn alignItemsFlexEnd childrenSpacingTopBig'}>
                                    <img style={{width: '4rem'}}
                                         src={requireDefault('littleCat')} alt=""/>
                                    <div className={'saf activeColor'} style={{fontSize: '.6rem'}}>CAT SBT</div>
                                    <div className={'childrenSpacingTopBig'}>
                                <pre className={'smallFontSize colorWhite textAlignRight'} style={{lineHeight: 1.5}}>{`Pet cats are the SBT assets of players, generated from 
the Genesis Cats' hatching, inheriting their skin and eye 
color. They are a unique identity linked to OpenAI, 
and shall accompany players in their growth.`}</pre>
                                    </div>
                                </div>
                                {
                                    [
                                        ['leftArrow', 10.5, 11],
                                        ['rightArrow', 4, 11],
                                    ].map(v => <img src={requireDefault(v[0])} alt="" className={'positionAbsolute'}
                                                    style={
                                                        {
                                                            top: `${v[1]}rem`,
                                                            left: `${v[2]}rem`,
                                                            width: '10rem',
                                                        }
                                                    }
                                    />)
                                }
                                <img src={requireDefault('wavePoint2')} className={'positionAbsolute'}
                                     style={{
                                         top: '-1rem',
                                         right: '-5rem',
                                         width: '10rem',
                                     }}
                                     alt=""/>
                                <img src={requireDefault('wavePoint1')} className={'positionAbsolute'}
                                     style={{
                                         left: 0,
                                         bottom: 0,
                                         width: '5rem',
                                     }}
                                     alt=""/>
                            </div>
                            <div style={{padding: '3rem'}} className={'flex justifyContentSpaceBetween positionRelative'}>
                                <div className={'flex alignItemsFlexEnd'}>
                                    <img src={requireDefault('howToPlayVertical')} alt=""
                                         style={{width: '1rem'}}
                                    />
                                    <img src={requireDefault('setUpWallet')} alt=""
                                         style={{width: '9rem', marginLeft: '2rem'}}
                                    />
                                </div>
                                <div className={'flexColumn justifyContentSpaceBetween alignItemsFlexEnd'}>
                            <pre className={'saf textAlignRight'} style={{
                                fontSize: '1rem'
                            }}>{`Set up a wallet 
to log in to the app`}</pre>
                                    <div className={'childrenSpacingTop flexColumn'}
                                         style={{paddingBottom: '1.5rem', marginBottom: '1rem', marginRight: '6.5rem'}}>
                                        <img src={requireDefault('MetaMask')} alt=""
                                             style={{width: '1.2rem', height: '1.2rem'}}/>
                                        <pre style={{fontSize: '.33rem'}}>{`Or connect with mobile wallet`}</pre>
                                    </div>
                                </div>
                                <div className={'childrenSpacingTopBig positionAbsolute'}
                                     style={{
                                         top: '6.7rem',
                                         left: '15.7rem'
                                     }}
                                >
                                    <img src={requireDefault('particleLogin')} alt=""
                                         style={{height: '1.2rem'}}
                                    />
                                    <div style={{fontSize: '.33rem'}}>You can choose to log in with a media account</div>
                                </div>
                            </div>
                            <div className={'linearGreen positionRelative'} style={{padding: '3rem'}}>
                                <div className={'saf textAlignCenter'} style={{fontSize: '1rem'}}>
                                    Select a scientist to hatch a pet cat
                                </div>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'flexCenter justifyContentSpaceBetween'} style={{paddingTop: '2.5rem'}}>
                                        <div className={'flexColumn justifyContentSpaceBetween'} style={{height: '8rem'}}>
                                <pre className={'bigFontSize'}>{`Enter the Token ID or invitation link to 
find the corresponding cat scientist 
accurately`}</pre>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('Fur')} alt="" style={{width: '1rem'}}/>
                                                    <pre className={'bigFontSize'}>{`Click here to filter fur`}</pre>
                                                </div>
                                            </div>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}
                                                 style={{
                                                     transform: 'translateY(1rem)'
                                                 }}
                                            >
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('mintSBT1')} alt="" style={{width: '3rem'}}/>
                                                    <pre className={'bigFontSize'}>{`Click MINT to let scientists 
create a unique cat for you`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween positionRelative'}
                                         style={{paddingTop: '2.5rem'}}>
                                        <div className={'flexColumn justifyContentSpaceBetween'} style={{height: '8rem'}}>
                                            <div className={'childrenSpacingTop'}>
                                                <img src={requireDefault('scientistIcon')} alt=""
                                                     style={{width: '1.5rem'}}/>
                                                <pre className={'bigFontSize'}>{`Click here to view your own cat 
scientists`}</pre>
                                            </div>
                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                <div className={'childrenSpacingTopSmall'}>
                                                    <img src={requireDefault('1_1')} alt="" style={{width: '1rem'}}/>
                                                    <pre className={'bigFontSize'}>{`Click here to filter glasses`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img src={requireDefault('selectScientist')} className={'positionAbsolute'}
                                     alt="" style={{width: '13rem', top: '6rem', left: '8.7rem'}}/>
                            </div>
                            <div style={{padding: '3rem', background: '#F6F7FF'}}
                                 className={'flexCenter justifyContentSpaceBetween positionRelative'}>
                                <div className={'childrenSpacingTopBigger'}>
                            <pre className={'saf'} style={{fontSize: '1rem', lineHeight: 1.5}}>{`Pet cat
interactive area`}</pre>
                                    <div className={'positionAbsolute'} style={{top: '6.2rem', left: '6rem'}}>
                                        Pet cat level and experience points
                                    </div>
                                    <div className={'childrenSpacingTopBigger'}
                                         style={{padding: '2rem 0 0 2.5rem'}}
                                    >
                                        <div className={'childrenSpacingTop'}>
                                            <img src={requireDefault('startTyping')} alt="" style={{width: '4rem'}}/>
                                            <div>Players can chat with Pet Cat</div>
                                        </div>
                                        <div className={'childrenSpacingTop'}>
                                            <img src={requireDefault('selected')} alt="" style={{width: '5rem'}}/>
                                            <div className={'paddingLeftBig'}>Choose a Pet Cat to interact with</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: '5rem', margin: '-1rem 2.5rem 0 0'}}>
                                    <div className={'childrenSpacingTop'}>
                                        <img src={requireDefault('power')} alt=""
                                             className={'width100'} style={{width: '2rem'}}
                                        />
                                        <div>Pet cat battery power</div>
                                    </div>
                                    <div className={'childrenSpacingTop'} style={{paddingTop: '2.5rem'}}>
                                        <img src={requireDefault('switchButton')} alt=""
                                             className={'width100'} style={{width: '1.5rem'}}
                                        />
                                        <div>Pet training and games portal</div>
                                    </div>
                                </div>
                                <img src={requireDefault('petCat')} alt=""
                                     className={'positionAbsolute'} style={{width: '8rem', left: '11rem', top: '4rem'}}
                                />
                            </div>
                            <div className={'positionRelative overflowHidden'}>
                                <div style={{padding: '3rem'}} className={'linearGreen'}>
                                    <div className={'saf textAlignCenter'} style={{fontSize: '1rem'}}>
                                        Pet training and games
                                    </div>
                                    <div className={'flex justifyContentSpaceBetween'}>
                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                            <div className={'flexColumn justifyContentSpaceBetween'}
                                                 style={{height: '8rem'}}>
                                                <div className={'childrenSpacingTop'}>
                                                    <img src={requireDefault('fishPond')} alt="" style={{width: '1rem'}}/>
                                                    <pre className={'bigFontSize'}>{`Here you can teleport to the fish 
pond for fishing`}</pre>
                                                </div>
                                                <div className={'childrenSpacingTop'}>
                                                    <img src={requireDefault('0812')} alt="" style={{width: '1rem'}}/>
                                                    <pre
                                                        className={'bigFontSize'}>{`Pet cat crystal energy flower, recyclable`}</pre>
                                                </div>
                                                <div className={'childrenSpacingTop'}>
                                                <pre
                                                    className={'bigFontSize'}>{`Friend interaction function entry `}</pre>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flexCenter justifyContentSpaceBetween'}
                                             style={{paddingTop: '0.5rem'}}>
                                            <div className={'flexColumn justifyContentSpaceBetween'}
                                                 style={{height: '8rem'}}>
                                                <div className={'childrenSpacingLeft flex alignItemsCenter'}>
                                                    <img src={requireDefault(' daka')} style={{width: '1rem'}} alt=""/>
                                                    <div className={'bigFontSize'}>Daily pet cuddle</div>
                                                </div>
                                                <div className={'childrenSpacingLeft flex alignItemsCenter'}>
                                                    <img src={requireDefault('market')} style={{width: '1rem'}} alt=""/>
                                                    <div className={'bigFontSize'}>Pet props trading market</div>
                                                </div>
                                                <div className={'childrenSpacingTop'}>
                                                    <img src={requireDefault('workshop')} alt="" style={{width: '2rem'}}/>
                                                    <pre className={'bigFontSize'}>{`Yard workstation, Batteries can be 
synthesized and used`}</pre>
                                                </div>
                                                <div className={'childrenSpacingTopBigger'}
                                                     style={{transform: 'translateY(.6rem)'}}>
                                                    <pre
                                                        className={'bigFontSize'}>{`Pet room portal You can interact with your cat`}</pre>
                                                    <pre
                                                        className={'bigFontSize'}>{`Explore and discover function entry`}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img src={requireDefault('theWayToWin')} alt=""
                                     style={{width: '11rem', top: '4.5rem', left: '7.5rem'}}
                                     className={'positionAbsolute'}/>
                                <div style={{padding: '0 3rem 3rem 3rem'}} className={'flex justifyContentSpaceBetween'}>
                                    {
                                        howToPlayContent.map(v => <div style={{width: '10rem'}}
                                                                       className={'childrenSpacingTopBigger'}>
                                            <div
                                                className={'paddingLeftRightBig flex alignItemsCenter childrenSpacingLeftBig'}
                                                style={{
                                                    height: '2.5rem',
                                                    background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)',
                                                }}
                                            >
                                                <img src={requireDefault('wellNo')} alt="" style={{width: '.6rem'}}/>
                                                <div className={'saf lineHeight1'} style={{fontSize: '.8rem'}}>
                                                    {v[0]}
                                                </div>
                                            </div>
                                            {
                                                v[1].map(v =>
                                                    <div className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                        <img src={requireDefault(v[0])} alt="" style={{width: '.7rem'}}/>
                                                        <pre>{v[1]}</pre>
                                                    </div>
                                                )
                                            }
                                        </div>)
                                    }
                                </div>
                                <img src={requireDefault('wavePoint2')} alt=""
                                     className={'positionAbsolute'} style={{top: '8rem', width: '13rem', right: '-8rem'}}
                                />
                                <img src={requireDefault('wavePoint3')} alt=""
                                     className={'positionAbsolute'} style={{left: 0, width: '13rem', bottom: '-8.3rem'}}
                                />
                            </div>
                            {pageFoot}
                        </div>,
                    // genesis: isMintScientist
                    'genesis/*': isMintScientist
                        ? (() => {
                            const
                                colors = backgroundAttribute[1],
                                attributeIndex = checkData([data, 'attributeIndex'], 5),
                                transparentColor = colors[0],
                                isTransparentColor = v => v === transparentColor,
                                attributeData = checkData([data, 'attributeData'],
                                    {
                                        [backgroundAttributeKey]: transparentColor,
                                        ...(() => {
                                            const obj = {}
                                            newScientistAttributes.map(v => obj[v[0]] = undefined)
                                            return obj
                                        })()
                                    }
                                ),
                                attribute = checkDataArray(newScientistAttributes, attributeIndex),
                                attributeKey = attribute[0],
                                attributeValue = checkDataArray(attribute, 1),
                                attributeDataBackground = attributeData[backgroundAttributeKey],
                                setAttributeData = (data = {}) => setData({
                                    attributeData: {...attributeData, ...data}
                                }),
                                isSelectAttribute = attributeIndex !== -1,
                                completeAttribute = [
                                    backgroundAttribute,
                                    ...newScientistAttributes
                                ],
                                allTokenAttributes = checkDataArray(data, 'allTokenAttributes'),
                                checkRequired = (
                                    func = () => {
                                    },
                                    falseFunc = () => {
                                    },
                                    tokenAttributes = []
                                ) => {
                                    let isequired = true
                                    const equiredAttributeKeys = [backgroundAttribute, ...requiredAttributes].map(v => v[0])
                                    Promise.all(equiredAttributeKeys.map(
                                        v => {
                                            if (isequired) {
                                                isequired = isNotNull(attributeData[v])
                                            }
                                        }
                                    )).then(() => {
                                        if (isequired) {
                                            if (allTokenAttributes.includes(tokenAttributes.join(','))) {
                                                falseFunc()
                                                toast('The NFT image already exists')
                                            } else {
                                                func()
                                            }
                                        } else {
                                            falseFunc()
                                            toast(`Please select all the following attributes:\n${equiredAttributeKeys.join(',')}`)
                                        }
                                    })
                                },
                                nftTokenId = checkDataString(data, 'nftTokenId'),
                                nftTokenIdIsNotNullOrEmpty = isNotNullOrEmpty(nftTokenId),
                                getCurrentTokenAttributes = () => {
                                    const tokenAttributes = []
                                    return Promise.all(completeAttribute.map((v, i) => {
                                        const key = v[0]
                                        tokenAttributes.push((key === backgroundAttributeKey ? colors : checkDataArray(newScientistAttributes, i - 1, 1)).indexOf(attributeData[key]) + 1)
                                    })).then(() => tokenAttributes)
                                },
                                downloadImage = () => checkRequired(async () => {
                                        if (nftTokenIdIsNotNullOrEmpty) {
                                            try {
                                                // 获取要转换的DOM元素
                                                const zip = new JSZip(), attributesList = []
                                                Object.keys(attributeData).map(k => {
                                                    const value = attributeData[k]
                                                    attributesList.push({
                                                        'trait_type': k,
                                                        value: isNotNull(value) ? value : 'None',
                                                    })
                                                })
                                                const imageName = `${nftTokenId}.png`
                                                zip.file(
                                                    nftTokenId,
                                                    new Blob([JSON.stringify(
                                                        {
                                                            name: `Cat Scientist #${nftTokenId}`,
                                                            image: `${checkDataString(chainValue, 'imageBaseUrl')}${imageName}`,
                                                            attributes: attributesList
                                                        }, null, 2)], {type: 'text/plain'})
                                                )
                                                zip.file(
                                                    imageName,
                                                    await toBlob(
                                                        document.getElementById('capture'),
                                                        {
                                                            canvasWidth: 500,
                                                            canvasHeight: 500,
                                                        }
                                                    ),
                                                    {base64: true}
                                                )
                                                zip.generateAsync({type: 'blob'})
                                                    .then(content => require('file-saver')(content, [nftTokenId, 'zip'].join('.')))
                                            } catch (error) {
                                                console.error('Error converting <div> to image:', error);
                                            }
                                        } else {
                                            toast('Please search for the NFT first')
                                        }
                                    },
                                ),
                                newScientistPrice = checkData0(data, 'newScientistPrice'),
                                mintNewScientist = () => switchEthereumChain(
                                    () => {
                                        toggleShowLoading(true)
                                        const toggleShowLoadingToast = text => {
                                            toggleShowLoading()
                                            toast(text)
                                        }
                                        canAccessWalletConnectUrl(
                                            () => getCurrentTokenAttributes().then(tokenAttributes => checkRequired(
                                                () => rC('price').then(value => prepareWriteContract({
                                                        value,
                                                        abi: contractAbi,
                                                        address: contractAddress,
                                                        functionName: 'mintAllowList',
                                                        walletClient: {account: address},
                                                        args: [1, ...aL, tokenAttributes],
                                                    }).then(r => writeContract(r.request).then(r => {
                                                        const hash = checkData([r, 'hash'])
                                                        console.log(hash, '+++++++++++++++++++++++++++++++');
                                                        toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                                                    }).catch(e => toggleShowLoadingToast(e.message.split('\n')[0])))
                                                        .catch(e => {
                                                            const errorStr = [1, 2].map(v => e.message.split('\n')[v]).join('\n')
                                                            toggleShowLoadingToast(
                                                                errorStr.includes('ExceedsAllowListQuota')
                                                                    ? 'The current address exceeds the available number'
                                                                    : (
                                                                        errorStr.includes('NotOnAllowList')
                                                                            ? 'The current address is not in the whitelist'
                                                                            : errorStr
                                                                    )
                                                            )
                                                        })
                                                ).catch(() => toggleShowLoading()),
                                                () => toggleShowLoading(),
                                                tokenAttributes
                                            )),
                                            () => toggleShowLoading()
                                        )
                                    },
                                    undefined,
                                    true
                                ),
                                searchNFTAttributes = () => {
                                    if (nftTokenIdIsNotNullOrEmpty) {
                                        toggleShowLoading(true)
                                        const nftTokenIdNumber = Number(nftTokenId)
                                        rC('ownerOf', nftTokenIdNumber).then(() => {
                                            const aD = {}
                                            completeAttribute.map(
                                                (v, i) => {
                                                    const key = v[0]
                                                    rC('tokenAttributes', nftTokenIdNumber, i).then(
                                                        attributeIndex => {
                                                            aD[key] = checkData([v[1], Number(attributeIndex) - 1], undefined)
                                                        }
                                                    ).catch(e => toast(e.toString())).then(() => {
                                                        if (i >= completeAttribute.length - 1) {
                                                            setAttributeData(aD)
                                                            toggleShowLoading()
                                                            toast('Success')
                                                        }
                                                    })
                                                }
                                            )
                                        }).catch(() => {
                                            toggleShowLoading()
                                            toast('The token does not exist')
                                        })
                                    }
                                }
                            return isPhoneClientWidth
                                ? pageTopFootPhone(
                                    tokenId
                                        ? tokenDetail
                                        : <div>
                                            <div style={{height: '100vh'}} className={'flexColumn'}>
                                                <div className={'flexGrow1Column overflowYScroll flexColumn boxSizingBorderBox'}
                                                     style={{paddingBottom: '5rem'}}>
                                                    <div style={{paddingTop: '3rem'}} className={'boxSizingBorderBox'}>
                                                        <div
                                                            className={'paddingBigPhone flexCenter childrenSpacingLeft justifyContentSpaceBetween'}>
                                                            <div className={'flex childrenSpacingLeft alignItemsCenter'}>
                                                                <img onClick={() => toggleIsMintScientist()}
                                                                     style={{width: '2rem'}}
                                                                     className={'cursorPointer'}
                                                                     src={requireDefault('closeButton')}
                                                                     alt=""/>
                                                            </div>
                                                            <div onClick={makeNFTCountAdd}
                                                                 className={'bigFontSize positionRelative borderRadius999 overflowVisible'}
                                                                 style={{
                                                                     padding: '.3rem 2rem',
                                                                     background: 'linear-gradient(180deg, #BAFF00 0%, rgba(186, 255, 0, 0.10) 100%)'
                                                                 }}
                                                            >
                                                                <img src={requireDefault('Group427319692')} alt=""
                                                                     style={{
                                                                         width: '2.5rem',
                                                                         top: '.1rem',
                                                                         left: '-.7rem'
                                                                     }}
                                                                     className={'positionAbsolute'}/>
                                                                <div className={'smallerFontSizePhone'}>
                                                                    WL users can get exclusive space clothes
                                                                </div>
                                                                <div style={{fontSize: '.58rem'}}>
                                                                    For users who need a WL, plz open a ticket on our DC
                                                                </div>
                                                            </div>
                                                            {
                                                                sessionMakeNFT
                                                                    ? <div className={'flexColumnCenter'}>
                                                                        <input type="text" value={nftTokenId}
                                                                               style={{width: '3rem'}}
                                                                               className={'borderSmallBlackLine borderRadius padding'}
                                                                               onChange={e => setData({
                                                                                   nftTokenId: e.target.value
                                                                               })}/>
                                                                        <div className={'cursorPointer'}
                                                                             onClick={searchNFTAttributes}
                                                                        >查询
                                                                        </div>
                                                                    </div>
                                                                    : <div/>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={'paddingBigPhone childrenSpacingTopBigPhone flexGrow1Column overflowYScroll'}
                                                        style={{background: isSelectAttribute ? 'rgba(0,0,0,.7)' : '#F4F7F9'}}>
                                                        <div
                                                            className={`childrenSpacingTopBigPhone height100 flexColumn overflowHidden ${isSelectAttribute ? 'borderRadiusPhone' : ''}`}
                                                            style={{background: isSelectAttribute ? 'white' : 'transparent'}}
                                                        >
                                                            <div
                                                                className={'width100 boxSizingBorderBox flexCenter justifyContentSpaceBetween paddingPhone borderRadiusPhone backgroundWhite boxShadow'}>
                                                                {
                                                                    newScientistAttributes.map(
                                                                        (v, i) => <img
                                                                            src={requireDefault(`${v[0]}${attributeIndex === i ? 'Active' : ''}`)}
                                                                            alt=""
                                                                            className={'cursorPointer'}
                                                                            onClick={() => setData({attributeIndex: attributeIndex === i ? -1 : i})}
                                                                            style={{width: '1.5rem'}}/>
                                                                    )
                                                                }
                                                            </div>
                                                            {isSelectAttribute
                                                                ? <div
                                                                    className={'flex flexWrap flexGrow1Column overflowYScroll'}
                                                                    style={{alignContent: 'flex-start',}}
                                                                >
                                                                    {
                                                                        attributeValue.map(v => <div
                                                                            onClick={() => setAttributeData({[attributeKey]: v})}
                                                                            className={'positionRelative borderRadiusPhone cursorPointer boxSizingBorderBox'}
                                                                            style={{
                                                                                width: '12rem', height: '12rem',
                                                                                margin: '0 0 1rem 1rem',
                                                                                background: 'rgba(236, 240, 244, .5)',
                                                                                ...ternaryObject(
                                                                                    attributeData[attributeKey] === v,
                                                                                    {border: 'solid .3rem black'}
                                                                                )
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className={'width100 height100 positionAbsolute'}
                                                                                src={requireDefault(`KittyScientist/${attributeKey}/${v}`)}
                                                                                alt=""/>
                                                                            {
                                                                                annexAttributes.map(v1 => {
                                                                                    const fronts = checkDataArray(v1, 2)
                                                                                    return attributeKey === v1[0] && v && (fronts.length <= 0 || fronts.includes(v)) &&
                                                                                        <img
                                                                                            className={'width100 height100 positionAbsolute'}
                                                                                            src={requireDefault(`KittyScientist/${v1[1]}/${v}`)}
                                                                                            alt=""/>
                                                                                })
                                                                            }
                                                                            {
                                                                                annexAttributes.map(v1 => {
                                                                                    const fronts = checkDataArray(v1, 2)
                                                                                    return fronts.includes(v) &&
                                                                                        <img
                                                                                            src={requireDefault('Groupp427319693')}
                                                                                            alt=""
                                                                                            className={'positionAbsolute'}
                                                                                            style={{
                                                                                                width: '4rem',
                                                                                                top: 0,
                                                                                                left: '.1rem'
                                                                                            }}
                                                                                        />
                                                                                })
                                                                            }
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                : <div
                                                                    className={'childrenSpacingTopBigPhone flexGrow1Column overflowYScroll'}>
                                                                    <div className={'flexCenter'}>
                                                                        <div id='capture'
                                                                             style={{
                                                                                 width: '27rem',
                                                                                 height: '27rem',
                                                                                 overflow: 'hidden'
                                                                             }}
                                                                             className={'positionRelative'}
                                                                        >
                                                                            {
                                                                                completeAttribute.map(v => {
                                                                                    const key = v[0], value = attributeData[key]
                                                                                    return value && !isTransparentColor(value) &&
                                                                                        <img
                                                                                            className={'positionAbsolute width100 height100'}
                                                                                            src={requireDefault(`KittyScientist/${key}/${value}`)}
                                                                                            alt=""/>
                                                                                })
                                                                            }
                                                                            {
                                                                                annexAttributes.map(v => {
                                                                                    const key = v[0],
                                                                                        value = attributeData[key],
                                                                                        fronts = checkDataArray(v, 2)
                                                                                    return value && (fronts.length <= 0 || fronts.includes(value)) &&
                                                                                        <img
                                                                                            className={'positionAbsolute width100 height100'}
                                                                                            src={requireDefault(`KittyScientist/${v[1]}/${value}`)}
                                                                                            alt=""/>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '3rem'}}
                                                                         className={'width100 boxSizingBorderBox paddingPhone borderRadiusPhone backgroundWhite boxShadow'}>
                                                                        <div className={'saf'}>background</div>
                                                                        <div className={'flex flexWrap'}
                                                                             style={{marginTop: '.5rem'}}>
                                                                            {
                                                                                colors.map(v => <div
                                                                                        className={'boxSizingBorderBox borderRadius'}
                                                                                        onClick={() => setAttributeData({[backgroundAttributeKey]: v})}
                                                                                        style={{
                                                                                            width: '2rem', height: '2rem',
                                                                                            margin: '0 .5rem .5rem 0',
                                                                                            ...ternaryObject(
                                                                                                v === 'white',
                                                                                                {border: 'solid .1rem black'}
                                                                                            ),
                                                                                            ...ternaryObject(attributeDataBackground === v, {
                                                                                                border: 'solid .2rem black'
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img className={'width100 height100'}
                                                                                             src={requireDefault(isTransparentColor(v) ? 'transparent' : `KittyScientist/${backgroundAttributeKey}/${v}`)}
                                                                                             alt=""/>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{marginTop: '.7rem'}}>
                                                                            <div className={'saf'}>Selected Components</div>
                                                                            <div className={'flex overflowXScroll'}
                                                                                 style={{marginTop: '.5rem'}}>
                                                                                {
                                                                                    newScientistAttributes.map(v => {
                                                                                        const key = v[0]
                                                                                        return <div
                                                                                            className={'borderRadiusPhone positionRelative flexShrink0'}
                                                                                            style={{
                                                                                                width: '5rem',
                                                                                                height: '5rem',
                                                                                                overflow: 'visible',
                                                                                                margin: '0 .5rem .5rem 0',
                                                                                                border: 'solid .1rem black',
                                                                                                background: 'rgba(236, 240, 244, .5)',
                                                                                            }}>
                                                                                            {
                                                                                                (() => {
                                                                                                    const value = attributeData[key]
                                                                                                    return value && <img
                                                                                                        className={'width100 height100 positionAbsolute'}
                                                                                                        src={requireDefault(`KittyScientist/${key}/${value}`)}
                                                                                                        alt=""/>
                                                                                                })()
                                                                                            }
                                                                                            {
                                                                                                annexAttributes.map(v => {
                                                                                                    const fronts = checkDataArray(v, 2),
                                                                                                        value = attributeData[key]
                                                                                                    return key === v[0] && value && (fronts.length <= 0 || fronts.includes(value)) &&
                                                                                                        <img
                                                                                                            className={'width100 height100 positionAbsolute'}
                                                                                                            src={requireDefault(`KittyScientist/${v[1]}/${value}`)}
                                                                                                            alt=""/>
                                                                                                })
                                                                                            }
                                                                                            <img
                                                                                                onClick={() => setAttributeData({[key]: undefined})}
                                                                                                className={'positionAbsolute zIndex1 cursorPointer'}
                                                                                                src={requireDefault('closeButton1')}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: '1.2rem',
                                                                                                    top: '-.1rem',
                                                                                                    right: '-.1rem'
                                                                                                }}/>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={'positionFixed zIndex999 backgroundWhite paddingPhone flexCenter boxSizingBorderBox'}
                                                    style={{left: 0, right: 0, bottom: 0}}>
                                                    {
                                                        isSelectAttribute
                                                            ? <div style={{width: '9rem', height: '4rem'}}
                                                                   onClick={() => setData({attributeIndex: -1})}
                                                                   className={'saf backgroundBlack borderRadiusPhone activeColor flexCenter'}>
                                                                Show Image
                                                            </div>
                                                            : <div
                                                                className={'borderRadiusPhone childrenSpacingLeftPhone flexCenter paddingLeftPhone'}
                                                                style={{
                                                                    background: '#F4F7F9',
                                                                    height: '4rem'
                                                                }}>
                                                                <div className={'saf'}>{newScientistPrice} ETH</div>
                                                                <div style={{width: '5rem'}}
                                                                     onClick={sessionMakeNFT ? downloadImage : mintNewScientist}
                                                                     className={`saf ${sessionMakeNFT ? 'smallerFontSizePhone' : ''} backgroundBlack borderRadiusPhone activeColor height100 flexCenter`}>
                                                                    {sessionMakeNFT ? 'download' : 'Mint'}
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            {loading}
                                        </div>,
                                    false
                                )
                                : <div>
                                    {pageTop}
                                    {
                                        (() => {
                                            return <div style={{paddingTop: '1.5rem'}}>
                                                <div
                                                    className={'paddingBigger flexCenter childrenSpacingLeft justifyContentSpaceBetween'}>
                                                    <div className={'flex childrenSpacingLeft alignItemsCenter'}>
                                                        <img onClick={() => toggleIsMintScientist()}
                                                             style={{width: '1rem'}}
                                                             className={'cursorPointer'}
                                                             src={requireDefault('closeButton')}
                                                             alt=""/>
                                                        <div className={'saf'}>back</div>
                                                    </div>
                                                    <div onClick={makeNFTCountAdd}
                                                         className={'bigFontSize positionRelative borderRadius999 overflowVisible'}
                                                         style={{
                                                             padding: '.3rem .7rem',
                                                             background: 'linear-gradient(180deg, #BAFF00 0%, rgba(186, 255, 0, 0.10) 100%)'
                                                         }}
                                                    >
                                                        <img src={requireDefault('Group427319692')} alt=""
                                                             style={{
                                                                 width: '1.5rem',
                                                                 top: 0,
                                                                 left: '-.8rem'
                                                             }}
                                                             className={'positionAbsolute'}/>
                                                        <div>WL users can get exclusive space clothes</div>
                                                        <div style={{fontSize: '.227rem'}}>
                                                            For users who need a WL, plz open a ticket on our DC
                                                        </div>
                                                    </div>
                                                    {
                                                        sessionMakeNFT
                                                            ? <div className={'childrenSpacingLeft flexCenter'}>
                                                                <input type="text" value={nftTokenId}
                                                                       style={{height: '.3rem'}}
                                                                       className={'borderSmallBlackLine borderRadius padding'}
                                                                       onChange={e => setData({
                                                                           nftTokenId: e.target.value
                                                                       })}/>
                                                                <div className={'cursorPointer'}
                                                                     onClick={searchNFTAttributes}
                                                                >查询
                                                                </div>
                                                            </div>
                                                            : <div/>
                                                    }
                                                </div>
                                                <div className={'paddingBigger flexCenter justifyContentSpaceBetween'}
                                                     style={{background: 'rgba(236, 240, 244, .5)', height: '12rem'}}>
                                                    <div
                                                        className={'paddingBigger backgroundWhite flex boxShadow childrenSpacingLeft'}
                                                        style={{height: '90%', borderRadius: '.5rem'}}
                                                    >
                                                        <div
                                                            className={'flexColumn height100'}
                                                            style={{
                                                                paddingRight: '.5rem',
                                                                borderRight: 'solid .03rem #E8E8E8'
                                                            }}
                                                        >
                                                            {
                                                                newScientistAttributes.map(
                                                                    (v, i) => <img
                                                                        src={requireDefault(`${v[0]}${attributeIndex === i ? 'Active' : ''}`)}
                                                                        alt=""
                                                                        className={'cursorPointer'}
                                                                        onClick={() => setData({attributeIndex: i})}
                                                                        style={{
                                                                            width: '.7rem',
                                                                            marginBottom: '.7rem'
                                                                        }}/>
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className={'flex flexWrap overflowYScroll height100'}
                                                            style={{width: '3.9rem', alignContent: 'flex-start'}}
                                                        >
                                                            {
                                                                attributeValue.map(v => <div
                                                                    onClick={() => setAttributeData({[attributeKey]: v})}
                                                                    className={'positionRelative borderRadius cursorPointer boxSizingBorderBox'}
                                                                    style={{
                                                                        width: '1.5rem', height: '1.5rem',
                                                                        margin: '0 0 .2rem .2rem',
                                                                        background: 'rgba(236, 240, 244, .5)',
                                                                        ...ternaryObject(
                                                                            attributeData[attributeKey] === v,
                                                                            {border: 'solid .07rem black'}
                                                                        )
                                                                    }}
                                                                >
                                                                    <img
                                                                        className={'width100 height100 positionAbsolute'}
                                                                        src={requireDefault(`KittyScientist/${attributeKey}/${v}`)}
                                                                        alt=""/>
                                                                    {
                                                                        annexAttributes.map(v1 => {
                                                                            const fronts = checkDataArray(v1, 2)
                                                                            return attributeKey === v1[0] && v && (fronts.length <= 0 || fronts.includes(v)) &&
                                                                                <img
                                                                                    className={'width100 height100 positionAbsolute'}
                                                                                    src={requireDefault(`KittyScientist/${v1[1]}/${v}`)}
                                                                                    alt=""/>
                                                                        })
                                                                    }
                                                                    {
                                                                        annexAttributes.map(v1 => {
                                                                            const fronts = checkDataArray(v1, 2)
                                                                            return fronts.includes(v) &&
                                                                                <img
                                                                                    src={requireDefault('Groupp427319693')}
                                                                                    alt=""
                                                                                    className={'positionAbsolute'}
                                                                                    style={{
                                                                                        width: '.7rem',
                                                                                        top: 0,
                                                                                        left: '.1rem',
                                                                                    }}
                                                                                />
                                                                        })
                                                                    }
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div id='capture'
                                                         style={{width: '10rem', height: '10rem', overflow: 'hidden'}}
                                                         className={'positionRelative'}
                                                    >
                                                        {
                                                            completeAttribute.map(v => {
                                                                const key = v[0], value = attributeData[key]
                                                                return value && !isTransparentColor(value) &&
                                                                    <img
                                                                        className={'positionAbsolute width100 height100'}
                                                                        src={requireDefault(`KittyScientist/${key}/${value}`)}
                                                                        alt=""/>
                                                            })
                                                        }
                                                        {
                                                            annexAttributes.map(v => {
                                                                const key = v[0], value = attributeData[key],
                                                                    fronts = checkDataArray(v, 2)
                                                                return value && (fronts.length <= 0 || fronts.includes(value)) &&
                                                                    <img
                                                                        className={'positionAbsolute width100 height100'}
                                                                        src={requireDefault(`KittyScientist/${v[1]}/${value}`)}
                                                                        alt=""/>
                                                            })
                                                        }
                                                    </div>
                                                    <div
                                                        className={'paddingBigger backgroundWhite boxShadow childrenSpacingTop'}
                                                        style={{height: '90%', width: '4rem', borderRadius: '.5rem'}}>
                                                        <div className={'saf'}>background</div>
                                                        <div className={'flex flexWrap'}>
                                                            {
                                                                colors.map(v => <div
                                                                        className={'boxSizingBorderBox borderRadius'}
                                                                        onClick={() => setAttributeData({[backgroundAttributeKey]: v})}
                                                                        style={{
                                                                            width: '.7rem', height: '.7rem',
                                                                            margin: '0 .1rem .1rem 0',
                                                                            ...ternaryObject(
                                                                                v === 'white',
                                                                                {border: 'solid .01rem black'}
                                                                            ),
                                                                            ...ternaryObject(attributeDataBackground === v, {
                                                                                border: 'solid .05rem black'
                                                                            })
                                                                        }}
                                                                    >
                                                                        <img className={'width100 height100'}
                                                                             src={requireDefault(isTransparentColor(v) ? 'transparent' : `KittyScientist/${backgroundAttributeKey}/${v}`)}
                                                                             alt=""/>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className={'childrenSpacingTop'}
                                                             style={{marginTop: '.7rem'}}>
                                                            <div className={'saf'}>Selected Components</div>
                                                            <div className={'flex flexWrap'}>
                                                                {
                                                                    newScientistAttributes.map(v => {
                                                                        const key = v[0]
                                                                        return <div
                                                                            className={'borderRadius positionRelative'}
                                                                            style={{
                                                                                width: '1.5rem',
                                                                                height: '1.5rem',
                                                                                overflow: 'visible',
                                                                                margin: '0 .3rem .3rem 0',
                                                                                border: 'solid .03rem black',
                                                                                background: 'rgba(236, 240, 244, .5)',
                                                                            }}>
                                                                            {
                                                                                (() => {
                                                                                    const value = attributeData[key]
                                                                                    return value && <img
                                                                                        className={'width100 height100 positionAbsolute'}
                                                                                        src={requireDefault(`KittyScientist/${key}/${value}`)}
                                                                                        alt=""/>
                                                                                })()
                                                                            }
                                                                            {
                                                                                annexAttributes.map(v => {
                                                                                    const fronts = checkDataArray(v, 2),
                                                                                        value = attributeData[key]
                                                                                    return key === v[0] && value && (fronts.length <= 0 || fronts.includes(value)) &&
                                                                                        <img
                                                                                            className={'width100 height100 positionAbsolute'}
                                                                                            src={requireDefault(`KittyScientist/${v[1]}/${value}`)}
                                                                                            alt=""/>
                                                                                })
                                                                            }
                                                                            <img
                                                                                onClick={() => setAttributeData({[key]: undefined})}
                                                                                className={'positionAbsolute zIndex1 cursorPointer'}
                                                                                src={requireDefault('closeButton1')}
                                                                                alt=""
                                                                                style={{
                                                                                    width: '.3rem',
                                                                                    top: '-.1rem',
                                                                                    right: '-.1rem'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <div
                                                                className={'borderRadius flexCenter justifyContentSpaceBetween paddingLeftBig'}
                                                                style={{background: '#F4F7F9', height: '1rem'}}>
                                                                <div className={'saf bigFontSize'}>
                                                                    {newScientistPrice} ETH
                                                                </div>
                                                                <div style={{width: '40%'}}
                                                                     onClick={sessionMakeNFT ? downloadImage : mintNewScientist}
                                                                     className={`saf ${sessionMakeNFT ? 'smallFontSize' : 'bigFontSize'} backgroundBlack borderRadius activeColor height100 flexCenter cursorPointer`}>
                                                                    {sessionMakeNFT ? 'download' : 'Mint'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })()
                                    }
                                    {pageFoot}
                                    {loading}
                                </div>
                        })()
                        : (() => {
                            const setDataScientistList = scientistList => {
                                    isNotNullOrEmpty(scientistList) && setData({scientistList: []})
                                    setData({scientistList: scientistList || defaultScientistList})
                                },
                                searchToken = () => {
                                    const tokenIdLinkMatch = tokenIdLink.match(/^\d+|\d+\b|\d+(?=\w)/g)
                                    if (isNotNullOrEmpty(tokenIdLinkMatch)) {
                                        toggleShowLoading(true)
                                        const tI = Number(checkDataString(tokenIdLinkMatch, 0))
                                        canAccessWalletConnectUrl(
                                            () => rC('tokenURI', tI)
                                                .then(() => {
                                                        if (metaJsons[Number(tI)]) {
                                                            toggleShowLoading()
                                                            setDataScientistList([tI])
                                                        } else {
                                                            getMetaJsonList([tI], checkDataString(chainValue, 'baseURI'))
                                                                .then(() => {
                                                                    toggleShowLoading()
                                                                    setDataScientistList([tI])
                                                                })
                                                                .catch(e => {
                                                                    toggleShowLoading()
                                                                    setDataScientistList()
                                                                })
                                                        }
                                                    }
                                                ).catch(() => {
                                                    toggleShowLoading()
                                                    setDataScientistList()
                                                }),
                                            () => {
                                                toggleShowLoading()
                                                setDataScientistList()
                                            }
                                        )
                                    } else {
                                        setDataScientistList()
                                    }
                                },
                                MAX_SUPPLY = checkData0(data, 'MAX_SUPPLY'),
                                mainnets = [
                                    ['Ethereum Mainnet', `TOTAL：${MAX_SUPPLY}`, true],
                                    ['Bitcoin Mainnet', 'GET YOUR GENESIS NFT']
                                ],
                                newScientistListScroll = React.createRef(),
                                newScientistListScrollFunc = () => {
                                    const node = newScientistListScroll.current,
                                        {length} = scientistList,
                                        nSLT = checkData0(newScientistListTotal)
                                    if (
                                        length < nSLT &&
                                        (node.scrollHeight - node.scrollTop - 1 <= node.clientHeight) &&
                                        !showLoading
                                    ) {
                                        toggleShowLoading(true)
                                        const sL = [
                                            ...scientistList,
                                            ...arrayId(
                                                length + scientistListSize > nSLT ? (nSLT - length) : scientistListSize,
                                                scientistList[scientistList.length - 1]
                                            )
                                        ]
                                        getMetaJsonList(sL, checkDataString(chainValue, 'baseURI'))
                                            .then(() => toggleShowLoading())
                                            .catch(e => {
                                                toggleShowLoading()
                                                toast(e.toString())
                                            }).then(() => setData({scientistList: sL}))
                                    }
                                }
                            return isPhoneClientWidth
                                ? pageTopFootPhone(
                                    tokenId
                                        ? tokenDetail
                                        : <div>
                                            <div
                                                className={'backgroundBlack paddingBigPhone childrenSpacingTopBigPhone positionRelative zIndex1 overflowHidden'}>
                                                <img className={'positionAbsolute zIndex-1'}
                                                     src={requireDefault('wavePoint')}
                                                     alt="" style={{width: '20rem', left: '-10rem', top: '40rem'}}
                                                />
                                                <img className={'positionAbsolute zIndex-1'}
                                                     src={requireDefault('wavePoint')}
                                                     alt="" style={{width: '25rem', right: '-10rem', top: '40rem'}}
                                                />
                                                <img src={requireDefault('title')} alt=""
                                                     style={{width: '90%', marginTop: '3rem'}}/>
                                                <div className={'childrenSpacingTopPhone'}>
                                                    <div className={'saf colorWhite smallFontSizePhone'}
                                                         style={{marginTop: '2rem'}}>
                                                        Holder community rights:
                                                    </div>
                                                    <div>
                                                        {
                                                            [
                                                                'Have DC exclusive channel',
                                                                'Have exclusive voting rights',
                                                                'Can participate in product design and co-construction',
                                                                'You can obtain a certain proportion of platform governance token',
                                                            ].map(
                                                                v => <div
                                                                    className={'childrenSpacingLeftPhone flex alignItemsCenter'}>
                                                                    <img src={requireDefault('triangle1')}
                                                                         style={{width: '.5rem'}}
                                                                         alt=""/>
                                                                    <div
                                                                        className={'colorWhite smallerFontSizePhone'}>{v}</div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={'borderRadiusPhone flex alignItemsCenter positionRelative'}
                                                    style={{
                                                        background: 'linear-gradient(90deg, rgba(186, 255, 0, 0.34) 3.56%, rgba(186, 255, 0, 0.00) 98.86%)',
                                                        padding: '2rem 2rem 1rem 2rem'
                                                    }}
                                                >
                                                    <div className={'colorWhite smallFontSizePhone'}>
                                                        This NFT cannot participate in APP pet games, pet games requires
                                                        you to
                                                        Mint SBT to play
                                                    </div>
                                                    <img className={'positionAbsolute'}
                                                         src={requireDefault('remind')}
                                                         style={{width: '2rem', top: 0, left: 0}}
                                                         alt=""
                                                    />
                                                </div>
                                                <div className={'flexColumnCenter positionRelative'} style={{
                                                    marginTop: '6rem'
                                                }}>
                                                    <div className={'borderRadiusPhone paddingBigPhone'}
                                                         style={{
                                                             background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.34) 0%, black 100%)'
                                                         }}
                                                    >
                                                        <img style={{width: '20rem'}}
                                                             src={requireDefault('Groupp427319681')}
                                                             alt=""/>
                                                    </div>
                                                    <img src={requireDefault('Group427319680')}
                                                         style={{width: '15rem'}}
                                                         alt=""/>
                                                    <img src={requireDefault('Vector3')}
                                                         style={{width: '3rem', top: '7rem', left: '3rem'}}
                                                         className={'positionAbsolute'}
                                                         alt=""/>
                                                </div>
                                                <div className={'childrenSpacingTopBigPhone'}
                                                     style={{marginTop: '2rem'}}>
                                                    {
                                                        mainnets.map(v => {
                                                            const isOpen = v[2]
                                                            return <div
                                                                className={`${isOpen ? 'backgroundActiveColor' : ''} borderRadius flexCenter justifyContentSpaceBetween childrenSpacingLeftBigger`}
                                                                style={{padding: '1rem 0', ...ternaryObject(!isOpen, {background: '#A1A1A1'})}}
                                                            >
                                                                <img src={requireDefault('trapezoidLeft')}
                                                                     alt=""
                                                                     style={{width: '.4rem'}}/>
                                                                <div
                                                                    className={'flexGrow1 flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={''}>
                                                                        <div>{v[0]}</div>
                                                                        <div className={'smallerFontSizePhone'}>{v[1]}</div>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => isOpen &&
                                                                            switchEthereumChain(
                                                                                () => toggleIsMintScientist(true),
                                                                                undefined,
                                                                                true,
                                                                            )}
                                                                        className={`borderRadiusPhone ${isOpen ? 'cursorPointer backgroundBlack' : ''} saf ${isOpen ? 'activeColor' : 'colorWhite'}`}
                                                                        style={{
                                                                            padding: '1rem',
                                                                            ...ternaryObject(!isOpen, {background: '#545454'})
                                                                        }}
                                                                    >
                                                                        {isOpen ? 'continue' : 'Coming Soon'}
                                                                    </div>
                                                                </div>
                                                                <img src={requireDefault('trapezoidRight')}
                                                                     alt=""
                                                                     style={{width: '.4rem'}}/>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={'childrenSpacingTopBigPhone'}
                                                 style={{padding: '4.5rem 1.2rem 1.2rem 1.2rem'}}>
                                                <div className={'paddingBigPhone'}>
                                                    <div
                                                        className={'borderRadius linearGreen paddingBigPhone overflowVisible'}>
                                                        <div className={'flexColumnCenter childrenSpacingTopPhone'}
                                                             style={{transform: 'translateY(-4rem)'}}
                                                        >
                                                            <img src={requireDefault('newGenesisCat')} alt=""
                                                                 style={{width: '50%'}}/>
                                                            <div className={'saf bigFontSizePhone'}>Genesis Cat</div>
                                                            <pre className={'textAlignCenter smallerFontSizePhone'}>{`Genesis Cat, also known as Cat Scientist
It is an NFT asset for collectors that can be freely traded on the market. 
It allows users to hatch pet cats.`}</pre>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        (() => {
                                                            return [
                                                                <div
                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={'saf'}>Genesis Cat</div>
                                                                    <div
                                                                        className={'flexCenter childrenSpacingLeftPhone'}>
                                                                        {
                                                                            [
                                                                                ['list1', () => setData({showAttributes: !showAttributes})],
                                                                            ].map(v => <img src={requireDefault(v[0])}
                                                                                            alt=""
                                                                                            onClick={v[1]}
                                                                                            style={{width: '1.5rem'}}/>)
                                                                        }
                                                                    </div>
                                                                </div>,
                                                                <div
                                                                    style={ternaryObject(!showAttributes, {borderColor: '#D8D8D8'})}
                                                                    className={'paddingBottom borderBottomSmallGrayLinePhone positionRelative'}
                                                                >
                                                                    {
                                                                        (() => {
                                                                            const
                                                                                attributeDisplay = checkDataArray(data, 'attributeDisplay')
                                                                            return showAttributes &&
                                                                                <div
                                                                                    style={{padding: '1rem 3rem 3rem 3rem'}}
                                                                                    className={'width100 boxSizingBorderBox positionAbsolute backgroundWhite'}>
                                                                                    {
                                                                                        newScientistAttributes.map((v, i) =>
                                                                                            <div
                                                                                                className={'childrenSpacingTopPhone'}
                                                                                                style={{
                                                                                                    padding: '1rem 1rem 1rem 0',
                                                                                                    borderBottom: '.1rem solid #D8D8D8'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    (() => {
                                                                                                        const aDi = attributeDisplay[i]
                                                                                                        return [
                                                                                                            <div
                                                                                                                onClick={() => setData((() => {
                                                                                                                    const aD = attributeDisplay
                                                                                                                    aD[i] = !aDi
                                                                                                                    return {attributeDisplay: aD}
                                                                                                                })())}
                                                                                                                className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                                                                                <div
                                                                                                                    className={'flexCenter childrenSpacingLeftPhone'}>
                                                                                                                    <img
                                                                                                                        style={{width: '2rem'}}
                                                                                                                        src={requireDefault(v[0])}
                                                                                                                        alt=""/>
                                                                                                                    <div>{v[0]}</div>
                                                                                                                </div>
                                                                                                                <img
                                                                                                                    style={{width: '1rem'}}
                                                                                                                    src={requireDefault('more')}
                                                                                                                    alt=""/>
                                                                                                            </div>,
                                                                                                            aDi && <div
                                                                                                                className={'childrenSpacingTopPhone'}>
                                                                                                                {v[1].map(v =>
                                                                                                                    <div>{v}</div>)}
                                                                                                            </div>
                                                                                                        ]
                                                                                                    })()
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                        })()
                                                                    }
                                                                </div>
                                                            ]
                                                        })()
                                                    }
                                                </div>
                                                <div
                                                    className={'width100 flex alignItemsCenter childrenSpacingLeftBigPhone'}>
                                                    <div style={{borderWidth: '.15rem'}}
                                                         className={'borderSmallBlackLine borderRadius999 paddingPhone flexGrow1'}
                                                    >
                                                        <input value={tokenIdLink}
                                                               className={'width100'}
                                                               placeholder={'Token ID or invitation link'}
                                                               onChange={e => setTokenIdLink(e.target.value)}
                                                        />
                                                    </div>
                                                    <div onClick={searchToken}
                                                         style={{height: '3rem', width: '3rem'}}
                                                         className={'backgroundActiveColor borderRadius999 flexCenter'}>
                                                        <img src={requireDefault('search')} alt=""
                                                             style={{height: '50%'}}/>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    marginTop: 0,
                                                    height: '70vh',
                                                    marginLeft: '-.5rem',
                                                    alignContent: 'start'
                                                }}
                                                     className={'flex flexWrap overflowYScroll'}
                                                     ref={newScientistListScroll}
                                                     onScroll={newScientistListScrollFunc}
                                                >
                                                    {
                                                        scientistList.map(v =>
                                                            <img
                                                                alt=""
                                                                className={'borderRadiusPhone'}
                                                                style={{
                                                                    width: '8.5rem',
                                                                    height: '8.5rem',
                                                                    marginTop: '.5rem',
                                                                    marginRight: '.5rem'
                                                                }}
                                                                onClick={() => console.log(v)}
                                                                // onClick={() => windowReplace(`/genesis/${v}`)}
                                                                src={metadataObj(v).image}
                                                            />
                                                        )
                                                    }
                                                </div>
                                                {/*<div style={{borderColor: '#D8D8D8'}}*/}
                                                {/*     onClick={windowOpenOpenSea}*/}
                                                {/*     className={'borderSmallGrayLine paddingSmallPhone flexCenter saf'}*/}
                                                {/*>*/}
                                                {/*    more*/}
                                                {/*</div>*/}
                                            </div>
                                            {buyDialog}
                                            {loading}
                                        </div>
                                )
                                : <div>
                                    {pageTop}
                                    {tokenId
                                        ? tokenDetail
                                        : <div>
                                            <div className={'backgroundBlack positionRelative zIndex1 overflowHidden'}
                                                 style={{padding: '2.5rem'}}>
                                                <img className={'positionAbsolute zIndex-1'}
                                                     src={requireDefault('wavePoint')}
                                                     alt="" style={{width: '10rem', top: 0, right: '-3rem'}}
                                                />
                                                <div
                                                    className={'flex childrenSpacingLeftBig'}>
                                                    <div className={'childrenSpacingTopBigger'}>
                                                        <img src={requireDefault('title')}
                                                             style={{width: '10rem'}}
                                                             alt=""/>
                                                        <div className={'childrenSpacingTopBig'} style={{
                                                            marginTop: '1rem'
                                                        }}>
                                                            <div className={'saf colorWhite bigFontSize'}>
                                                                Holder community rights:
                                                            </div>
                                                            <div className={'childrenSpacingTop'}>
                                                                {
                                                                    [
                                                                        'Have DC exclusive channel',
                                                                        'Have exclusive voting rights',
                                                                        'Can participate in product design and co-construction',
                                                                        'You can obtain a certain proportion of platform governance token',
                                                                    ].map(
                                                                        v => <div
                                                                            className={'childrenSpacingLeft flex alignItemsCenter'}>
                                                                            <img src={requireDefault('triangle1')}
                                                                                 style={{width: '.3rem'}}
                                                                                 alt=""/>
                                                                            <div className={'colorWhite'}>{v}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'borderRadius999 flex alignItemsCenter childrenSpacingLeft'}
                                                            style={{background: 'linear-gradient(90deg, rgba(186, 255, 0, 0.34) 3.56%, rgba(186, 255, 0, 0.00) 98.86%)'}}
                                                        >
                                                            <img src={requireDefault('remind')}
                                                                 style={{width: '1rem'}}
                                                                 alt=""/>
                                                            <div className={'colorWhite'}>
                                                                This NFT cannot participate in APP pet games, pet games
                                                                requires you to Mint SBT to play
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'childrenSpacingTop flexGrow1'}>
                                                        <div className={'flex'}>
                                                            <div className={'borderRadius paddingBig'}
                                                                 style={{
                                                                     background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.34) 0%, black 100%)'
                                                                 }}
                                                            >
                                                                <img style={{width: '7rem'}}
                                                                     src={requireDefault('Groupp427319681')}
                                                                     alt=""/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'childrenSpacingLeft flex justifyContentFlexEnd'}>
                                                            <img src={requireDefault('Vector2')}
                                                                 style={{height: '2.5rem'}}
                                                                 alt=""/>
                                                            <img src={requireDefault('Group427319680')}
                                                                 style={{width: '5rem'}}
                                                                 alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'flex alignItemsCenter justifyContentSpaceBetween'}
                                                     style={{marginTop: '2rem'}}>
                                                    {
                                                        mainnets.map(v => {
                                                            const isOpen = v[2]
                                                            return <div
                                                                className={`${isOpen ? 'backgroundActiveColor' : ''} borderRadius flexCenter justifyContentSpaceBetween childrenSpacingLeftBigger`}
                                                                style={{
                                                                    width: '10rem',
                                                                    padding: '.5rem 0', ...ternaryObject(!isOpen, {background: '#A1A1A1'})
                                                                }}
                                                            >
                                                                <img src={requireDefault('trapezoidLeft')}
                                                                     alt=""
                                                                     style={{width: '.17rem'}}/>
                                                                <div
                                                                    className={'flexGrow1 flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={''}>
                                                                        <div className={'bigFontSize'}>
                                                                            {v[0]}
                                                                        </div>
                                                                        <div>{v[1]}</div>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => isOpen &&
                                                                            switchEthereumChain(
                                                                                () => toggleIsMintScientist(true),
                                                                                undefined,
                                                                                true,
                                                                            )}
                                                                        className={`borderRadius ${isOpen ? 'cursorPointer backgroundBlack' : ''} saf bigFontSize ${isOpen ? 'activeColor' : 'colorWhite'}`}
                                                                        style={{
                                                                            padding: '.3rem .5rem',
                                                                            ...ternaryObject(!isOpen, {background: '#545454'})
                                                                        }}
                                                                    >
                                                                        {isOpen ? 'continue' : 'Coming Soon'}
                                                                    </div>
                                                                </div>
                                                                <img src={requireDefault('trapezoidRight')}
                                                                     alt=""
                                                                     style={{width: '.17rem'}}/>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div style={{padding: '3rem'}}>
                                                <div
                                                    className={'flexCenter childrenSpacingLeftBigger borderRadius overflowVisible'}
                                                    style={{background: 'linear-gradient(180deg, #BAFF00 0%, #FFFFFF 100%)'}}
                                                >
                                                    <img style={{
                                                        width: '9rem',
                                                        transform: 'translateY(-1.5rem)'
                                                    }}
                                                         src={requireDefault('newGenesisCat')} alt=""/>
                                                    <div className={'childrenSpacingTopBig'}>
                                                        <div className={'saf'}
                                                             style={{fontSize: '1rem'}}>Genesis
                                                            Cat
                                                        </div>
                                                        <pre className={'bigFontSize'}
                                                             style={{lineHeight: 1.5}}>{`Genesis Cat, also known as Cat Scientist
It is an NFT asset for collectors that can be freely traded on the market. 
It allows users to hatch pet cats.`}</pre>
                                                    </div>
                                                </div>
                                                <div className={'width100 flex'} style={{marginTop: '2rem'}}>
                                                    {newAttributesContent}
                                                    <div className={'flexGrow1'}
                                                         style={{paddingLeft: '1.5rem'}}>
                                                        <div
                                                            className={'flexCenter justifyContentFlexEnd childrenSpacingLeftBig'}
                                                            style={{...paddingBorder, height: '.6rem'}}>
                                                            <div
                                                                className={'flex alignItemsCenter childrenSpacingLeftBigger'}>
                                                                <div
                                                                    className={'borderSmallBlackLine borderRadius999 padding'}
                                                                    style={{borderWidth: '.07rem'}}
                                                                >
                                                                    <input value={tokenIdLink}
                                                                           onChange={e => setTokenIdLink(e.target.value)}
                                                                           placeholder={'Token ID or invitation link'}
                                                                           type="text" name="" id=""
                                                                           style={{width: '4.5rem'}}
                                                                    />
                                                                </div>
                                                                <div onClick={searchToken}
                                                                     className={'padding backgroundActiveColor borderRadius999 cursorPointer'}>
                                                                    <img src={requireDefault('search')}
                                                                         alt=""
                                                                         style={{width: '.4rem'}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{paddingTop: '1rem'}}>
                                                            <div style={{
                                                                paddingBottom: '.4rem',
                                                                height: '15rem',
                                                                marginLeft: '-.2rem',
                                                                alignContent: 'start'
                                                            }}
                                                                 className={'flex flexWrap overflowYScroll'}
                                                                 ref={newScientistListScroll}
                                                                 onScroll={newScientistListScrollFunc}
                                                            >
                                                                {
                                                                    scientistList.map(v =>
                                                                        <img style={{
                                                                            width: '3.5rem',
                                                                            height: '3.5rem',
                                                                            marginTop: '.2rem',
                                                                            marginLeft: '.2rem',
                                                                        }}
                                                                             className={'borderRadius cursorPointer'}
                                                                             onClick={() => console.log(v)}
                                                                            // onClick={() => windowReplace(`/genesis/${v}`)}
                                                                             src={metadataObj(v).image}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        {/*<div className={'flexCenter saf cursorPointer'}*/}
                                                        {/*     onClick={windowOpenOpenSea}*/}
                                                        {/*     style={{*/}
                                                        {/*         border: '.05rem solid #D8D8D8',*/}
                                                        {/*         marginTop: '.5rem',*/}
                                                        {/*         padding: '.2rem 0'*/}
                                                        {/*     }}>*/}
                                                        {/*    MORE*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {pageFoot}
                                    {buyDialog}
                                    {loading}
                                </div>
                        })(),
                    'marketplace/*': (() => {
                        return isPhoneClientWidth
                            ? pageTopFootPhone(
                                (tokenId
                                        ? tokenDetail
                                        : <div>
                                            <div style={{padding: '4rem 1rem 1rem 1rem'}}
                                                 className={'backgroundBlack flex alignItemsCenter childrenSpacingLeftBigger overflowXScroll '}>
                                                <img className={'positionAbsolute'} src={requireDefault('halfWavePoint')}
                                                     alt=""
                                                     style={{height: '10rem', left: '-7rem'}}/>
                                                {
                                                    nftContracts.map((v, i) => <div
                                                            onClick={() => setNFTContractIndex(i)}
                                                            style={{width: '9.5rem', height: '3.5rem', borderRadius: '.3rem'}}
                                                            className={`padding boxSizingBorderBox ${nftContractAddress === v.address ? 'linearGreen' : 'backgroundWhite'} 
                                                 childrenSpacingTop flexColumn justifyContentCenter flexShrink0 cursorPointer zIndex1`}>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                <img src={requireDefault(v.icon)} alt=""
                                                                     style={{width: '1.5rem'}}/>
                                                                <div className={'flexGrow1 childrenSpacingTop'}>
                                                                    <div style={{fontSize: '.4rem'}}
                                                                         className={'textOverflowEllipsis'}>{v.name}</div>
                                                                    <div style={{fontSize: '.4rem'}}>{leaveOut(v.address)}</div>
                                                                </div>
                                                            </div>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                {
                                                                    [
                                                                        ['Items', v.totalSupply ? v.totalSupply : '0'],
                                                                        ['Royalty', `${checkDataString(v, 'royalty')}%`],
                                                                        [v.chain.name],
                                                                    ].map((v, i) => <div
                                                                        className={'flexCenter childrenSpacingLeft'}>
                                                                        {i !== 0 && <div className={'colorGray'}
                                                                                         style={{fontSize: '.4rem'}}>|</div>}
                                                                        <div className={'flexCenter childrenSpacingLeft'}>
                                                                            <div style={{fontSize: '.4rem'}}>{v[0]}</div>
                                                                            {v[1] && <div style={{fontSize: '.4rem'}}
                                                                                          className={'fontWeightBolder'}>{v[1]}</div>}
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div style={{padding: '1rem 1rem'}}
                                                 className={'backgroundWhite childrenSpacingTopBigger'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'paddingBigger'}
                                                         style={{
                                                             width: '10rem',
                                                             background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)'
                                                         }}
                                                    >
                                                        <div className={'saf lineHeight1'} style={{fontSize: '.8rem'}}>
                                                            Overall Stats
                                                        </div>
                                                    </div>
                                                    <div className={'flexCenter childrenSpacingLeftBig'}>
                                                        {
                                                            [
                                                                ['backgroundActiveColor', 'My NFTs', 'myNFTs'],
                                                                ['backgroundWhite', 'My Bids', 'myBids'],
                                                            ].map(v => <div onClick={() => windowReplace(v[2])}
                                                                            className={`borderRadius999 ${v[0]} paddingBig flexCenter childrenSpacingLeft cursorPointer`}
                                                                            style={{
                                                                                border: 'solid .1rem',
                                                                                paddingLeft: '.5rem',
                                                                                paddingRight: '.5rem'
                                                                            }}
                                                            >
                                                                <div style={{fontSize: '.4rem'}}>{v[1]}</div>
                                                                <img src={requireDefault('toRightBlack')} alt=""
                                                                     style={{width: '.4rem'}}/>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'flex justifyContentSpaceBetween'}>
                                                    {
                                                        [
                                                            [`${totalVolume} ETH`, 'total volume'],
                                                            [`${floorPrice} ETH`, 'floor price'],
                                                            [owners, 'owners'],
                                                            [uniqueOwners, 'unique owners'],
                                                        ].map(v => <div className={'childrenSpacingTop'}>
                                                            <div className={'saf lineHeight1'}
                                                                 style={{fontSize: '.7rem'}}>{v[0]}</div>
                                                            <div className={'smallerFontSizePhone'}>{v[1]}</div>
                                                        </div>)
                                                    }
                                                    <img src={requireDefault('page')} alt="" style={{width: '3rem'}}/>
                                                </div>
                                                <div>
                                                    {attributesContent}
                                                    <div>
                                                        <div style={{paddingTop: '1rem', height: '70vh'}}>
                                                            {marketplaceNFTs()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loading}
                                        </div>
                                ))
                            : <div>
                                {pageTop}
                                {
                                    tokenId
                                        ? tokenDetail
                                        : <div>
                                            <div style={{padding: '3rem 2rem 2rem 2rem'}}
                                                 className={'backgroundBlack flex alignItemsCenter childrenSpacingLeftBigger overflowXScroll '}>
                                                <img className={'positionAbsolute'} src={requireDefault('halfWavePoint')} alt=""
                                                     style={{height: '10rem', left: '-7rem'}}/>
                                                {
                                                    nftContracts.map((v, i) => <div
                                                            onClick={() => setNFTContractIndex(i)}
                                                            style={{width: '9.5rem', height: '3.5rem', borderRadius: '.3rem'}}
                                                            className={`paddingBigger boxSizingBorderBox ${nftContractName === v.name ? 'linearGreen' : 'backgroundWhite'} 
                                                childrenSpacingTopBig flexColumn justifyContentCenter flexShrink0 cursorPointer zIndex1`}>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftBigger'}>
                                                                <img src={requireDefault(v.icon)} alt=""
                                                                     style={{width: '1.5rem'}}/>
                                                                <div className={'childrenSpacingTop flexGrow1'}>
                                                                    <div
                                                                        className={'biggerFontSize textOverflowEllipsis'}>{v.name}</div>
                                                                    <div>{leaveOut(v.address)}</div>
                                                                </div>
                                                            </div>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                                {
                                                                    [
                                                                        ['Items', v.totalSupply ? v.totalSupply : '0'],
                                                                        ['Royalty', `${checkDataString(v, 'royalty')}%`],
                                                                        [v.chain.name],
                                                                    ].map((v, i) => <div
                                                                        className={'flexCenter childrenSpacingLeftBig'}>
                                                                        {i !== 0 && <div className={'colorGray'}>|</div>}
                                                                        <div className={'flexCenter childrenSpacingLeft'}>
                                                                            <div>{v[0]}</div>
                                                                            {v[1] &&
                                                                                <div className={'fontWeightBolder'}>{v[1]}</div>}
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div style={{padding: '1rem 2rem'}}
                                                 className={'backgroundWhite childrenSpacingTopBigger'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'paddingBigger'}
                                                         style={{
                                                             width: '10rem',
                                                             background: 'linear-gradient(90deg, #BAFF00 0%, rgba(186,255,0,0.04) 100%)'
                                                         }}
                                                    >
                                                        <div className={'saf lineHeight1'} style={{fontSize: '.8rem'}}>
                                                            Overall Stats
                                                        </div>
                                                    </div>
                                                    <div className={'flexCenter childrenSpacingLeftBig'}>
                                                        {
                                                            [
                                                                ['backgroundActiveColor', 'My NFTs', 'myNFTs'],
                                                                ['backgroundWhite', 'My Bids', 'myBids'],
                                                            ].map(v => <div onClick={() => windowReplace(v[2])}
                                                                            className={`borderRadius999 ${v[0]} paddingBig flexCenter childrenSpacingLeft cursorPointer`}
                                                                            style={{
                                                                                border: 'solid .07rem',
                                                                                paddingLeft: '.5rem',
                                                                                paddingRight: '.5rem'
                                                                            }}
                                                            >
                                                                <div>{v[1]}</div>
                                                                <img src={requireDefault('toRightBlack')} alt=""
                                                                     style={{width: '.4rem'}}/>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'flex justifyContentSpaceBetween'}>
                                                    {
                                                        [
                                                            [`${totalVolume} ETH`, 'total volume'],
                                                            [`${floorPrice} ETH`, 'floor price'],
                                                            [owners, 'owners'],
                                                            [uniqueOwners, 'unique owners'],
                                                        ].map(v => <div className={'childrenSpacingTop'}>
                                                            <div className={'saf lineHeight1'}
                                                                 style={{fontSize: '.7rem'}}>{v[0]}</div>
                                                            <div>{v[1]}</div>
                                                        </div>)
                                                    }
                                                    <img src={requireDefault('page')} alt="" style={{width: '3rem'}}/>
                                                </div>
                                                <div className={'flex'}>
                                                    {attributesContent}
                                                    <div className={'flexGrow1'} style={{paddingLeft: '1.5rem'}}>
                                                        <div
                                                            className={'flexCenter justifyContentFlexEnd childrenSpacingLeftBig'}
                                                            style={{...paddingBorder}}>
                                                        </div>
                                                        <div style={{paddingTop: '1rem'}}>
                                                            {marketplaceNFTs()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loading}
                                        </div>
                                }
                                {pageFoot}
                            </div>
                    })(),
                    myNFTs: myNFTsPage(true, 'My NFTs'),
                    myBids: myNFTsPage(false, 'My Bids'),
                    privacy: (() => {
                        const text = <pre className={'colorWhite'}>{privacy}</pre>
                        return isPhoneClientWidth
                            ? pageTopFootPhone(
                                <div>
                                    <div className={'backgroundBlack'} style={{padding: '4rem 2rem 2rem 2rem'}}>
                                        {text}
                                    </div>
                                </div>
                            )
                            : <div>
                                {pageTop}
                                <div className={'backgroundBlack'} style={{padding: '2rem'}}>
                                    {text}
                                </div>
                                {pageFoot}
                            </div>;
                    })(),
                    cat: (() => {
                        const cats = checkDataArray(data, 'cats'),
                            catIndex = checkData0(data, 'catIndex'),
                            cat = checkData([cats, catIndex], checkDataObject(cats, 0)),
                            [name, image] = checkDataString(cat, ['name'], ['image']),
                            traits = (() => {
                                const arr = []
                                checkDataArray(cat, 'attributes').map(v => {
                                    const {trait_type} = v;
                                    [
                                        'Fur', 'Eyes', 'Nose', 'Mouth', 'Whiskers', 'Display'
                                    ].includes(trait_type) && arr.push([trait_type, v.value])
                                })
                                return arr
                            })(),
                            setCatIndex = (catIndex = 0) => setData({catIndex})
                        return isPhoneClientWidth
                            ? pageTopFootPhone(<div className={'backgroundBlack'}>
                                    <div className={'paddingTopLeftRightBigPhone childrenSpacingTopPhone'} style={{
                                        paddingTop: '3.5rem', paddingBottom: '4rem',
                                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 90%, rgba(186, 255, 0, .2) 100%)',
                                    }}>
                                        <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                            <div className={'colorWhite saf smallFontSizePhone'}>
                                                CAT
                                            </div>
                                            <div className={'colorWhite smallFontSizePhone'}>{'>'}</div>
                                            <div className={'activeColor saf smallFontSizePhone'}>
                                                CAT INFO
                                            </div>
                                        </div>
                                        <div className={'childrenSpacingTopBigPhone'}>
                                            <div
                                                className={'saf biggerFontSize colorWhite flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                <div className={'saf'} style={{fontSize: '2rem'}}>{name}</div>
                                            </div>
                                            <div className={'childrenSpacingTopBigPhone'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div
                                                        className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                        <div style={{width: '2.5rem'}}
                                                             className={'flexCenter flexWrap justifyContentSpaceBetween childrenSpacingTop'}>
                                                            <img className={'borderRadius999'} style={{width: '2.5rem'}}
                                                                 src={image} alt=""/>
                                                        </div>
                                                        <div className={'flexColumn childrenSpacingTop'}>
                                                            {
                                                                [
                                                                    'Owner',
                                                                    addressLeaveOut
                                                                ].map(v => <div
                                                                    className={'colorWhite smallFontSizePhone'}>{v}</div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'childrenSpacingTopBigPhone'}>
                                                <div style={{maxHeight: '30.55rem', marginLeft: '-.5rem'}}
                                                     className={'flexCenter flexWrap overflowYScroll'}>
                                                    {
                                                        cats.map((v, i) => <img onClick={() => setCatIndex(i)}
                                                                                className={`borderRadiusPhone cursorPointer 
                                                                                boxSizingBorderBox ${v.name === name ? 'borderActiveColor' : ''}`}
                                                                                style={{
                                                                                    width: '31%',
                                                                                    borderWidth: '.3rem',
                                                                                    margin: '0 0 .5rem .5rem'
                                                                                }}
                                                                                src={v.image} alt=""/>)
                                                    }
                                                </div>
                                                <div className={'childrenSpacingTopBigPhone'}>
                                                    {
                                                        [
                                                            [
                                                                'TRAITS',
                                                                <div
                                                                    className={'flexCenter justifyContentSpaceBetween flexWrap'}>
                                                                    {
                                                                        traits.map(v => <div
                                                                            className={'flexCenter justifyContentSpaceBetween marginTopPhone childrenSpacingLeftSmall childrenFlexGrow1'}
                                                                            style={{
                                                                                width: `${(
                                                                                    () => {
                                                                                        const {length} = traits
                                                                                        return length === 1 ? 100 : 43
                                                                                    }
                                                                                )()}%`,
                                                                                padding: '.7rem',
                                                                                borderRadius: '.3rem',
                                                                                border: 'solid .07rem rgba(255,255,255,.3)'
                                                                            }}
                                                                        >
                                                                            {
                                                                                v.map((v, i) => <div
                                                                                    className={`colorWhite smallFontSizePhone textOverflowEllipsis  ${i ? 'textAlignRight' : ''}`}>{v}</div>)
                                                                            }
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            ],
                                                        ].map(v => <div
                                                                className={`paddingPhone ${v[2]}`}
                                                                style={{
                                                                    border: 'solid .07rem rgba(255,255,255,.3)',
                                                                    borderRadius: '.3rem',
                                                                }}
                                                            >
                                                                <div className={'colorWhite saf paddingBottomPhone'}
                                                                     style={{borderBottom: 'solid .07rem rgba(255,255,255,.1)'}}
                                                                >{v[0]}
                                                                </div>
                                                                {v[1]}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading}
                                </div>
                            )
                            : <div>
                                {pageTop}
                                <div className={'backgroundBlack'}>
                                    <div className={'pagePaddingLeftRight boxSizingBorderBox flexCenter'}
                                         style={{
                                             'background': 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 50%, rgba(186, 255, 0, .2) 100%)',
                                             paddingTop: '1.5rem', paddingBottom: '1rem',
                                             minHeight: '100vh'
                                         }}>
                                        <div className={'width100'}>
                                            <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                <div className={'colorWhite saf'}>
                                                    CAT
                                                </div>
                                                <div className={'colorWhite'}>{'>'}</div>
                                                <div className={'activeColor saf'}>
                                                    CAT INFO
                                                </div>
                                            </div>
                                            <div className={'childrenSpacingTopBig'}
                                                 style={{marginTop: '.5rem'}}>
                                                <div
                                                    className={'saf biggerFontSize colorWhite flex alignItemsCenter childrenSpacingLeftBig'}>
                                                    <div className={'saf'}
                                                         style={{fontSize: '.8rem'}}>{name}</div>
                                                </div>
                                                <div style={{width: '8rem'}}
                                                     className={'childrenSpacingTopBig'}>
                                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                                        <div
                                                            className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                            <img className={'borderRadius999'}
                                                                 style={{width: '1rem'}}
                                                                 src={image}
                                                                 alt=""/>
                                                            <div className={'flexColumn childrenSpacingTop'}>
                                                                {
                                                                    [
                                                                        'Owner',
                                                                        addressLeaveOut
                                                                    ].map(v => <div
                                                                        className={'colorWhite'}>{v}</div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'flex childrenSpacingLeftBig'}>
                                                    <div style={{width: '8rem', maxHeight: '10rem'}}
                                                         className={'flexCenter flexWrap justifyContentSpaceBetween overflowYScroll'}>
                                                        {
                                                            cats.map((v, i) => <img onClick={() => setCatIndex(i)}
                                                                                    className={`borderRadius marginBottomBig cursorPointer 
                                                                                boxSizingBorderBox ${v.name === name ? 'borderActiveColor' : ''}`}
                                                                                    style={{
                                                                                        width: '48%',
                                                                                        borderWidth: '.1rem'
                                                                                    }}
                                                                                    src={v.image} alt=""/>)
                                                        }
                                                    </div>
                                                    <div
                                                        className={'flexGrow1 childrenSpacingTopBig flexColumn'}>
                                                        {
                                                            [
                                                                [
                                                                    'TRAITS',
                                                                    <div
                                                                        className={'flexCenter justifyContentSpaceBetween flexWrap'}>
                                                                        {
                                                                            traits.map(v => <div
                                                                                className={'flexCenter justifyContentSpaceBetween borderRadius marginTop childrenSpacingLeftSmall childrenFlexGrow1'}
                                                                                style={{
                                                                                    width: `${(
                                                                                        () => {
                                                                                            const {length} = traits
                                                                                            return length === 1 ? 100 : (length === 2 ? 42.5 : 26)
                                                                                        }
                                                                                    )()}%`,
                                                                                    padding: '.2rem .5rem',
                                                                                    border: 'solid .03rem rgba(255,255,255,.3)'
                                                                                }}
                                                                            >
                                                                                {
                                                                                    v.map((v, i) => <div
                                                                                        className={`colorWhite bigFontSize textOverflowEllipsis ${i ? 'textAlignRight' : ''}`}>{v}</div>)
                                                                                }
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                ],
                                                            ].map(v => <div
                                                                    className={`borderRadius paddingBig flexColumn ${v[2]}`}
                                                                    style={{border: 'solid .03rem rgba(255,255,255,.3)'}}
                                                                >
                                                                    <div
                                                                        className={'colorWhite saf paddingBottomBig'}
                                                                        style={{borderBottom: 'solid .03rem rgba(255,255,255,.3)'}}
                                                                    >{v[0]}
                                                                    </div>
                                                                    {v[1]}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading}
                                </div>
                                {pageFoot}
                            </div>
                    })(),
                    'landing/*': (() => {
                        const isBind = checkDataBool(data, 'isBind'),
                            toggleIsLandingLogin = isLandingLogin => setData({isLandingLogin}),
                            particleAddress = checkData([data, 'particleAddress']),
                            particleToken = checkData([data, 'particleToken']),
                            isLogin = token() || (isParticle && isNotNullOrEmpty(particleToken)),
                            isLandingLogin = checkDataBool(data, 'isLandingLogin') && !isLogin,
                            landingAddress = leaveOut(isNotNullOrEmpty(particleAddress) ? particleAddress : address),
                            supportAuthTypes = [
                                ['phone', 'Phone', 'particlePhone'],
                                ['email', 'Email', 'particleEmail'],
                                ['google', 'Google', 'particleGoogle'],
                            ],
                            landingWrongNetwork = chainIdIsNotNull && wrongNetwork && !isParticle,
                            particleRequestApi = (url, data, method, headers = {}, token) => {
                                const t = isNotNullOrEmpty(token) ? token : particleToken
                                return axios(
                                    {
                                        method,
                                        url: `${chainValue.api}${url}`,
                                        [method ? 'data' : 'params']: data,
                                        headers: {
                                            ...headers,
                                            ...ternaryObject(t, {'Authorization': `Bearer ${t}`})
                                        },
                                    },
                                ).then(r => {
                                    const d = checkDataObject(r, 'data'),
                                        isSuccess = d.code === '1'
                                    if (!isSuccess) {
                                        toast(d.msg)
                                        toggleShowLoading()
                                    }
                                    return isSuccess ? checkDataObject(d, 'data') : null
                                }).catch(e => {
                                    toggleShowLoading()
                                    toast('Unknown error')
                                    console.error(`------------------------------\n`, e, `------------------------------\n`)
                                })
                            },
                            landingRequestApi = isParticle ? particleRequestApi : requestApi,
                            particleLogin = preferredAuthType => particle.auth.login({
                                preferredAuthType,
                                supportAuthTypes: supportAuthTypes.map(v => v[0]).join(','),
                                ...ternaryObject(preferredAuthType !== 'google', {account: 'none'}),
                            }).then(() => particle.evm.getAddress().then(address => particleRequestApi('sys/code', {address}).then(r => {
                                const code = checkData([r, 'code'])
                                particle.evm.personalSign(`0x${Buffer.from(code).toString('hex')}`).then(sign => particleRequestApi(
                                    'sys/login',
                                    {
                                        code,
                                        sign,
                                        address,
                                        loginType: 'WEB Particle',
                                        network: chainValue.loginNetwork,
                                    },
                                    'post'
                                ).then(r => {
                                        const token = checkData([r, 'token'])
                                        if (token) {
                                            setData({
                                                isParticle: true,
                                                particleToken: token,
                                                particleAddress: address
                                            })
                                            particleRequestApi('user/curInvitation', undefined, undefined, undefined, token).then(r => {
                                                const otherInvCode = checkData([r, 'otherInvCode'])
                                                setData({
                                                    otherInvCode,
                                                    isBind: isNotNullOrEmpty(otherInvCode)
                                                })
                                            })
                                        }
                                    }
                                ))
                            })))
                        return <div>
                            {
                                isLandingLogin
                                    ? <div
                                        className={'overflowYScroll paddingBigPhone boxSizingBorderBox childrenSpacingTopPhone'}
                                        style={{
                                            height: '100vh',
                                            width: '100vw',
                                            background: 'linear-gradient(180deg, #BAFF00 0%, #F1F5F8 72.45%)'
                                        }}

                                    >
                                        <div className={'saf biggerFontSizePhone'} style={{marginTop: '3rem'}}>
                                            Login to PETGPT
                                        </div>
                                        {
                                            [
                                                ['Please select below methods to log in',
                                                    supportAuthTypes
                                                ],
                                                [
                                                    'Or connect with mobile wallet',
                                                    [
                                                        ['walletConnect', 'WalletConnect', 'particleWalletConnect',
                                                            () => {
                                                                setData({isParticle: false})
                                                                switchEthereumChain(
                                                                    () => loginThenFunc(() => requestApi('user/curInvitation').then(r => {
                                                                        const otherInvCode = checkData([r, 'otherInvCode'])
                                                                        setData({
                                                                            otherInvCode,
                                                                            isBind: isNotNullOrEmpty(otherInvCode)
                                                                        })
                                                                    })),
                                                                    undefined,
                                                                    true
                                                                )
                                                            }
                                                        ],
                                                    ]
                                                ]
                                            ].map((v, i) => <div className={'childrenSpacingTopPhone'}>
                                                {i !== 0 && <div style={{
                                                    borderBottom: 'dashed .01rem black',
                                                    borderStyle: 'dashed',
                                                    margin: '2rem 0'
                                                }}/>}
                                                <div className={!isPhoneClientWidth ? 'biggerFontSize' : ''}>{v[0]}</div>
                                                {
                                                    v[1].map(v => <div style={{borderRadius: '1.5rem'}}
                                                                       onClick={() => checkData([v, 3], () => particleLogin(v[0]))()}
                                                                       className={'backgroundWhite paddingBigPhone flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                        <div className={'flexCenter childrenSpacingLeftPhone'}>
                                                            <img src={requireDefault(v[2])} alt="" style={{width: '2.5rem'}}/>
                                                            <div
                                                                className={!isPhoneClientWidth ? 'biggerFontSize' : ''}>{v[1]}</div>
                                                        </div>
                                                        <img src={requireDefault('particleToRight')} alt=""
                                                             style={{width: '2rem'}}/>
                                                    </div>)
                                                }
                                            </div>)
                                        }
                                    </div>
                                    : <div style={{height: '100vh', width: '100vw'}}
                                           className={'backgroundBlack overflowYScroll paddingBigPhone childrenSpacingTopBigPhone boxSizingBorderBox positionRelative zIndex1'}>
                                        <img onClick={replaceHome} src={requireDefault('logo')}
                                             style={{height: '1.5rem'}} alt=""/>
                                        <div className={'flex alignItemsCenter justifyContentSpaceBetween'}>
                                            <img src={requireDefault('WelcomeToPETGPT')} alt="" style={{width: '17rem'}}/>
                                            <img src={requireDefault('Union')} alt="" style={{width: '6rem'}}/>
                                        </div>
                                        <div className={'childrenSpacingTopPhone'}>
                                            {
                                                [
                                                    [
                                                        !isBind,
                                                        `Register an account 
and bind an invitation code`,
                                                        [
                                                            <div
                                                                className={'borderRadiusPhone paddingPhone flexCenter childrenSpacingLeftPhone'}
                                                                style={{background: 'rgba(186, 255, 0, 0.1)'}}>
                                                                <div
                                                                    className={`saf colorWhite ${!isPhoneClientWidth ? 'biggerFontSize' : ''}`}
                                                                    style={{transform: 'translateY(0.2rem)'}}>
                                                                    invitation code :
                                                                </div>
                                                                <div className={'activeColor bigFontSizePhone'}>{tokenId}</div>
                                                            </div>,
                                                            <div
                                                                className={'flexCenter childrenSpacingLeftBigPhone childrenFlexGrow1'}>
                                                                {
                                                                    [
                                                                        [landingWrongNetwork ? 'Wrong network' : (isLogin ? landingAddress : 'Sign in'),
                                                                            true,
                                                                            () => isLogin ? (!isParticle && connect()) : toggleIsLandingLogin(true)
                                                                        ],
                                                                        // particleRequestApi
                                                                        ['BiND', isLogin && !isBind, () => landingRequestApi('user/setInvCode', {invitationCode: tokenId}, 'post').then(r => {
                                                                            r && toast('Successful')
                                                                            landingRequestApi('user/curInvitation').then(r => {
                                                                                const otherInvCode = checkData([r, 'otherInvCode'])
                                                                                setData({
                                                                                    otherInvCode,
                                                                                    isBind: isNotNullOrEmpty(otherInvCode)
                                                                                })
                                                                            })
                                                                        })],
                                                                    ].map((v, i) => {
                                                                        const isTrue = v[1]
                                                                        return <div onClick={() => isTrue && v[2]()}
                                                                                    className={`borderRadiusPhone cursorPointer flexCenter ${isTrue ? '' : 'colorGray'} paddingTopBottomBigPhone saf ${!isPhoneClientWidth ? 'biggerFontSize' : ''}`}
                                                                                    style={{
                                                                                        background: (i === 0 && landingWrongNetwork) ? 'red' : (isTrue ? '#BAFF00' : 'rgba(38, 41, 48, 1)')
                                                                                    }}
                                                                        >
                                                                            {v[0]}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>,
                                                            <div className={'activeColor smallFontSizePhone flexCenter'}
                                                                 style={{opacity: isBind ? 1 : 0, marginTop: '.5rem'}}
                                                            >
                                                                Invitation code already
                                                                bound: {checkDataString(data, 'otherInvCode')}
                                                            </div>
                                                        ]
                                                    ],
                                                    [
                                                        isBind,
                                                        `Download the APP
and mint your own unique pet`,
                                                        [
                                                            <div
                                                                className={'flexCenter childrenSpacingLeftBigPhone childrenFlexGrow1'}>
                                                                {
                                                                    appDownload.map(v => <img className={'cursorPointer'}
                                                                                              src={requireDefault(`${v[0]}Landing`)}
                                                                                              onClick={v[1]} alt=""/>)
                                                                }
                                                            </div>
                                                        ]
                                                    ]
                                                ].map((v, i) => {
                                                    const isTure = v[0]
                                                    return <div
                                                        className={'borderActiveColorPhone boxSizingBorderBox paddingPhone borderRadiusPhone childrenSpacingTopBigPhone'}
                                                        style={{borderColor: isTure ? '#BAFF00' : 'rgba(186, 255, 0, 0.3)'}}
                                                    >
                                                        <div className={'flex childrenSpacingLeftPhone'}>
                                                            <div style={{width: '1.5rem', height: '1.5rem'}}
                                                                 className={`borderActiveColorPhone ${isTure ? 'backgroundActiveColor' : 'activeColor'} boxSizingBorderBox borderRadius999 flexCenter saf smallFontSizePhone`}>
                                                                {i + 1}
                                                            </div>
                                                            <pre
                                                                className={`colorWhite ${!isPhoneClientWidth ? 'biggerFontSize' : ''}`}
                                                                style={{lineHeight: '1.3'}}>{v[1]}</pre>
                                                        </div>
                                                        {v[2]}
                                                    </div>
                                                })

                                            }
                                        </div>
                                        {isPhoneClientWidth && <div className={'positionFixed flexCenter paddingPhone zIndex-1'}
                                                                    style={{left: 0, right: 0, bottom: '1rem'}}
                                        >
                                            <img src={requireDefault('PETGPT.AI')} alt="" style={{width: '7rem'}}/>
                                        </div>}
                                        <div className={'positionAbsolute overflowHidden zIndex-1'}
                                             style={{top: 0, bottom: 0, left: 0, right: 0}}
                                        >
                                            <div className={'positionAbsolute width100 opacity'}
                                                 style={{top: 0, right: '-12rem'}}
                                            >
                                                <img src={requireDefault('wavePoint2')} alt=""
                                                     style={{width: '30rem'}}/>
                                            </div>
                                            <div className={'positionAbsolute width100'}
                                                 style={{bottom: '-7rem', left: '-7rem'}}
                                            >
                                                <img src={requireDefault('wavePoint')} alt=""
                                                     style={{width: '20rem'}}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {loading}
                            {isWeChat && <div className={'positionFixed zIndex999'}
                                              style={{top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.9)'}}>
                                <div className={'flex justifyContentFlexEnd'}>
                                    <img src={requireDefault('rightArrow')} alt=""
                                         style={{width: '15rem', margin: '.5rem 2.5rem 0 0'}}/>
                                </div>
                                <div
                                    className={`activeColor paddingPhone ${!isPhoneClientWidth ? 'biggerFontSize' : ''}`}>Please
                                    open it in your browser
                                </div>
                            </div>}
                        </div>
                    })()
                },
                pathNames = Object.keys(paths),
                [firstPath] = pathNames,
                useRefEffect = (fn = () => {
                }, inputs = []) => {
                    const didMountRef = useRef(false)
                    useEffect(() => {
                        fn(didMountRef.current)
                        if (!didMountRef.current) didMountRef.current = true
                    }, inputs);
                },
                chainIdEQNftContractChainId = chainId === nftContractChain.id;
            [
                [() => {
                    setData({init: true})
                    !chainIdIsNotNull && getOwnerIncomeList()
                    if (isGenesis) {
                        console.log('aaaa');
                        rC('MAX_SUPPLY').catch(() => {
                        }).then(MAX_SUPPLY => setData({MAX_SUPPLY}))
                        rC('price').catch(() => {
                        }).then(price => setData({newScientistPrice: numberETH(price)}))
                        rC('totalMinted').catch(() => {
                        }).then(totalMinted => {
                            totalMinted = Number(totalMinted)
                            setData({newScientistListTotal: totalMinted})
                            let start = 1, allTokenAttributes = []
                            const getTokenAttributes = () => {
                                if (start <= totalMinted) {
                                    let end = start + totalMinted - 1
                                    end = totalMinted < end ? totalMinted : end
                                    rC('getTokenAttributes', start, end).catch(() => {
                                    }).then(tokenAttributes => {
                                        allTokenAttributes = [...allTokenAttributes, ...tokenAttributes.map(v => v.join(','))]
                                        start = end + 1
                                        getTokenAttributes()
                                    })
                                } else {
                                    console.log(allTokenAttributes, '===============');
                                    setData({allTokenAttributes})
                                }
                            }
                            getTokenAttributes()
                        })
                    }
                }],
                [
                    () => {
                        if (isGenesis && isNotNull(newScientistListTotal)) {
                            getMetaJsonList(tokenId ? [tokenId] : scientistList, checkDataString(chainValue, 'baseURI'))
                                .then(() => toggleShowLoading())
                                .catch(e => {
                                    toggleShowLoading()
                                    toast(e.toString())
                                })
                        }
                    },
                    [newScientistListTotal]
                ],
                [
                    () => {
                        if (isStart && needSupply) {
                            const getSupply = setInterval(
                                async () => {
                                    const [
                                        totalSupply,
                                        soldSupply,
                                    ] = await Promise.all([
                                        'MAX_SUPPLY',
                                        'totalSupply',
                                    ].map(async functionName => await rC(functionName).catch(() => {
                                    })))
                                    isInit && setData({isInit: false})
                                    console.log(`totalSupply:${totalSupply}`, `soldSupply:${soldSupply}`)
                                    if (isNotNull(totalSupply) && isNotNull(soldSupply)) {
                                        // clearInterval(getSupply)
                                        setData({totalSupply, soldSupply})
                                    }
                                },
                                1000
                            )
                            setData({getSupply})
                        }
                        if (chainIdIsNotNull && !isMarketplacePages) {
                            switchEthereumChain()
                            getOwnerIncomeList(true)
                        }
                    },
                    [chainId]
                ],
                [
                    () => {
                        if ((isMarketplace || isMyBids) && (!isNotNull(wCMarketSuccess) || wCMarketSuccess)) {
                            offerBidPage = 1
                            setData({
                                owners: undefined,
                                offerBids: undefined,
                                floorPrice: undefined,
                                totalVolume: undefined,
                                uniqueOwners: undefined,
                                marketInfoTotal: undefined,
                            })
                            if (!chainIdEQNftContractChainId) {
                                switchEthereumChain(undefined, undefined, true, nftContractChain)
                            } else {
                                !showLoading && toggleShowLoading(true)
                                const nC = defaultNFTContracts,
                                    isMyBidsOrTokenId = isMyBids || tokenId,
                                    nCI = defaultNFTContracts[nftContractIndex] ? nftContractIndex : 0,
                                    nftContracts = isMyBidsOrTokenId ? [defaultNFTContracts[nCI]] : defaultNFTContracts
                                Promise.all(nftContracts.map((v, i) => {
                                    const rCMP = (functionName, ...args) => new v.eth.Contract(v.abi, v.address)
                                        .methods[functionName](...args).call()
                                    return rCMP('totalSupply').then(totalSupply => (v.is721A ? rCMP('MAX_SUPPLY') : new Promise(resolve => resolve(totalSupply))).then(
                                            async MAX_SUPPLY => nC[isMyBidsOrTokenId ? nCI : i] = {
                                                ...nftContracts[i],
                                                MAX_SUPPLY,
                                                totalSupply: Number(totalSupply),
                                                royalty: await (async () => {
                                                    const price = 10000
                                                    return npDivide(npTimes(Number((await rCMP('royaltyInfo', tokenId || 0, price))[1]), 100), price)
                                                })()
                                            }
                                        )
                                    )
                                })).then(() => {
                                    setData({nftContracts: nC})
                                    const nftContract = checkData([nC, nCI], checkDataObject(nC, 0)),
                                        {address: nftContractAddress, axiosOfferBids} = nftContract;
                                    const pages = arrayId(axiosOfferBids ? 1 : 2),
                                        {MAX_SUPPLY} = nftContract,
                                        size = npDivide(MAX_SUPPLY, pages.length),
                                        {marketContract, eth: nftContractETH} = nftContract,
                                        rCMarket = (functionName, ...args) => new nftContractETH.Contract(marketContract.abi, marketContract.address)
                                            .methods[functionName](nftContractAddress, ...args).call(),
                                        {isOld} = marketContract,
                                        getListParams = (v, func) => {
                                            const s = isOld ? size : Math.ceil(size),
                                                vSize = npTimes(v, s),
                                                start = npPlus(npTimes(npMinus(v, 1), s), 1)
                                            return !isOld && start > MAX_SUPPLY
                                                ? new Promise(resolve => resolve([]))
                                                : rCMarket(
                                                    func,
                                                    ...isOld
                                                        ? [v, s]
                                                        : [
                                                            ...ternaryArray(func === 'getUserBids', [address]),
                                                            start,
                                                            vSize > MAX_SUPPLY ? MAX_SUPPLY : vSize,
                                                        ]
                                                )
                                        }
                                    let offerBid = []
                                    Promise.all(pages.map(v =>
                                        ((isMyBids && !isOld)
                                            ? getListParams(v, 'getOfferBids')
                                            : (axiosOfferBids
                                                ? getMarketInfo()
                                                : getListParams(v, 'getOfferBids')))
                                            .then(r => offerBid = [...offerBid, ...r])
                                    )).then(async () => getMetaJsonList(tokenId ? [{tokenId}] : offerBid).then(async () => {
                                            if (isMyBids) {
                                                const offerBids = []
                                                Promise.all(offerBid.map(v =>
                                                    v['bidder'] === address && v['bidPrice'] > 0 &&
                                                    offerBids.push(v)
                                                )).then(async () => setData({offerBids: await offerBidsSort(offerBids)}))
                                            } else {
                                                offerBid = axiosOfferBids ? offerBid : (await offerBidsSort(offerBid))
                                                setData({
                                                    offerBids: offerBid,
                                                    floorPrice: numberETH(checkData0(offerBid, 0, 'offerPrice'))
                                                })
                                            }
                                            let transactionTokenList = []
                                            const getPastEventsMarket = (eventName, current = 1) => {
                                                const size = 100
                                                return isMyBids
                                                    ? new Promise(resolve => resolve([]))
                                                    : loginThenFunc(
                                                        () => requestApi(
                                                            'market/event',
                                                            {nftIndex: nftContract.nftIndex, eventName, current, size, tokenId},
                                                        ).then(r => {
                                                            const records = checkDataArray(r, 'records')
                                                            transactionTokenList = [...transactionTokenList, ...records]
                                                            return records.length < size ? transactionTokenList : getPastEventsMarket(eventName, npPlus(current, 1))
                                                        }),
                                                        toggleShowLoading
                                                    )
                                            }
                                            if (tokenId && !isMyBids) {
                                                new nftContractETH.Contract(nftContract.abi, nftContractAddress)
                                                    .methods['ownerOf'](tokenId).call()
                                                    .catch(() => {
                                                        const {referrer} = document, indexOf = referrer.indexOf('?')
                                                        if (referrer.slice(referrer.lastIndexOf('/') + 1, indexOf === -1 ? referrer.length : indexOf) === 'myBids') {
                                                            return ''
                                                        } else {
                                                            replaceMarketplace()
                                                        }
                                                    })
                                                    .then(owner => rCMarket('getOfferBid', tokenId).then(
                                                            ({
                                                                 2: seller,
                                                                 3: salePrice,
                                                                 4: bidder,
                                                                 5: bidPrice
                                                             } = {}) => rCMarket(isOld ? 'isRoyalty' : 'NFTAddressIsRoyalty').then(isRoyalty =>
                                                                (isOld ? (new Promise(resolve => resolve(true))) : rCMarket('NFTAddressIsBid'))
                                                                    .then(NFTAddressIsBid => {
                                                                        toggleShowLoading()
                                                                        setData({
                                                                            owner,
                                                                            seller,
                                                                            salePrice: addressEQAddress(owner, seller) ? numberETH(salePrice) : 0,
                                                                            bidder,
                                                                            bidPrice: numberETH(bidPrice),
                                                                            isRoyalty,
                                                                            NFTAddressIsBid,
                                                                        })
                                                                        getPastEventsMarket().then(
                                                                            r => setData({
                                                                                activity: r.map(
                                                                                    v => {
                                                                                        const {price} = v,
                                                                                            priceEQ0 = !isNotNullOrEmpty(price) || price === '0'
                                                                                        return [
                                                                                            `${priceEQ0 ? 'REMOVE ' : ''}${
                                                                                                {
                                                                                                    OfferTokenForSale: 'LISTING',
                                                                                                    EnterBidForToken: 'BID',
                                                                                                    TransactionToken: 'TRANSFER',
                                                                                                }[v.eventName]
                                                                                            }`,
                                                                                            numberETH(v.price),
                                                                                            ...[
                                                                                                'fromAddress',
                                                                                                'toAddress',
                                                                                                'hashAddress',
                                                                                            ].map(v1 => leaveOut(v[v1])),
                                                                                            timestampToDate(v.blockTime),
                                                                                        ]
                                                                                    }
                                                                                )
                                                                            })
                                                                        )
                                                                    }).catch(replaceMarketplace)
                                                            ).catch(replaceMarketplace)
                                                        ).catch(replaceMarketplace)
                                                    )
                                            } else {
                                                let ownerOf = []
                                                Promise.all(pages.map(v =>
                                                    getListParams(v, 'getOwnerOf')
                                                        .then(r => ownerOf = [...ownerOf, ...r])
                                                )).then(() => {
                                                    const ownerOf1 = []
                                                    let uniqueOwner = 0
                                                    Promise.all(ownerOf.map((v, i) => {
                                                        if (Number(v) !== 0) {
                                                            ownerOf1.push(v)
                                                            if (ownerOf.indexOf(v) === ownerOf.lastIndexOf(v))
                                                                uniqueOwner++
                                                        }
                                                    })).then(() => {
                                                        let totalVolume = 0;
                                                        getPastEventsMarket('TransactionToken').then(
                                                            r => Promise.all(
                                                                r.map(v => totalVolume = npPlus(totalVolume, numberETH(v.price)))
                                                            ).then(() => {
                                                                toggleShowLoading()
                                                                setData({
                                                                    totalVolume,
                                                                    uniqueOwners: uniqueOwner,
                                                                    owners: [...new Set(ownerOf1)].length,
                                                                })
                                                            })
                                                        )
                                                    })
                                                })
                                            }
                                        })
                                    ).catch(() => toggleShowLoading())
                                }).catch(() => toggleShowLoading())
                            }
                        }
                    },
                    [chainId, nftContractIndex, wCMarketSuccess, ...ternaryArray(isMyBids, [address])]
                ],
                [
                    () => {
                        if (address && showBuy) {
                            rC('balanceOf', address).then(r => setData({balanceOf: Number(r)})).catch(() => {
                            })
                        }
                    },
                    [address, showBuy],
                ],
                [
                    () => {
                        if (devCount === 10) {
                            sessionDev
                                ? sessionStorage.removeItem('dev')
                                : sessionStorage.setItem('dev', 1)
                            location.reload()
                        }
                    },
                    [devCount]
                ],
                [
                    () => {
                        if (makeNFTCount === 10) {
                            sessionMakeNFT
                                ? sessionStorage.removeItem('makeNFT')
                                : sessionStorage.setItem('makeNFT', 1)
                            location.reload()
                        }
                    },
                    [makeNFTCount]
                ]
            ].map(v => useEffect(v[0], checkDataArray(v, 1)));
            [
                [isLoadEnd => needSupply && isLoadEnd && clearInterval(checkData([data, 'getSupply'])), [chainId]],
                [
                    isLoadEnd => {
                        isLoadEnd && localStorage.removeItem('token')
                        if (pNs0 === 'cat') {
                            switchEthereumChain(
                                () => {
                                    const setCats = (cats = []) => setData({cats}),
                                        toastError = e => {
                                            toggleShowLoading()
                                            toast(checkDataString(checkDataString(e, 'message').split('\n'), 0))
                                        },
                                        catInit = () => readContractSBT('tokenOfOwner', address).then((catIdList = []) => {
                                            catIdList = catIdList.map(v => Number(v))
                                            requestApi(
                                                'cat/init',
                                                {catIdList},
                                                'post',
                                            ).then(r => {
                                                toggleShowLoading()
                                                r && Promise.all(
                                                    catIdList.map(v => readContractSBT('tokenURI', v).then(url =>
                                                        axios({url: disableCaching(url)}).then(r => checkDataObject(r, 'data'))
                                                    ))
                                                ).then((cats = []) => setCats(cats)).catch(toastError)
                                            })
                                        })
                                    setCats()
                                    toggleShowLoading(true)
                                    if (isLoadEnd || !token()) {
                                        loginThenFunc(catInit, toggleShowLoading)
                                    } else {
                                        address && token() && catInit()
                                    }
                                },
                                undefined,
                                true
                            )
                        }
                        if (pNs0 === 'landing' && !isWeChat && (token() || isParticle === false)) {
                            switchEthereumChain(
                                () => {
                                    const getCurInvitation = () => requestApi('user/curInvitation').then(r => {
                                        toggleShowLoading()
                                        const otherInvCode = checkData([r, 'otherInvCode'])
                                        setData({
                                            otherInvCode,
                                            isBind: isNotNullOrEmpty(otherInvCode)
                                        })
                                    })
                                    toggleShowLoading(true)
                                    if (isLoadEnd || !token()) {
                                        loginThenFunc(getCurInvitation, toggleShowLoading)
                                    } else {
                                        address && token() && getCurInvitation()
                                    }
                                },
                                undefined,
                                true
                            )
                        }
                    },
                    [chainId, address]
                ],
                [
                    () => {
                        if (isMyNFTs) {
                            switchEthereumChain(
                                () => {
                                    toggleShowLoading(true)
                                    if (axiosOfferBids) {
                                        setData({offerBids: undefined, metaJsons: undefined})
                                        getMarketInfoAxiosOfferBidsMyNFTs()
                                    } else {
                                        let offerBids = []
                                        rCNFTContract(nftContract.isTokenOfOwner ? 'tokenOfOwner' : 'tokensOfOwner', address).then((tokenIds = []) =>
                                            Promise.all(tokenIds.map(async tokenId => offerBids.push(await rCMarket('getOfferBid', tokenId))))
                                                .then(async () => getMetaJsonList(offerBids).then(async () => {
                                                    toggleShowLoading()
                                                    setData({offerBids: await offerBidsSort(offerBids)})
                                                })
                                                    .catch(() => toggleShowLoading()))
                                                .catch(() => toggleShowLoading())
                                        )
                                            .catch(() => toggleShowLoading())
                                    }
                                },
                                undefined,
                                true,
                                nftContractChain
                            )
                        }
                    },
                    [chainId, nftContract, address]
                ]
            ].map(v => useRefEffect(...v));

            function replaceHome() {
                windowReplace(firstPath)
            }

            function rC(functionName, ...args) {
                return readContract(
                    {
                        args,
                        functionName,
                        abi: contractAbi,
                        address: contractAddress,
                    },
                )
            }

            function readContractSBT(functionName, ...args) {
                return readContract(
                    {
                        args,
                        functionName,
                        abi: sbtContractAbi,
                        address: sbtContractAddress,
                    },
                )
            }

            function rCNFTContract(functionName, ...args) {
                return new nftContractETH.Contract(nftContractAbi, nftContractAddress)
                    .methods[functionName](...args).call()
            }

            function rCMarket(functionName, ...args) {
                return new nftContractETH.Contract(marketContractAbi, marketContractAddress)
                    .methods[functionName](nftContractAddress, ...args).call()
            }

            function getOwnerIncomeList(clean) {
                if (isMintSbt && isGenesis && tokenId && (!isNotNull(owner) || clean)) {
                    !showLoading && toggleShowLoading(true)
                    canAccessWalletConnectUrl(
                        () => rC('ownerOf', tokenId)
                            .then(owner => readContractSBT('price').then(price => {
                                    clean && !wrongNetwork && close()
                                    !isMarketplace && toggleShowLoading()
                                    setData({owner, mintSBTPrice: numberETH(price)})
                                    if ((!isNotNull(checkData([data, 'incomeList'])) || clean))
                                        getPastEvents(sbtContractAddress, sbtContractAbi, 'PayForScientistOwner').then(async r =>
                                            setData({
                                                incomeList: await transactionRecords(
                                                    checkDataArray(r),
                                                    v => [
                                                        `${formatEther(checkDataString(v, 'returnValues', 'amount'))} ETH`,
                                                        leaveOut(checkDataString(v, 'transactionHash')),
                                                    ],
                                                )
                                            })
                                        ).catch(() => setData({incomeList: []}))
                                })
                            ).catch(replaceGenesis),
                        () => toggleShowLoading()
                    )
                }
            }

            useAccount({
                onConnect: () => {
                },
                onDisconnect: () => {
                },
            })
            useChainId()
            return [
                contextHolder,
                <Routes onChang={(() => document.title = `PETGPT${pathname ? `-${tokenId ? pathname : pNs0}` : ''}`)()}>
                    <Route path='*' element={<Navigate to={firstPath}/>}/>
                    {pathNames.map(v => <Route key={v} path={v} element={paths[v]}/>)}
                </Routes>
            ]
        }
    )
export default () => [
    <WagmiConfig config={wagmiConfig}><Profile/></WagmiConfig>,
    <Web3Modal projectId={projectId} explorerRecommendedWalletIds={[
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
        'ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204',
        '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
        '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662'
    ]} explorerExcludedWalletIds='ALL' ethereumClient={ethereumClient} themeMode={'light'} themeVariables={{
        '--w3m-accent-color': '#0251FF',
        '--w3m-background-color': '#BAFF00',
        '--w3m-accent-fill-color': '#000000',
    }}/>
]