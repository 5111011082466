export default `Privacy & Cookies Policy
                            
We recognize that your privacy is very important and take it seriously. This Privacy & Cookies Policy describes Eucalyptus Labs’ policies and procedures on the collection, use and disclosure of your information when you use the services, websites, and applications offered by Eucalyptus Labs (the “Services”) and tells you about your privacy rights and how the law protects you.

petget.ai

By using the Services, you consent to our use of your information in accordance with this Privacy & Cookies Policy. We will not use or share your personal information with anyone except as described in this Privacy & Cookies Policy. Third-party websites hosted by Eucalyptus Labs through its Ship service are subject to the Terms of Service and Privacy Policies of such third party unless otherwise indicated on such page. Capitalized terms that are not defined in this Privacy & Cookies Policy have the meaning given them in our Terms of Service.

This Privacy & Cookies Policy is intended to meet our duties of transparency under the “General Data Protection Regulation” or “GDPR”.

We will post any modifications or changes to this Privacy & Cookies Policy on this page.

Who We Are and How to Contact Us

Eucalyptus Labs is the Controller (for the purposes of the GDPR) of your Personal Data (referred to as either “Eucalyptus Labs”, “we”, “us” or “our” in this Privacy & Cookies Policy).

If you have any questions about our practices or this Privacy & Cookies Policy, please contact us at dpo@eucalyptuslabs.com.

Your Rights Relating To Your Personal Data

You have the right under this Privacy and Cookies Policy, and by law if you are within the EU, to:

● Request access to your Personal Data. If you are within the EU, this enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it.
● Request correction of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.
● Request erasure of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to process it. You also have the right if you are within the EU to ask us to delete or remove your Personal Data where you have exercised your right to object to processing (see below).
● Object to processing of your Personal Data. This right exists where we are relying on a legitimate interest as the legal basis for our processing and there is something about your particular situation, which makes you want to object to the processing of your Personal Data on this ground. You also have the right to object where we are processing your Personal Data for direct marketing purposes.
● Request the restriction of processing of your Personal Data. This enables you to ask us to suspend the processing of Personal Data about you, for example if you want us to establish its accuracy or the reason for processing it.
● Request the transfer of your Personal Data. If you are within the EU, we will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
● Withdraw consent. This right only exists where we are relying on consent to process your Personal Data (“Consent Withdrawal”). If you withdraw your consent, we may not be able to provide you with access to certain specific functionalities of our Site. We will advise you if this is the case at the time you withdraw your consent.

How to exercise your rights. If you want to exercise any of the rights described above, please contact us using our contact details.

Typically, you will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, except in relation to Consent Withdrawal, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive, or, we may refuse to comply with your request in these circumstances.

We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.

We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

Complaints

If you would like to submit a complaint regarding this Privacy Policy or our practices in relation to your Personal Data, please contact us at: dpo@eucalyptuslabs.com.

We will reply to your complaint as soon as we can.

Marketing Communications Preferences

You can ask us to stop sending you marketing messages or modify your email preferences at any time through any of the following methods:

● by following the opt-out links on any marketing message sent to you; or
● through your account settings; or
● by contacting us at any time using the contact details.

Where you opt out of receiving these marketing messages, this will not apply to Personal Data provided to us as a result of emails relating to existing or pending hires, purchases or subscriptions using the Services or consent to direct marketing communications.

What Personal Data we collect

We use Personal Data we collect to provide the Services, personalize content, remember information to help you efficiently access your account, analyze how the Services are used, diagnose service or technical problems, maintain security, monitor aggregate metrics such as total number of visitors, traffic, and demographic patterns, and track user content and users as necessary to comply with the Digital Millennium Copyright Act and other applicable laws.

Information You Directly Provide to Us. There are many occasions when you provide information that may enable us to identify you personally (“Personal Data”) while using the Services.

Personal Data from Third Party Sources. In addition to the Personal Data that we collect directly from you (as described in the section immediately above this one), we may also collect certain of your Personal Data from third party sources, some of which may not be publicly available.

Aggregated Data. We may also collect, use and share “Aggregated Data” such as statistical or demographic data for any purpose. Aggregated Data may be derived from your Personal Data, but once in aggregated form it will not constitute Personal Data for the purposes of the GDPR or CCPA as this data does not directly or indirectly reveal your identity. However, if we combine or connect Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy & Cookies Policy.

No Special Categories of Personal Data. We do not collect any “Special Categories of Personal Data” about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offenses.

How We Use Your Personal Data and Why?

We generally use Personal Data for the following: to deliver and improve our Services; to manage your account and provide you with customer support; to deliver messages to other users at your request, to perform research and analysis about your use of the Services; to develop, display, and track Content and advertising tailored to your interests on the Services and other sites, including, potentially, providing our advertisements to you when you visit other sites; website or mobile application analytics; to diagnose or fix technology problems; to automatically update the Services on your device; to verify your identify and prevent fraud or other unauthorized or illegal activity; to enforce or exercise any rights in our Terms & Conditions (including to enforce rules related to the content of any private messages); and to perform functions or services as otherwise described to you at the time of collection. A more detailed list of the purposes for which we collect Personal Data is provided below. We will only use your Personal Data for these purposes unless we reasonably consider that we need to use it for another reason which is compatible with the original purpose. If we need to use your Personal Data for an unrelated purpose, we will update this Privacy Policy and we will explain the “legal basis” which allows us to do so.

In respect of each of the purposes for which we use your Personal Data, the GDPR requires us to ensure that we have a legal basis for that use if you are within the EU. The legal bases depend on the Services you use and how you use them. This means we collect and use your Personal Data only where:

● We need it to provide you the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;
● It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote the Services and to protect our legal rights and interests; or
● We need to process your data to comply with a legal or regulatory obligation.

We may also rely on your consent as a legal basis for using your Personal Data where we have expressly sought it for a specific purpose. If we do rely on your consent to a use of your Personal Data, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).

What happens when you do not provide necessary Personal Data?

Where we need to process your Personal Data either to comply with law, or to perform the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with the functionalities of the Services). In this case, we may have to stop you using our Services.

How We Use Cookies And Other Tracking Or Profiling Technologies

We may collect non-personally identifiable information regarding your usage of the Services, including the pages and articles and presentations you viewed, time spent using certain features of the website, demographic data such as server locations, connection speed, and other information that does not identify you. Like most online services, we also use cookies, log files, clear GIFs, tracking pixels, web beacons, and other technologies that may collect Personal Data.

Cookies

When you visit the Services, we may send one or more “cookies“ – small data files – to your computer to uniquely identify your browser and let us help you log in faster and enhance your navigation through the Site. A cookie may convey anonymous information about how you browse the Services to us so we can provide you with a more personalized experience, but does not collect personal information about you. A persistent cookie remains on your computer after you close your browser so that it can be used by your browser on subsequent visits to the Service. Persistent cookies can be removed by following your web browser’s directions. A session cookie is temporary and disappears after you close your browser.

How can I disable cookies? You can typically reset your web browser to refuse all cookies or to notify you when a cookie is being sent. In order to do this, follow the instructions provided by your browser (usually located within the “settings”, “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings.

If you do not accept our cookies, you may experience some inconvenience in your use of our Services and some features of the Service may not function properly. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Services.

Log Files: Log file information is automatically reported by your browser each time you access a web page. When you use the Services, our servers automatically record certain information your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol address, browser type, referring / exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information.

Clear GIFs Information (Web Beacons): When you use the Services, we may employ clear GIFs (also known as web beacons or tracking pixels) from an ad network to anonymously track online usage patterns. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Because your web browser must request these advertisements and web beacons from the ad network’s servers, these companies can view, edit, or set their own cookies, just as if you had requested a web page from their site. You may be able to opt-out of web beacon tracking by adjusting the settings on your browser. In addition, we may also use clear GIFs in HTML-based emails sent to our users to track which emails are opened by recipients. The information collected is used to enable more accurate reporting and make Eucalyptus Labs better for our users.

Third Party Services: We may use Google Analytics, Sentry, Segment, Intercom, and more tools to help understand use of the Services. These tools collect the information sent by your browser as part of a web page request, including cookies and your IP address. These tools also receive this information and their use of it is governed by their respective privacy policies. You can also prevent the use of these tools relating to your use of our Services by downloading and installing the browser plugins.

Use of Twitter User Data: If you choose to connect a Twitter profile to your account, we will access certain information obtained from Twitter regarding your account. In particular we may store your name and email address as well as information on the particular profile you connect, including your followers and profile picture. This data will only be used by us to provide you with the service you expect and will not be shared with any third parties.

Use of Facebook User Data: If you choose to connect a Facebook profile to your account, we will access certain information obtained from Facebook regarding your account. In particular we may store your name and email address as well as information on the particular profile you connect to, including your profile picture. This data will only be used by us to provide you with the service you expect and will not be shared with any third parties.

CalOPPA Disclosures: We do not currently respond to web browsers’ “do not track” signals that provide a method to opt out of the collection of information about users’ activities on the Services and on other websites. If we do so in the future, we will provide relevant information in this Privacy & Cookies Policy.

Who We Share Your Personal Data With

We may share your Personal with third parties. We consider this information to be a vital part of our relationship with you.

In addition, your Personal Data you choose to add to your profile as well as most Content you choose to post will be available for public viewing on the Service. If you want your information to remain private, don’t make it available to other users on our Site.

As we develop our business, we may buy or sell businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, we may also transfer your Personal Data as part of the transferred assets without your consent or notice to you.

We may also share non-Personal Data (such as anonymous usage data, referring/exit pages and URLs, platform types, number of clicks, etc.) with interested third parties to help them understand the usage patterns for certain Services or conduct independent research based on such anonymous usage data.

If you request that we remove your Personal Data, we will convey that request to any third party with whom we have shared your data. We are not, however, responsible for revising or removing your Personal Data obtained by any third party who has previously been provided your information by us in accordance with this policy or any third party to whom you have provided such information (whether by sharing your login and password, or otherwise).

How long we store your personal data

We will retain your information for as long as your account is active or it is reasonably needed for the purposes, unless you request that we remove your Personal Data.We will only retain your Personal Data for so long as we reasonably need to use it for these purposes unless a longer retention period is required by law (for example for regulatory purposes). This may include keeping your Personal Data after you have deactivated your account for the period of time needed for us to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal obligations, resolve disputes and enforce our agreements.

How We Protect Your Personal Data

We use industry-standard physical, managerial, and technical safeguards to preserve the integrity and security of your personal information. We limit access to your Personal Data to those employees and other staff who have a business need to have such access. All such people are subject to a contractual duty of confidentiality. We cannot, however, ensure or warrant the security of any information you transmit to us or guarantee that your information on the Services may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, managerial, or technical safeguards.

We have put in place procedures to deal with any actual or suspected Personal Data breach. In the event that personal information is compromised as a result of such a breach of security, we will promptly notify those persons whose personal information has been compromised, in accordance with the notification procedures set forth in this Privacy & Cookies Policy, or as otherwise required by applicable law.

We cannot ensure that your Personal Data will be protected, controlled or otherwise managed pursuant to this Privacy & Cookies Policy if you share your login and password information with any third party, including any third party operating a website or providing other services.

Our Policy on Children's Privacy

Protecting the privacy of young children is especially important. The Services are not intended for children below 16 and we do not knowingly collect or solicit personal information from anyone under the age of 16 or knowingly allow such persons to register with the Services. If you are under the age of 16, please do not submit any personal information through the Site. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy & Cookies Policy by instructing their children never to provide personal information on this Site. If we become aware that we have collected personal information from a child under age 16, we will take steps to remove that information.

Links to Other Websites

This Privacy & Cookies Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by Eucalyptus Labs. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy & Cookies Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our Site, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.

Changes to Our Privacy & Cookies Policy

We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy & Cookies Policy at any time. Any changes or updates will be effective immediately upon posting to this page. You should review this Privacy & Cookies Policy regularly for changes. You can determine if changes have been made by checking the Effective Date below. Your continued use of our Site following the posting of any changes to this Privacy & Cookies Policy means you consent to such changes.`
