export default {
    root: '0x7ade2dcc82fd08e05885741bcce2119e92e733da4dd0157df48b4fd1141270f1',
    '0x14f45C74b52e9e4e0e138BCC7D5210777B7d0085': [
        1000,
        [
            '0x8cc5fdcc42f550e58fdd4d6f2ab207d474f23242011d9a7ccbdbf7cb9c2dd013',
            '0x564b414a2a37a0749904c28d5725b018b143a8a58bd6c863a7ef3c6cd050d7f2'
        ]
    ],
    '0x65ef138EABCeb9418E2294f35E974e83FdF7c594': [
        10,
        [
            '0xb298a4e4b34a5c16034b118993e5d0ae0b3b932176ddb67b400ea759a3644589',
            '0x564b414a2a37a0749904c28d5725b018b143a8a58bd6c863a7ef3c6cd050d7f2'
        ]
    ],
    '0x7441A14d437D6A318372BeFAFF1E34A8269cc229': [
        10,
        [
            '0xc83ec5b9fdb44520f2ec2fd1e01e7367c69b17f1fbf4afe55986281c2b0bb242'
        ]
    ],
}



