import {createStore} from "redux";

export default createStore((
    state = {
        isPhoneClientWidth: false,
        languageIndex: sessionStorage.getItem('languageIndex') || 0,
    },
    // @ts-ignore
    newState = {},
) => ({
    // @ts-ignore
    ...state,
    ...newState
}))