export default {
    address: '0x6398Ace003d9E83708ACE80Cff3D2f2413DDD120',
    abi: [{"inputs": [], "stateMutability": "nonpayable", "type": "constructor"}, {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    }, {"inputs": [], "name": "ApprovalQueryForNonexistentToken", "type": "error"}, {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    }, {"inputs": [], "name": "ExceedsAllowListQuota", "type": "error"}, {
        "inputs": [],
        "name": "ExceedsMaximumSupply",
        "type": "error"
    }, {"inputs": [], "name": "InvalidQueryRange", "type": "error"}, {
        "inputs": [],
        "name": "MintERC2309QuantityExceedsLimit",
        "type": "error"
    }, {"inputs": [], "name": "MintToZeroAddress", "type": "error"}, {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    }, {"inputs": [], "name": "NotOnAllowList", "type": "error"}, {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    }, {"inputs": [], "name": "OwnershipNotInitializedForExtraData", "type": "error"}, {
        "inputs": [],
        "name": "SetUserCallerNotOwnerNorApproved",
        "type": "error"
    }, {"inputs": [], "name": "TransferCallerNotOwnerNorApproved", "type": "error"}, {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    }, {"inputs": [], "name": "TransferToNonERC721ReceiverImplementer", "type": "error"}, {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    }, {"inputs": [], "name": "URIQueryForNonexistentToken", "type": "error"}, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
        }, {"indexed": false, "internalType": "uint256", "name": "numberOfTokens", "type": "uint256"}],
        "name": "AllowListClaimMint",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "approved",
            "type": "address"
        }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "Approval",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }, {"indexed": false, "internalType": "bool", "name": "approved", "type": "bool"}],
        "name": "ApprovalForAll",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "address",
            "name": "account",
            "type": "address"
        }, {"indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "Claimed",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "uint256",
            "name": "fromTokenId",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "toTokenId", "type": "uint256"}, {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "to", "type": "address"}],
        "name": "ConsecutiveTransfer",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": false, "internalType": "bytes32", "name": "merkleRoot", "type": "bytes32"}],
        "name": "MerkleRootChanged",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "newOwner", "type": "address"}],
        "name": "OwnershipTransferred",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "Transfer",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
            "indexed": true,
            "internalType": "address",
            "name": "user",
            "type": "address"
        }, {"indexed": false, "internalType": "uint64", "name": "expires", "type": "uint64"}],
        "name": "UpdateUser",
        "type": "event"
    }, {
        "inputs": [],
        "name": "MAX_SUPPLY",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }], "name": "approve", "outputs": [], "stateMutability": "payable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "deleteDefaultRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "explicitOwnershipOf",
        "outputs": [{
            "components": [{
                "internalType": "address",
                "name": "addr",
                "type": "address"
            }, {"internalType": "uint64", "name": "startTimestamp", "type": "uint64"}, {
                "internalType": "bool",
                "name": "burned",
                "type": "bool"
            }, {"internalType": "uint24", "name": "extraData", "type": "uint24"}],
            "internalType": "struct IERC721A.TokenOwnership",
            "name": "",
            "type": "tuple"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256[]", "name": "tokenIds", "type": "uint256[]"}],
        "name": "explicitOwnershipsOf",
        "outputs": [{
            "components": [{
                "internalType": "address",
                "name": "addr",
                "type": "address"
            }, {"internalType": "uint64", "name": "startTimestamp", "type": "uint64"}, {
                "internalType": "bool",
                "name": "burned",
                "type": "bool"
            }, {"internalType": "uint24", "name": "extraData", "type": "uint24"}],
            "internalType": "struct IERC721A.TokenOwnership[]",
            "name": "",
            "type": "tuple[]"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}],
        "name": "getAllowListMinted",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "getApproved",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "start", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "end",
            "type": "uint256"
        }],
        "name": "getTokenAttributes",
        "outputs": [{"internalType": "uint256[7][]", "name": "tokenAttributes_", "type": "uint256[7][]"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }],
        "name": "isApprovedForAll",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "merkleRoot",
        "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "numberOfTokens", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "tokenQuota",
            "type": "uint256"
        }, {"internalType": "bytes32[]", "name": "proof", "type": "bytes32[]"}, {
            "internalType": "uint256[7]",
            "name": "attributes",
            "type": "uint256[7]"
        }], "name": "mintAllowList", "outputs": [], "stateMutability": "payable", "type": "function"
    }, {
        "inputs": [],
        "name": "name",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "claimer", "type": "address"}, {
            "internalType": "uint256",
            "name": "b",
            "type": "uint256"
        }, {"internalType": "bytes32[]", "name": "proof", "type": "bytes32[]"}],
        "name": "onAllowListB",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "owner",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "ownerOf",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "price",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "resetTokenRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "_tokenId", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_salePrice",
            "type": "uint256"
        }],
        "name": "royaltyInfo",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}, {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
            "internalType": "bytes",
            "name": "_data",
            "type": "bytes"
        }], "name": "safeTransferFrom", "outputs": [], "stateMutability": "payable", "type": "function"
    }, {
        "inputs": [{"internalType": "bytes32", "name": "merkleRoot_", "type": "bytes32"}],
        "name": "setAllowList",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes32", "name": "merkleRoot_", "type": "bytes32"}],
        "name": "setAllowListNotPreserveBalances",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "operator", "type": "address"}, {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
        }], "name": "setApprovalForAll", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "string", "name": "baseURI_", "type": "string"}],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "receiver", "type": "address"}, {
            "internalType": "uint96",
            "name": "feeNumerator",
            "type": "uint96"
        }], "name": "setDefaultRoyalty", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "price_", "type": "uint256"}],
        "name": "setPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256[]", "name": "spaceSuitCodes_", "type": "uint256[]"}],
        "name": "setSpaceSuitCodes",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "spaceSuitMaxSupply_", "type": "uint256"}],
        "name": "setSpaceSuitMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
        }, {"internalType": "uint96", "name": "feeNumerator", "type": "uint96"}],
        "name": "setTokenRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
            "internalType": "address",
            "name": "user",
            "type": "address"
        }, {"internalType": "uint64", "name": "expires", "type": "uint64"}],
        "name": "setUser",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "name": "spaceSuitCodes",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "spaceSuitMaxSupply",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "spaceSuitTotalSupply",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes4", "name": "interfaceId", "type": "bytes4"}],
        "name": "supportsInterface",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "symbol",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "numberOfTokens",
            "type": "uint256"
        }], "name": "teamMint", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "name": "tokenAttributes",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "tokenURI",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
        "name": "tokensOfOwner",
        "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "uint256",
            "name": "start",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "stop", "type": "uint256"}],
        "name": "tokensOfOwnerIn",
        "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "totalMinted",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "userExpires",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "userOf",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {"inputs": [], "name": "withdraw", "outputs": [], "stateMutability": "nonpayable", "type": "function"}]
}